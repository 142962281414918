import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Table } from 'react-bootstrap';
import Card from "../../components/Card/Card.jsx";
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import OrderPayment from "../../views/AdminScreenRoutes/OrderPayment";
import OrderTicketed from "../../views/AdminScreenRoutes/OrderTicketed";
import OrderTransport from "../../views/AdminScreenRoutes/OrderTransport";
import OrderHistory from "../../views/AdminScreenRoutes/OrderHistory"; 

function ShowOrder(props) {
    const [order, setOrder] = useState({});
    const [transaction, setTransaction] = useState({});
    const [s3Url, setS3Url] = useState("");
    const [poErrorStatus, setPOError] = useState("");
    const [showTab, setShowTab] = useState("basic");
    const [merchantData, setMerchantData] = useState({});
    const [productData, setProductData] = useState([]);
    const [adminUserId, setAdminUserId] = useState(null);

    useEffect(() => {
        axios.get(`/admin/order/${props.match.params.id}`)
            .then(res => {
                const order = res.data.results.order;
                setMerchantData(res.data.merchantData);
                setProductData(res.data.results.product_data);
                setOrder(order);
                setS3Url(res.data.src);
                if (_.isEmpty(res.data.results.product_data)) {
                    setPOError("Something Went Wrong - Product HSN or SKU not found. Please update product and request to generate the invoice again.");
                }
            })
            .catch(err => {
                // console.error(err);
                NotificationManager.error("Failed to load order data");
            });
    }, [props.match.params.id]);

    const requestInvoice = () => {
        axios.put(`/admin/order/${props.match.params.id}`)
            .then(() => {
                NotificationManager.success("Request sent. Please wait for some time to get processed");
                document.getElementById("alertMsg").style.display = 'none';
            })
            .catch(err => {
                // console.error(err);
                NotificationManager.error("Failed to send request. Try again later");
            });
    };

    return (
        <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li style={{ display: "inline-block", marginRight: "10px" }}>
                        <a style={{ padding: "10px", color: showTab === 'basic' ? "#000" : "#7b999e", cursor: "pointer" }} onClick={() => setShowTab('basic')}>Basic</a>
                    </li>
                    <li style={{ display: "inline-block", marginRight: "10px" }}>
                        <a style={{ padding: "10px", color: showTab === 'payment' ? "#000" : "#7b999e", cursor: "pointer" }} onClick={() => setShowTab('payment')}>Payment</a>
                    </li>
                    <li style={{ display: "inline-block", marginRight: "10px" }}>
                        <a style={{ padding: "10px", color: showTab === 'transport' ? "#000" : "#7b999e", cursor: "pointer" }} onClick={() => setShowTab('transport')}>Transport</a>
                    </li>
                    <li style={{ display: "inline-block", marginRight: "10px" }}>
                        <a style={{ padding: "10px", color: showTab === 'ticketed' ? "#000" : "#7b999e", cursor: "pointer" }} onClick={() => setShowTab('ticketed')}>Ticketed</a>
                    </li>
                    <li style={{ display: "inline-block", marginRight: "10px" }}>
                        <a style={{ padding: "10px", color: showTab === 'history' ? "#000" : "#7b999e", cursor: "pointer" }} onClick={() => setShowTab('history')}>History</a>
                    </li>
                </ul>
            </div>

            {showTab === 'basic' ? (
                Object.keys(order).length ? (
                    <div>
                        <Row>
                            <Col md={12}>
                                <Card
                                    title="Order Details"
                                    category="Basic Information"
                                    content={
                                        <Table responsive bordered hover>
                                            <tbody>
                                                <tr>
                                                    <td>PO#:</td>
                                                    <td>{order.orderSerial}</td>
                                                </tr>
                                                <tr>
                                                    <td>Buyers</td>
                                                    <td>{order.recipientName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Reseller</td>
                                                    <td>{merchantData.fName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mode of transport</td>
                                                    <td>{order.shippingMethod || ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>Payment method</td>
                                                    <td>Advance payment</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={12}>
                                <Card
                                    title="Order Status"
                                    category="Current Status"
                                    content={
                                        <Table responsive bordered hover>
                                            <tbody>
                                                <tr>
                                                    <td>Order Status</td>
                                                    <td>{order.oStatus || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Payment status</td>
                                                    <td>{order.pStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Shipping status</td>
                                                    <td>{order.sStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Return Status</td>
                                                    <td>{order.rStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Dispute Status</td>
                                                    <td>{order.dStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Comment Status</td>
                                                    <td>{order.commentStatus || 'N/A'}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={12}>
                                <Card
                                    title="Products"
                                    category="List of Products"
                                    content={
                                        productData.length ? (
                                            <div style={{ overflowY: 'scroll', height: "200px" }}>
                                                <Table responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Item Snapshot</th>
                                                            <th>Title</th>
                                                            {/* <th>Specification Name</th> */}
                                                            <th>Product Code</th>
                                                            <th>Unit Price</th>
                                                            <th>Quantity</th>
                                                            <th>Total Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {order.orderDetails.map((data, index) => (
                                                            <tr key={index}>
                                                                <td><img src={productData[index]?.imgUrl} width="100" alt="Product" /></td>
                                                                <td>{data.title}</td>
                                                                {/* <td>&nbsp;</td> */}
                                                                <td>{data.sku || data.code || ''}</td>
                                                                <td>{data.consumerPrice}</td>
                                                                <td>{data.quantity}</td>
                                                                <td>{data.consumerPrice * data.quantity}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        ) : (
                                            <p>No products found.</p>
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={12}>
                                <Card
                                    title="Address Details"
                                    category="Delivery Address"
                                    content={
                                        <Table responsive bordered hover>
                                            <tbody>
                                                <tr>
                                                    <td>Shipping Address</td>
                                                    <td>{order.shippingCity || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Street Name</td>
                                                    <td>{order.shippingAddress1|| 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>District</td>
                                                    <td>{order.shippingCity || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td>{order.shippingCity || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>province / State</td>
                                                    <td>{order.shippingState || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Country</td>
                                                    <td>{order.shippingCountry  || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>post code/Zip</td>
                                                    <td>{order.shippingPostalCode || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Number</td>
                                                    <td>{order.shippingPhoneNumber|| 'N/A'}</td>
                                                </tr>
                                                 
                                            </tbody>
                                        </Table>
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                ) : null
            ) : showTab === 'payment' ? (
                <OrderPayment orderShipping={order.orderShipping} />
            ) : showTab === 'transport' ? (
                <OrderTransport />
            ) : showTab === 'ticketed' ? (
                <OrderTicketed />
            ) : showTab === 'history' ? (
                <OrderHistory />
            ) : null}
        </div>
    );
}

export default ShowOrder;



