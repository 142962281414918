import React, { useState, useEffect } from 'react';
import { Row,Col,Button,Table } from 'react-bootstrap';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import Card from '../../components/Card/Card.jsx';

const DisabledProduct = (props) => {
    const [product, setProduct] = useState(null); // State for product details
    const [loading, setLoading] = useState(true);
    const [disableReason, setDisableReason] = useState(""); // State for disable reason

    useEffect(() => {
        fetchProductData();
    }, []);

    const fetchProductData = async () => {
        try {
            const result = await axios.get(`/admin/getItem/${localStorage.getItem("code")}`);
            if (result.data === 1) {
                setLoading(false);
                NotificationManager.error("Product not found");
            } else {
                let productData = result.data.product[0];
                setProduct(productData);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            NotificationManager.error("Failed to fetch product data");
            console.error("Error fetching product data:", error);
        }
    };

    const handleConfirmDisable = async () => {
        try {
            if (!disableReason) {
                NotificationManager.error("Please provide a reason for disabling the product");
                return;
            }

            const response = await axios.post(`/admin/disableProduct`, {
                code: product.code,
                status: 2,
                reason: disableReason,
            });

            if (response.data.success) {
                NotificationManager.success("Product disabled successfully");
                // Redirect to main page or wherever needed
                props.history.push('/admin/products');
            } else {
                NotificationManager.error("Failed to disable the product");
            }
        } catch (error) {
            NotificationManager.error("Error disabling product");
            console.error("Error disabling product:", error);
        }
    };

    const handleClose = () => {
        props.history.push('/admin/product'); // Go back to the main page
    };

    return (
        <div className="content">
            <Row>
                <Col md={12}>
                    <Card
                        title="Disabled Products"
                        category="Product Information"
                        content={
                            <Table responsive bordered hover>
                                <tbody>
                                    <tr>
                                        <td>Product Name</td>
                                        <td>{product?.name || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Disable Reason</td>
                                        <td>
                                            <textarea style={{ width: '100%', padding: '10px', borderRadius: '6px', border: '1px solid #ddd', backgroundColor: '#f9f9f9', marginTop: '10px' }}
                                                rows={4}
                                                placeholder="Enter reason for disabling the product"
                                                value={disableReason}
                                                onChange={(e) => setDisableReason(e.target.value)}
                                            />
                                            <div className='col-sm-12 col-md-6 col-lg-2 col-xl-2'>
                                                <Button className="btn btn-primary btn-fill"
                                                    variant="secondary"
                                                    onClick={handleClose}
                                                    style={{
                                                        background: 'rgb(255, 160, 0)', borderColor: 'rgb(255, 160, 0)', fontSize: '15px', fontWeight: '500', color: '#FFFFFF'
                                                    }}
                                                > Close
                                                </Button></div>
                                            <div className='col-sm-12 col-md-6 col-lg-2 col-xl-2'>
                                                <Button className="btn btn-primary btn-fill"
                                                    variant="warning"
                                                    onClick={handleConfirmDisable}
                                                    style={{ background: 'rgb(255, 160, 0)', borderColor: 'rgb(255, 160, 0)', fontSize: '15px', fontWeight: '500', color: '#FFFFFF' }}
                                                >
                                                    Confirm Disable
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }
                    />
                </Col>
            </Row>
        </div>
    );
};
export default DisabledProduct;