import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import Card from "../../components/Card/Card.jsx";
import { NotificationManager } from 'react-notifications';

const OrderPayment = () => {
  const { id } = useParams();  // Get the 'id' from the route parameters
  const [order, setOrder] = useState({});

  useEffect(() => {
    axios.get(`/admin/order/${id}`)
      .then(res => {
        const order = res.data.results.order;
        setOrder(order);
        NotificationManager.success("Order data fetched successfully!");
      })
      .catch(error => {
        // console.error("There was an error fetching the order data!", error);
        NotificationManager.error("Error fetching order data");
      });
  }, [id]);  // Re-fetch when 'id' changes

  return (
    <div className="content">
      <Row>
        <Col md={12}>
          <Card
            title="Order Payment Information"
            category="Details of the payment status"
            content={
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th>Serial number</th>
                    <th>State</th>
                    <th>Remark</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center">1</td>
                    <td>{order.pStatus || 'N/A'}</td>
                    <td>{order.commentStatus || 'N/A'}</td>
                    <td>{new Date(order.paidDate).toUTCString() || 'N/A'}</td>  
                  </tr>
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrderPayment;
