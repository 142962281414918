import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import MerchantbulkUploadOrder from "../views/MerchantbulkUploadOrder";
import Modal from "react-responsive-modal";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { NotificationManager } from "react-notifications";
import { Button } from "react-bootstrap";
import "../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const fileRef = useRef();
  const [showUpload, setShowUpload] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [errormessage, setErrorMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(20);
  const [selectStore, setSelectStore] = useState("Select Store Name");
  const [storeData, setStoreData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [tempCustomerData, setTempCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [error, setErrors] = useState({});
  const [defaultStore, setDefaultStore] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [abnormalTotal, setAbnormalTotal] = useState("");
  const [awaitingTotal, setAwaitingTotal] = useState("");
  const [processingCount, setProcessingCount] = useState("");
  const [shippedCount, setShippedCount] = useState("");
  const [tickedCount, setTickedCount] = useState("");
  const [cancelCount, setCancelCount] = useState("");
  const [resultCount, setResultCount] = useState("");
  const [open, setOpen] = useState(false);
  const [moreDesc, setMoreDesc] = useState(false);
  const [msg, setMsg] = useState("");
  const [singleProduct, setSingleProduct] = useState([]);
  const [fulfillstatus, setfulfillstatus] = useState();
  const [percentValue, setpercentValue] = useState();
  const [percentDate, setpercentDate] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [axiosMessage, setAxiosMessage] = useState([]);
  const token = localStorage.getItem("token");
  const decode = jwt_decode(token);
  

  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    
    setLoading(false);
    setDefaultStore(domain);
    setSelectStore(domain);
    getOrders(domain, currentPage, showPerPage, {});
  }, []);
  
  const getOrders = (
    storeDomain,
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/shopperrds/orders?store=${storeDomain}&merchantId=${userId}&page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          setOrders({ storeDomain, data: { currentPage: res.data } });
          setPageData(res.data.results);
          setTotalData(res.data.count);
          setAbnormalTotal(res.data.abnormalCount);
          setAwaitingTotal(res.data.awaitingPayCount);
          setProcessingCount(res.data.processingCount);
          setShippedCount(res.data.shippedCount);
          setTickedCount(res.data.tickedCount);
          setCancelCount(res.data.cancelCount);
          setResultCount(res.data.resultCount);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    getOrders(selectStore, e.selected + 1, showPerPage, {});
  };

  const handleChange = (e) => {
    console.log("Came to handle Change", e.target.value);
    document.getElementById("searchOrder").value = "";
    setFromDate("");
    setTodate("");
    setSelectStore(e.target.value);
    getOrders(e.target.value, currentPage, showPerPage, {});
  };

  const handleSearchOrder = (e) => {
    setSearchString(e.target.value);
  };

  const handleFromdate = (fromDate) => {
    setFromDate(fromDate);
    setErrors({});
  };

  const handleToDate = (toDate) => {
    setTodate(toDate);
    setErrors({});
  };

  const handleNavigation = () => {
    setShowUpload(!showUpload);
  };
  function showDateRangeData() {
    let errors = {};
    // console.log(fromDate, toDate);
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0) {
          errors.toDate = "End date needs to be after From date";
        } else {
          getOrders(selectStore, 1, showPerPage, {
            fromDate,
            toDate,
            text: searchString,
          });
        }
      }
      setErrors(errors);
    } else if (searchString) {
      getOrders(selectStore, 1, showPerPage, { text: searchString });
    }
  }

  function downloadOrder() {
    let errors = {};
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0) {
          errors.toDate = "End date needs to be after From date";
        } else {
          getDownloadOrders(selectStore, 1, showPerPage, {
            fromDate,
            toDate,
            text: searchString,
          });
        }
      }
      setErrors(errors);
    } else if (searchString) {
      getDownloadOrders(selectStore, 1, showPerPage, { text: searchString });
    } else {
      getDownloadOrders(selectStore, currentPage, showPerPage, {});
    }
  }
  const onCloseModal = () => {
    setOpen(false);
    setMsg("");
  };

  const statusArray = [
    {
      id: 1,
      value: "Pending",
    },
    {
      id: 3,
      value: "Processed",
    },
    {
      id: 14,
      value: "Shipped",
    },
  ];

  const callPurchesOrderApi = (orderNumber) => {
    axios.get("/po/trackOrderNumber/" + orderNumber).then((res) => {
      // setfulfillstatus(res.data)
      console.log(res.data);
      console.log("resonpsdfff");
      const fullfillstatusArray = res.data.data.map(
        (item) => item.fulfillmentStatus
      );
      const fullfillstatusDate = res.data.data.map(
        (item) => item.updatedAt.split("T")[0]
      );
      const finalDateIs = fullfillstatusDate.toString()
      setpercentDate(finalDateIs)
      const fullfillstatusvalue = fullfillstatusArray.toString();
      if(fullfillstatusvalue==1)
      {
        setpercentValue(0)
      }
      else if(fullfillstatusvalue==3)
      {
        setpercentValue(50)
      }
      else if(fullfillstatusvalue==14){
        setpercentValue(100)
      }
      setOpen(true);
      setfulfillstatus(fullfillstatusvalue);
    });
  };


  const getDownloadOrders = (
    storeDomain,
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    console.log(qs);
    axios
      .get(
        `/shopperrds/downloadOrderCsv?store=${storeDomain}&merchantId=${
          jwt_decode(localStorage.getItem("token")).id
        }&page=${currentPage}&limit=${showPerPage}&${qs}`
      )
      .then((data) => {
        let result = data.data;
        setLoading(false);
        if (result.error == 0) {
          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
  };

  //Add Product from CSV File
const addCsvOrder = (e) => {
  console.log("request come in addcsvorder");
  e.preventDefault();
  const scvdata = new FormData()
  scvdata.append('file', csvData[0])
  scvdata.append('merchantId', decode.id)
  scvdata.append('csvType', 13) 
  console.log("add scvdata is ");
  console.log(scvdata);

  axios
  .post('/shopperrds/bulkUploadOrder', scvdata)
  .then((res) => { 
      if (res.data.error == 0) {
          // NotificationManager.success(res.data.response);
          setAxiosMessage(res.data.response);
          setOpen(true);
          getOrders(selectStore, currentPage, showPerPage, {});
          fileRef.current.value = null
      } else {
          NotificationManager.error(res.data.response);
      }
  }).catch((error) => {
      error
      ? NotificationManager.error(error.toString())
      : NotificationManager.error('There is a problem with this csv');
  })
};

  return (
    <div>
      <Modal
      style={{borderRadius:"5px"}}
        open={open}
        onClose={() => {
          setOpen(false);
          setMoreDesc(false);
        }}
      >
        <div>
          <br />
          <br />
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p>{axiosMessage}</p>
          <br />
          <br />
          
        </div>
        
      </Modal>
      <div className="uploadBtn1" style = {{ marginBottom: '0px' , width : '100%'}}>
          <>
            <div className="container-fluid">
              <div className="card" style={{ padding: "1em" }}>
                <div className="row">
                  <form onSubmit={addCsvOrder}>
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    {/* <p>Add Orders CSV File</p> */}
                    <div className="form-group">
                    <label>Order Number</label>
                    <div id='fileContents'></div>
                        <input type='file' className='form-control text-center' name='avatar' onChange={(e) => {setCsvData(e.target.files) }} encType='multipart/form-data' accept='.csv' ref={fileRef}  />
                    </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>&nbsp;</label>
                      <div className='card-button' style={{margin : "0px" }}>
                          <button  type='submit' className="btn btn-fill" style={{ textTransform: 'uppercase', background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}}> Add Orders </button>
                      </div>
                        <a href="https://shopperrcdn.shopperr.in/inward/6613cc084184e227e4815e4e2024/11/LDSGA7_6613cc084184e227e4815e4e.csv" target="_blank">Sample Csv
                        </a>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
            </>
      </div>

        <div className="content bdycontent">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
          <div className="card" style={{ padding: "1em" }}>
              <ul>
                <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => getOrders(selectStore, 1, showPerPage, {status:1})}>All Orders <span>{totalData}</span></a></li>
                <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => getOrders(selectStore, 1, showPerPage, {status:2})}>Abnormal <span>{abnormalTotal}</span></a></li>
                <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => getOrders(selectStore, 1, showPerPage, {status:3})}>Awaiting Payment <span>{awaitingTotal}</span></a></li>
                <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => getOrders(selectStore, 1, showPerPage, {status:4})}>Processing <span>{processingCount}</span></a></li>
                <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => getOrders(selectStore, 1, showPerPage, {status:5})}>Shipped <span>{shippedCount}</span></a></li>
                <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => getOrders(selectStore, 1, showPerPage, {status:6})}>Ticketed orders <span>{tickedCount}</span></a></li>
                <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => getOrders(selectStore, 1, showPerPage, {status:7})}>Cancelled <span>{cancelCount}</span></a></li>
              </ul>
            </div>
          
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label>Order Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search By Order Number"
                      onChange={handleSearchOrder}
                      id="searchOrder"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>
                      From Date &nbsp;
                      <span
                        onClick={() =>
                          (document.getElementById("fromDate").value = "")
                        }
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="fromDate"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="From Date"
                      value={fromDate}
                      onChange={(e) => handleFromdate(e.target.value)}
                    />
                    {!isEmpty(error.fromDate) && (
                      <span className="text-danger">{error.fromDate}</span>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>
                      To Date &nbsp;
                      <span
                        onClick={() =>
                          (document.getElementById("toDate").value = "")
                        }
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="toDate"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="To Date"
                      value={toDate}
                      onChange={(e) => handleToDate(e.target.value)}
                    />
                    {!isEmpty(error.toDate) && (
                      <span className="text-danger">{error.toDate}</span>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => showDateRangeData()}
                  >
                    GET
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary btn-fill"
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    onClick={() => downloadOrder()}
                  >
                    DOWNLOAD
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getOrders(selectStore, currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Total Orders:{" "}
                    <span className="text-muted">{resultCount}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Order Number</th>
                    <th scope="col">Order ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Marketplace</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Paid Date</th>
                    <th scope="col">Shipped Date</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    pageData.map((order) => {
                      let d = new Date(order.createdAt);
                      let paid_date = ''; 
                      let formattedPaidDate = '';
                      let shipped_date = '';
                      let formattedShippedDate = '';

                      if(order.paidDate != undefined && order.paidDate!=''){
                        paid_date = new Date(order.paidDate);
                        formattedPaidDate = `${paid_date.getDate()}-${
                          paid_date.getMonth() + 1
                        }-${paid_date.getFullYear()}`;
                      }
                      
                      if(order.shipDate != undefined && order.shipDate!=''){
                        shipped_date = new Date(order.shipDate);
                        formattedShippedDate = `${shipped_date.getDate()}-${
                          shipped_date.getMonth() + 1
                        }-${shipped_date.getFullYear()}`;
                      }
                      // const getOrderStatus = (row) => {
                        // console.log(order.rStatus)
                        let getOrderStatus = '';
                      if (order.oStatus === 'OC') {
                          getOrderStatus = 'Cancelled';
                        } else if (order.rStatus !== 'RN') {
                          // console.log(111111111111)
                          getOrderStatus =  'Refund';
                        } else if (order.dStatus === 'DP' && order.disputeRole===0) {
                          getOrderStatus =  'Action needed';
                        } else if (order.dStatus === 'DP' && order.disputeRole===1) {
                          getOrderStatus =  'Awaiting response';
                        } else if (order.dStatus === 'DD' && order.disputeRole===3) {
                          getOrderStatus =  'Refused';
                        } else if (order.pStatus === 'PU' && order.sStatus === 'SU') {
                          getOrderStatus =  'Awaiting Payment';
                        } else if (order.pStatus === 'PD' && order.sStatus === 'SU') {
                          getOrderStatus =  'Processing';
                        } else if (order.pStatus === 'PD' && order.sStatus === 'SS') {
                          getOrderStatus =  'Shipped';
                        } else if (order.pStatus === 'PP') {
                          getOrderStatus =  'Refunded';
                        } else {
                          getOrderStatus = ''; // Handle default case
                        }
                      // };
 
                      return (
                        <tr key={order._id}>
                          <td scope="row">
                            
                            <Link to={`/merchant/order/${order._id}`}>
                              {order.orderSerial}
                            </Link>
                            </td>
                            <td scope="row">{order.mkpOrderId}
                            </td>
                          <td>{order.consumerPrice}</td>
                          <td>{order.storeDomain}</td>
                          <td>{`${d.getDate()}-${
                            d.getMonth() + 1
                          }-${d.getFullYear()}`}</td>
                          <td>{getOrderStatus}
                          </td>
                          <td>{formattedPaidDate}</td>
                          <td>{formattedShippedDate}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
    </div>
  );
}
export default OrderList;
