import React, { useState, useEffect } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import CustomButton from "../../components/CustomButton/CustomButton";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
       
function PurchaseOrder() {
  const [vendors, setVendors] = useState([]);
  const [qcaction, setQcaction] = useState("");
  const [orderId, setOrderId] = useState("");
  const [marketId, setMarketId] = useState("");
  const [hsn, setHsn] = useState("");
  const [sku, setSku] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rate, setRate] = useState("");
  const [tax, setTax] = useState("");
  const [netAmount, setNetAmount] = useState("");

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get("/wms/get_vendors");
        setVendors(response.data.vendors);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };
    fetchVendors();
  }, []);

  useEffect(() => {
    calculateNetAmount();
  }, [rate, tax, quantity]);

  const calculateNetAmount = () => {
    if (rate && tax && quantity) {
      const net = quantity * rate * (1 + tax / 100);
      setNetAmount(net.toFixed(2));
    } else {
      setNetAmount(""); 
    }
  };

  const addPurchaseOrder = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/wms/addPurchaseOrder", {
        vendor: qcaction,
        orderId,
        marketId,
        hsn,
        sku,
        quantity,
        rate,
        tax,
        netAmount
      });
      NotificationManager.success(response.data.response);
    } catch (error) {
      console.error("Error during add:", error);
      NotificationManager.error("Failed to add purchase order.");
    }
  };
  
  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "0 20px" }}>
      <Card sx={{ maxWidth: 1500, width: "100%" }}>
        <CardActionArea id="cardContentClass">
          <CardContent>
            <div className="content">
              <div className="container-fluid">
                <div className="card" style={{ padding: "1em" }}>
                  <form onSubmit={addPurchaseOrder}>
                    <div className="card" style={{ marginTop: 0, padding: "0px 10px" }}>
                      <div className="row">
                        <div className="col-md-2"><label htmlFor="vendor" >Vendor Name</label></div>
                        <div className="col-md-6">
                          <select
                            id="vendor"
                            className="form-control"
                            value={qcaction}
                            onChange={(e) => setQcaction(e.target.value)}
                            style={{ border: "1px solid #1D8BF7" }}
                          >
                            <option value="" disabled>Select Vendor</option>
                            {vendors.map((vendor) => (
                              <option key={vendor._id} value={vendor.name}>{vendor.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-2"><label htmlFor="orderId">OrderId</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="orderId"
                            placeholder="Enter Order ID"
                            style={{ border: "1px solid #1D8BF7" }}
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                          />
                        </div>

                        <div className="col-md-1"><label htmlFor="marketId">MarketId</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="marketId"
                            placeholder="Enter Market ID"
                            style={{ border: "1px solid #1D8BF7" }}
                            value={marketId}
                            onChange={(e) => setMarketId(e.target.value)}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-2"><label htmlFor="hsn">HSN</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="hsn"
                            placeholder="Enter HSN"
                            value={hsn}
                            onChange={(e) => setHsn(e.target.value)}
                            style={{ border: "1px solid #1D8BF7" }}
                          />
                        </div>

                        <div className="col-md-1"><label htmlFor="sku">SKU</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="sku"
                            placeholder="Enter SKU"
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                            style={{ border: "1px solid #1D8BF7" }}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-2"><label htmlFor="quantity">Quantity</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="quantity"
                            placeholder="Enter Quantity"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            style={{ border: "1px solid #1D8BF7" }}
                          />
                        </div>
                        <div className="col-md-1"><label htmlFor="rate">Rate</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="rate"
                            placeholder="Enter Rate"
                            value={rate}
                            onChange={(e) => setRate(e.target.value)}
                            style={{ border: "1px solid #1D8BF7" }}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-2"><label htmlFor="tax">Tax (%)</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="tax"
                            placeholder="Enter Tax (%)"
                            value={tax}
                            onChange={(e) => setTax(e.target.value)}
                            style={{ border: "1px solid #1D8BF7" }}
                          />
                        </div>

                        <div className="col-md-1"><label htmlFor="netAmount">Net Amount</label></div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            id="netAmount"
                            placeholder="Calculated Net Amount"
                            value={netAmount}
                            readOnly
                            style={{ border: "1px solid #1D8BF7" }}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="col-md-2">
                        <CustomButton fill type="submit" name="button">ADD</CustomButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

export default PurchaseOrder;
