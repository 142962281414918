import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";

import Modal from "react-responsive-modal";
// import "react-step-progress-bar/styles.css";
import CustomButton from '../../components/CustomButton/CustomButton';
// import "../../index.css";
import "./autocomplete.css";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { NotificationManager } from "react-notifications";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [loading, setLoading] = useState(true);
  const [searchLocation, setSearchLocation] = useState("");
  const [saleableQuantity, setSaleableQuantity] = useState(0);
  const [nonSaleableQuantity, setNonSaleableQuantity] = useState(0);
  const [damageQuantity, setDamageQuantity] = useState(0);
  const [saleableInput, setSaleableInput] = useState(false);
  const [nonsaleableInput, setNonsaleableInput] = useState(false);
  const [damageInput, setDamageInput] = useState(false);
  const [saleableInputDisplay, setSaleableInputDisplay] = useState(false);
  const [nonsaleableInputDisplay, setNonsaleableInputDisplay] = useState(false);
  const [damageInputDisplay, setDamageInputDisplay] = useState(false);
  const [qcaction, setQcaction] = useState('');
  const [binLocation, setBinLocation] = useState('');
  const [sku, setSku] = useState('');
  const [quantity, setQuantity] = useState('');
  const [remark, setRemark] = useState('');
  // const [processby, setProcessby] = useState('');
  const [inventoryBucket, setInventoryBucket] = useState('');
  let history = useHistory();
  let userId = localStorage.getItem("userId");

  useEffect(() => {
    // let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    
    setLoading(false);

    setSaleableInputDisplay(true)
    setSaleableInput(true)
    
  }, []);
  

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log('string, results')
    console.log(string, results)
    scanUpdate(string)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    // console.log('selectitem')
    // console.log(item.name)
    setBinLocation(item.name)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnClear = () => {
    console.log("Cleared");
  };

  const formatResult = (item) => {
    console.log(item);
    return (
      <div className="result-wrapper">
        <span className="result-span">name: {item.name}</span>
      </div>
    );
  };

  const scanUpdate = async (searchLocation) => {
    // e.preventDefault();
    
    // console.log(searchLocation)
    let obj = {
      'searchLocation':searchLocation
    }
    
    await axios.post('/wms/getShelfLocation', obj).then((response) => {
        if(response.data.error==1){
          setBinLocation(searchLocation)
        }else{
          // console.log(response.data.message)
          setSearchLocation(response.data.message)
        }
      
    });
        
  };

  const handleSelect = (e) => {
    setQcaction(e.target.value)
    // console.log(e.target.value)
    
    if(e.target.value == 'saleable'){
      
      if(saleableInput==false){
        setSaleableInputDisplay(true)
      }
      
      setSaleableInput(true)
    }

    if(e.target.value == 'non_saleable'){
      
      if(nonsaleableInput==false){
        setNonsaleableInputDisplay(true)
      }
      
      setNonsaleableInput(true)
    }

    if(e.target.value == 'damage'){
      
      if(damageInput==false){
        setDamageInputDisplay(true)
      }
      
      setDamageInput(true)
    }

  }


  const updateLocation = (e) => {

    const formData = new FormData();
    
    formData.append('sku', sku);
    formData.append('quantity', quantity);
    formData.append('qcaction', qcaction);
    formData.append('saleableQuantity', saleableQuantity);
    formData.append('nonSaleableQuantity', nonSaleableQuantity);
    formData.append('damageQuantity', damageQuantity);
    formData.append('remark', remark);
    formData.append('binLocation', binLocation);
    formData.append('inventoryBucket', inventoryBucket);

    if((parseInt(saleableQuantity)+parseInt(nonSaleableQuantity)+parseInt(damageQuantity))==0){
      NotificationManager.error("Quantity should be enter");
      return false;
    }

    if((parseInt(saleableQuantity)+parseInt(nonSaleableQuantity)+parseInt(damageQuantity))>quantity){
      // console.log(saleableQuantity+nonSaleableQuantity+damageQuantity)
      NotificationManager.error("Entered quantity should not be more than Return quantity");
      return false;
    }

    if((parseInt(saleableQuantity)+parseInt(nonSaleableQuantity)+parseInt(damageQuantity))<quantity){
      // console.log(saleableQuantity+nonSaleableQuantity+damageQuantity)
      NotificationManager.error("Entered quantity should not be less than Return quantity");
      return false;
    }

    axios.post("/wms/freshIncomingStockUpdate/",formData)
    .then(resData => {
      
      if(resData.data.error==1){
        NotificationManager.error(resData.data.response);
      }else{
        
        // openModel()
        NotificationManager.success(resData.data.response);
      }
      
    });

  }

  // const scanSku = (e) => {

  //   const skudata = new FormData()
  //   skudata.append('sku', sku)
    
  //   axios.post("/wms/getQtyShelflocation/",skudata)
  //   .then(resData => {
  //     console.log(resData.data)
  //     if(resData.data.error==1){
  //       NotificationManager.error(resData.data.message);
  //     }else{
  //       // console.log(resData.data.message.quantity)
  //       setQuantity(resData.data.message.quantity)
  //       setProcessby(resData.data.message.processby)
  //       setInventoryBucket(resData.data.message.inventoryBucket)
  //     }
      
  //   });
  // }

  return (
    <div className="content">
      
        <div className="content ">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div style={{ cursor: "pointer" }} >
                  <div className="form-group">
                        <div className='card ' style={{ marginTop: 0 , padding: '0px 10px' }}>
                          
                          <div className='row'>
                            <div className='col-md-2'>
                              <label htmlFor='sku'>SKU</label>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <input type='text' className='form-control' name="sku"  id='sku' value={sku} 
                                    onChange={(e) => setSku(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='col-md-1'>
                              <label htmlFor='quantity'>Quantity</label>
                            </div>
                            <div className='col-md-2'>
                                <div className='form-group'>
                                    <input type='text' className='form-control' name="quantity"  id='quantity' value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                                </div>
                            </div>

                            <div className='col-md-1'>
                              <label htmlFor='inventorybucket'>Inventory Bucket</label>
                            </div>
                            <div className='col-md-2'>
                                <div className='form-group'>
                                    <select className='form-control' name="inventorybucket" id='inventorybucket' value={inventoryBucket} onChange={(e) => setInventoryBucket(e.target.value)}  >
                                        <option value="">Select</option>
                                        <option value="0">India</option>
                                        <option value="3">Bulk</option>
                                    </select>
                                </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-2'>
                              <label htmlFor='action'>Action</label>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <select name="qc_action" id="qc_action" className="form-control" value={qcaction} onChange={handleSelect} >
                                      <option value="saleable">Saleable</option>
                                      <option value="non_saleable">Non-Saleable</option>
                                      <option value="damage">Damage</option>
                                    </select>
                                </div>
                            </div>
                          </div>

                          <div className="row" style={{ "marginBottom": "10px"}}>
                            <div className='col-md-2'>&nbsp;</div>
                            {saleableInputDisplay?( 
                              <div className="col-md-3">
                                <div className="row">
                                    <div className="col-md-12">
                                      <label>Saleable</label>
                                    </div>
                                    <div className="col-md-12">
                                      <input type='text' name='saleableQty' value={saleableQuantity} onChange={(e) => setSaleableQuantity(e.target.value)} />
                                    </div>
                                </div>
                              </div>
                            ):''}
                            {nonsaleableInputDisplay?( 
                              <div className="col-md-3">
                                <div className="row">
                                    <div className="col-md-12">
                                      <label>Non-Saleable</label>
                                    </div>
                                    <div className="col-md-12">
                                      <input type='text' name='nonsaleableQty' value={nonSaleableQuantity} onChange={(e) => setNonSaleableQuantity(e.target.value)}  />
                                    </div>
                                </div>
                              </div>

                            ):''}
                            {damageInputDisplay?( 
                              <div className="col-md-3">
                                <div className="row">
                                    <div className="col-md-12">
                                      <label>Damage</label>
                                    </div>
                                    <div className="col-md-12">
                                      <input type='text' name='damageQty' value={damageQuantity} onChange={(e) => setDamageQuantity(e.target.value)}  />
                                    </div>
                                </div>
                              </div>

                            ):''}
                        </div>

                          <div className='row'>
                            <div className='col-md-2'>
                              <label htmlFor='remark'>Remark</label>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                  <textarea className='form-control' id='remark' name="remark" value={remark} onChange={(e) => setRemark(e.target.value)} ></textarea>
                                </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-2'>
                              <label htmlFor='producaccount_namet_quantity'>Bin Location</label>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <div className="my-custom-wrapper">
                                      <ReactSearchAutocomplete
                                        items={searchLocation}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        onClear={handleOnClear}
                                        styling={{ zIndex: 1 }}
                                        formatResult={formatResult}
                                        autoFocus
                                      />
                                    </div>
                                    
                                </div>
                            </div>
                          </div>
                            
                            <div className='col-md-2'>
                                <CustomButton fill type='submit' name='button' onClick={(e) => updateLocation()}>Update</CustomButton>
                            </div>

                        </div>
                  </div>
                </div>
            </div>
          </div>

        </div>
      
    </div>
  );
}
export default OrderList;
