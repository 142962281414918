import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import InWardUpload from "../../views/WmsScreenRoutes/InWardUpload";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import CustomButton from '../../components/CustomButton/CustomButton';
import "react-step-progress-bar/styles.css";
import "../../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [awb, setAwb] = useState('');
  const [sku, setSku] = useState('');
  const [quantity, setQuantity] = useState('');
  const [qcaction, setQcaction] = useState('');
  const [claim, setClaim] = useState('');
  const [remark, setRemark] = useState('');
  const [scantype, setScantype] = useState('');
  const [accountname, setAccountname] = useState('');
  const [saleableQuantity, setSaleableQuantity] = useState(0);
  const [nonSaleableQuantity, setNonSaleableQuantity] = useState(0);
  const [damageQuantity, setDamageQuantity] = useState(0);
  const [saleableInput, setSaleableInput] = useState(false);
  const [nonsaleableInput, setNonsaleableInput] = useState(false);
  const [damageInput, setDamageInput] = useState(false);
  const [saleableInputDisplay, setSaleableInputDisplay] = useState(false);
  const [nonsaleableInputDisplay, setNonsaleableInputDisplay] = useState(false);
  const [damageInputDisplay, setDamageInputDisplay] = useState(false);
  
  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // setLoading(false);
    // getOrders(currentPage, showPerPage, {});
    setSaleableInputDisplay(true)
    setSaleableInput(true)
  }, []);

  const onOptionChange = e => {
    setClaim(e.target.value)
  }
  

  const scanUpdate = async (e) => {
    // e.preventDefault();
    
    // console.log(11111111)
    // return false;
    // const form = document.getElementById("form");
    const formData = new FormData();
    // console.log(formData);

    formData.append('awb_scan', awb);
    formData.append('sku', sku);
    formData.append('quantity', quantity);
    formData.append('qc_action', qcaction);
    formData.append('saleableQty', saleableQuantity);
    formData.append('nonsaleableQty', nonSaleableQuantity);
    formData.append('damageQty', damageQuantity);
    formData.append('claim', claim);
    formData.append('remark', remark);
    formData.append('customer_name', accountname);
    formData.append('scan_type', scantype);

    // console.log(saleableQuantity)
    // console.log(nonSaleableQuantity)
    // console.log(damageQuantity)

    if((parseInt(saleableQuantity)+parseInt(nonSaleableQuantity)+parseInt(damageQuantity))==0){
      NotificationManager.error("Quantity should be enter");
      return false;
    }

    if((parseInt(saleableQuantity)+parseInt(nonSaleableQuantity)+parseInt(damageQuantity))>quantity){
      console.log(saleableQuantity+nonSaleableQuantity+damageQuantity)
      NotificationManager.error("Entered quantity should not be more than Return quantity");
      return false;
    }
    
    await axios.post('/wms/scan', formData).then((response) => {
        
        if(response.data.error === 0){
          setAwb('');
          setSku('');
          setQuantity('');
          setQcaction('');
          setSaleableQuantity('');
          setNonSaleableQuantity('');
          setDamageQuantity('');
          setClaim('');
          setRemark('');
          setAccountname('');
          
          NotificationManager.success(response.data.response);
        }else if(response.data.error === 2){
          NotificationManager.warning(response.data.response);
        }
        else{
          setAwb('');
          setSku('');
          setQuantity('');
          setQcaction('');
          setSaleableQuantity('');
          setNonSaleableQuantity('');
          setDamageQuantity('');
          setClaim('');
          setRemark('');
          setAccountname('');  
          
          NotificationManager.success(response.data.response);
        }
        
    });
        
  };

  const get_qty = async (e) => {
    // e.preventDefault();

    const awbdata = new FormData()
    awbdata.append('awb', awb)
    
    await axios.post('/wms/get_qty', awbdata).then((response) => {
      if(response.data.error==1){

      } else{
        
        if(response.data.length>0){
          console.log(response.data[0].scan)

          // setScantype(response.data[0].scan);
          
          setSku(response.data[0].sku);
          setQuantity(response.data[0].qty);
          setAccountname(response.data[0].account_name);
        }
        
        
        // setScantype(response.data[1].scan);

        // setSku(response.data[0].sku);
        // setQuantity(response.data[0].qty);
        // setAccountname(response.data[0].account_name);
      }
        
    });
        
  };

  const handleSelect = (e) => {
    setQcaction(e.target.value)
    console.log(e.target.value)
    
    if(e.target.value == 'saleable'){
      
      if(saleableInput==false){
        setSaleableInputDisplay(true)
      }
      
      setSaleableInput(true)
    }

    if(e.target.value == 'non_saleable'){
      
      if(nonsaleableInput==false){
        setNonsaleableInputDisplay(true)
      }
      
      setNonsaleableInput(true)
    }

    if(e.target.value == 'damage'){
      
      if(damageInput==false){
        setDamageInputDisplay(true)
      }
      
      setDamageInput(true)
    }

  }

  return (
    <div class="content">
        <div className="content ">
          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div style={{ cursor: "pointer" }} >
                <div className="form-group">
                  {/* <form id="form" method="post"> */}
                      <div className='card ' style={{ marginTop: 0 , padding: '0px 10px' }}>
                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='awb_scan'>Scan(AWB)</label>
                          </div>
                          <div className='col-md-6'>
                              <div className='form-group'>
                                  <input type='text' className='form-control' name="awb_scan" id='awb_scan' value={awb} onChange={(e) => setAwb(e.target.value)} 
                                  // onKeyUp={get_qty} 
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                    get_qty();
                                    }}
                                   />
                              </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='sku'>SKU</label>
                          </div>
                          <div className='col-md-3'>
                              <div className='form-group'>
                                  <input type='text' className='form-control' name="sku"  id='sku' value={sku} onChange={(e) => setSku(e.target.value)} />
                              </div>
                          </div>

                          <div className='col-md-1'>
                            <label htmlFor='quantity'>Return Quantity</label>
                          </div>
                          <div className='col-md-2'>
                              <div className='form-group'>
                                  <input type='text' className='form-control' name="quantity"  id='quantity' value={quantity} onChange={(e) => setQuantity(e.target.value)} readOnly={true} />
                              </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='action'>Action</label>
                          </div>
                          <div className='col-md-6'>
                              <div className='form-group'>
                                  <select name="qc_action" id="qc_action" className="form-control" value={qcaction} onChange={handleSelect} >
                                    <option value="saleable">Saleable</option>
                                    <option value="non_saleable">Non-Saleable</option>
                                    <option value="damage">Damage</option>
                                  </select>
                              </div>
                          </div>
                          
                        </div>

                        <div className="row">
                            <div className='col-md-2'>&nbsp;</div>
                            {saleableInputDisplay?( 
                              <div className='col-md-3'>
                                <label>Saleable</label>
                                <input type='text' name='saleableQty' value={saleableQuantity} onChange={(e) => setSaleableQuantity(e.target.value)} /></div>
                            ):''}
                            {nonsaleableInputDisplay?( 
                              <div className='col-md-3'>
                                <label>Non-Saleable</label>
                                <input type='text' name='nonsaleableQty' value={nonSaleableQuantity} onChange={(e) => setNonSaleableQuantity(e.target.value)}  /></div>
                            ):''}
                            {damageInputDisplay?( 
                              <div className='col-md-2'>
                                <label>Damage</label>
                                <input type='text' name='damageQty' value={damageQuantity} onChange={(e) => setDamageQuantity(e.target.value)}  /></div>
                            ):''}
                        </div>

                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='product_quantity'>Claim</label>
                          </div>
                          <div className='col-md-1'>
                              <div className='form-group'>
                                  <span>Yes</span>&nbsp;
                                  <input type='radio' name='claim' value="1" checked={claim === "1"} onChange={onOptionChange} />
                              </div>
                          </div>
                          <div className='col-md-1'>
                              <div className='form-group'>
                                  <span>No</span>&nbsp;
                                  <input type='radio' name='claim' value="2" checked={claim === "2"} onChange={onOptionChange} />
                              </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='remark'>Remark</label>
                          </div>
                          <div className='col-md-6'>
                              <div className='form-group'>
                                <textarea className='form-control' id='remark' name="remark" value={remark} onChange={(e) => setRemark(e.target.value)} ></textarea>
                              </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='producaccount_namet_quantity'>Account Name</label>
                          </div>
                          <div className='col-md-6'>
                              <div className='form-group'>
                                  <input type="text" className='form-control'  id='customer_name' name="customer_name" value={accountname} readOnly={true} />
                                  <input type="hidden" className='form-control'  id='scan_type' name="scan_type" value={scantype} />
                              </div>
                          </div>
                        </div>
                          
                          <div className='col-md-2'>
                              {/* <CustomButton fill type='submit' name='button' 
                              onClick={() => {
                                scanUpdate();  // This runs only on button click
                              }}
                              >Update</CustomButton> */}
                              <button
                                style={{
                                  background: "rgb(255, 160, 0)",
                                  borderColor: "rgb(255, 160, 0)",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                                className="btn btn-primary btn-fill"
                                onClick={() => scanUpdate()}
                              >
                                Update
                              </button>
                          </div>
                      </div>
                  {/* </form> */}
                </div>
              </div>
             
            </div>
          </div>
        </div>
     
    </div>
  );
}
export default OrderList;
