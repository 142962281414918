import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Modal from "react-responsive-modal";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from "react-bootstrap";
import "../../index.css";
import { NotificationManager } from "react-notifications";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OffShelf() {
  const [searchAWB, setSearchAWB] = useState("");
  const [loading, setLoading] = useState(true);
  const [orderShipMethod, setOrderShipMethod] = useState("")
  const [data, setData] = useState("")
  const [supllierList, setSupplierList] = useState([])
  const [fromDate, setFromDate] = useState("");
  const [bagList, setBagList] = useState("");
  
  // useEffect(() => {
  
  //   setLoading(false);
    
  // }, [supllierList]);

  useEffect(() => {
    
    setLoading(false);
    
    getSupplierList();
  }, []);
  
  const getSupplierList = () => {
    axios.get("/wms/bagList").then((response) => {
      setSupplierList(response.data.result);
    });
  }
  
  const getManifestOrders = (searchAWB) => {
	setLoading(false);
	if(orderShipMethod == null || orderShipMethod == ''){
		NotificationManager.error(`Please select Shipping Method.`);
        return;
	}
    const obj = {
		awb: searchAWB,
		method:orderShipMethod
	}; 
	axios.post("/wms/getManifest/",obj)
		.then(res => {
    	setSearchAWB('');
    	if(res.data.success == 1){
			console.log(res.data);
    		setData(res.data.orders);
        } else {
			console.log(res.data);
			NotificationManager.error(res.data.msg);
			return;
		}
      })
      .catch((err) => {
        setLoading(false);
       
        console.log("Error", err);
      });
  };


  function scanInvoice(){
   
    getManifestOrders( {
      text: searchAWB,
      });
    }
	function getOrderByAwb () {
		 getManifestOrders(searchAWB);
		 setSearchAWB('');	
	}
	const handleSearchBarcode = (e) => {
    setSearchAWB(e.target.value);
  };
	 
    const handleByShipMethod = e => {
        setOrderShipMethod(e.target.value); 
    }
    
 const callSearchFunc = (e) => {
    e.persist();
    const selectedSupplier = document.getElementById("supplierList").value;
    console.log("Selected Supplier ID:", selectedSupplier); // Debugging log
    if (selectedSupplier !== "Select Supplier") {
        this.setState({ searchSku: "" }, () => {
            this.searchFunc();
        });
    }
    
};

const handleFromdate = (fromDate) => {
  setFromDate(fromDate);
  // setErrors({});
};

const downloadBagings = () => {
  // bagList;
  // fromDate;

  if(fromDate==''){
    console.log('date missing')
    return false;
  }

  if(bagList==''){
    console.log('bagList missing')
    return false;
  }

  let userId = localStorage.getItem("userId");

  const obj = {
		bagList: bagList,
		fromDate:fromDate,
    userId:userId
	};
  axios.post("/wms/downloadBagList", obj).then((response) => {
    console.log(response.data)
    if(response.data.error==0){
      // window.location.href = response.data.message
      window.open(response.data.message, '_blank')
    }
  });

};

const selectBagList = (val) => {
  setBagList(val)
}

  return (
    <div className="content">
        <div className="content ">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group"> 
                    <label>Shiping Method</label>
                    <select className="form-control" onChange={ handleByShipMethod } > 
                      <option value="">Select</option>
                      <option value="Bluedart">Bluedart</option>
                      <option value="Delhivery">Delhivery</option>
                      <option value="DTDC">DTDC</option>
                      <option value="Ecomxpress">Ecomxpress</option>
                      <option value="IndiaPost">India Post</option>
                      <option value="ShiprocketIndiaPost">Shiprocket India Post</option>
                      <option value="Xpressbees">Xpressbees</option>
                    </select>
                  </div>
					
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group"> 
                    <label >Scan AWB</label>
                    <input type="text"  className="form-control " onKeyDown={(e) => {
                                if (e.key === "Enter")
                                  getOrderByAwb();
                                }} onChange={handleSearchBarcode} value={searchAWB} id="awb" placeholder="Scan AWB" autoComplete="off" />
                  </div>
                </div>
                
              </div>
              <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group"> 
                    <label >Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="fromDate"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="From Date"
                      value={fromDate}
                      onChange={(e) => handleFromdate(e.target.value)}
                    />
                    
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group"> 
                    <label >Bag List</label>
                    <select
                    className="form-control"
                    id="supplierList"
                    // onChange={callSearchFunc}
                    onChange={(e) => selectBagList(e.target.value)}
                    >
                      <option value="Select Supplier">Select Shipping Method</option>
                        {supllierList.map((data) => {
                        const { method } = data;
                        return (
                        <option key={method} value={method.toString()}>
                        {method}
                      </option>
                      );
                      })}
                    </select>  
                  </div>
                </div>
                
                <div className="col-sm-12 col-md-6 col-lg-1 col-xl-1">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => downloadBagings()}
                  >
                    DOWNLOAD
                  </button>
                </div>           
              </div>

            </div>
          </div>
          

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Barcode</th>
                    <th scope="col">Reference Number</th>
                    <th scope="col">City</th>
                    <th scope="col">Pincode</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Weight</th>
                  </tr>
                </thead>
                <tbody>
                {!isEmpty(data) ? (
                    data.map((manifest) => (
                      <tr key={manifest._id}>  
						  <td>{manifest.ordership[0].ref_tracking_number}</td>
						  <td>{manifest.orders_code}</td>
						  <td>{manifest.consignee_city}</td>
						  <td>{manifest.consignee_zip}</td>
						  <td>{manifest.consignee_first_name}</td>
						  <td>{manifest.consignee_phone}</td>
						  <td>{manifest.ordership[0].os_estimate_weight}</td>
						</tr>
                    ))
                  ) : (
                     ( // Show message if a search was attempted and no data is found
                      <tr>
                        <td colSpan="7" className="text-center">
                          <h4>No Data Found</h4>
                        </td>
                      </tr>
                    )
                  )}
                
                </tbody>
              </table>

            </div>
          </div>
        </div>
      {/*) : (
        <h1>Hello</h1>
      )} */}
    </div>
  );
}
export default OffShelf;
