import Dashboard from "./views/financeUserDashborad";
import Billing from "./views/financeUserBilling";

const dashboardRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "pe-7s-graph",
        component: Dashboard,
        layout: "/finance",
    },
    {
        path: "/billing",
        name: "Billing",
        icon: "pe-7s-id",
        component: Billing,
        layout: "/finance",
    }
]

export default dashboardRoutes;

