import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import isEmpty from "is-empty";


import "../../assets/css/productList.css";
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import CustomButton from '../../components/CustomButton/CustomButton';
import { useParams } from 'react-router-dom';
var Barcode = require('react-barcode');

const PrintA4 = () => {
  const token = localStorage.getItem("token");
  
  const [csvResults, setCsvResults] = useState([]);
  const [orderMap, SetOrderMap] = useState([]);
  const [orderMapCount, SetOrderMapCount] = useState([]);
  const [orderCount, SetOrderCount] = useState([]);
  const [skuCount, SetSkuCount] = useState([]);
  const [qtyCount, SetQtyCount] = useState([]);

  const { opcode } = useParams();

  useEffect(() => {
    
    axios.get(`/wms/orderMap/${opcode}`)
      .then(res=> {
          SetOrderMap(res.data.data)
          // console.log(res.data.op_order_cnt)
          SetOrderCount(res.data.order_qty)
          SetSkuCount(res.data.sku_qty)
          SetQtyCount(res.data.qty)
          SetOrderMapCount(res.data.data.length)
          // console.log(res.data.data.length)
      })
	
  }, []);
		 
const handlePageClick = (e) => { 
      // getcsvData((e.selected + 1), showPerPage) 
};
let page = 1;

return (
    <div className="mainBox">
    <br />
  
  {!isEmpty(orderMap) ? (
    orderMap.map((inw1, index) => {
      let i = 1;
      return (
        <div className="container-fluid">
            <div className="card">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                {/* <img src="/product/receiving/barcode/code/HZA2404250161" /> */}
                <Barcode value={opcode} />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="form-group">
                  <label>Off-Shelf</label>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div >
                    &nbsp;Number：{opcode}<br />
                    Picking Staff：Shopperr<br />
                    Page:：{page++} of {orderMapCount} 
                  </div>
              </div>
            </div>
          </div>
          
          <div className="card" >
            <div className="row">
              <table className="table table-hover" border="1">
                <tbody><tr>
                    <td>Operator</td>
                    <td>shopperr</td>
                    <td>Printed Time</td>
                    <td>2024-04-29</td>
                    <td>Order Quantity</td>
                    <td>{orderCount}</td>
                    <td>SKU</td>
                    <td>{skuCount}</td>
                    <td>ITEM</td>
                    <td>{qtyCount}</td>
                </tr>
                </tbody>
              </table>
                
              <table className="table table-hover" border="1">
                <thead>
                  <tr>
                    <th scope="col">S/No.</th>
                    <th scope="col">Shelf Location</th>
                    <th scope="col">Product Barcode</th>
                    <th scope="col">Image</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Remark</th>
                  </tr>
                </thead>
                <tbody>
                {
                  
                  inw1.map((inw) => {
                    let d = new Date(inw.op_create_date);
                    let image = inw.images!=null ? inw.images : 'https://chinafullfilment.shopperr.in/default/index/view-picture/id/1344933/type/icon/thumb/yes';
                      return (
                        <tr>
                          <td>{i++}</td>
                          <td>{inw.ws_code}</td>
                          <td>
                          <br/>STK01{inw.product_sku}
                          </td>
                          <td>
                            <a href={image} target="_blank">
                              <img src={image} style={{width:"75px", height:"75px"}} />
                            </a>
                          </td>
                          <td><font size="30px"><b>{inw.opm_quantity}</b></font></td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      
                      );
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
      );
      
    })
      ) : (
        <tr>
          <td colSpan="8" className="text-center">
          </td>
        </tr>
      )}
	
    </div>
  );
};

export default PrintA4;
