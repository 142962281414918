import React, { Component, useState, useEffect } from 'react'
import SyncLoader from "react-spinners/SyncLoader"
import { Redirect } from "react-router-dom"
import _, { set } from 'lodash'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import validator from 'validator'
import Select from 'react-select'
import { Button } from '@material-ui/core'

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`

const Selectsearch = (props) => {
    const { setter, wareHouse } = props
    const [options, setOptions] = useState([])
    const [value, setValue] = useState('')
    
    useEffect(() => {
        axios.get('/admin/viewwarehouse')
            .then((response) => { 
                console.log('API Response:', response.data); // Log the response to see the structure
                let temp = response.data.map((ele) => {
                    if (ele.warehouse_name && ele.warehouse_code) { // Check if both properties exist
                        return { value: ele, label: `Name: ${ele.warehouse_name} Code: ${ele.warehouse_code}` }
                    } else {
                        console.error('Missing properties in warehouse object:', ele);
                        return { value: ele, label: 'Invalid Data' } // Handle cases where properties are missing
                    }
                })
                setOptions(temp)
            })
            .catch((err) => {
                console.error('Error fetching warehouse data:', err.message)
                alert('Failed to fetch warehouse data.')
            })
    }, [])

    return (
        <Select
            options={options}
            onChange={(opt) => setter(opt.value)}
            isSearchable
        />
    )
}

export default class EditLogisticUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            type: [],
            name: "",
            email: "",
            password: "",
            oldPassword: "",
            wareHouse: "",
            wareHouseId: "",
            errors: {},
            loading: false
        }
    }

    componentDidMount() {
        axios.get(`/admin/getSelectedLogisticUser/${localStorage.getItem("code")}`)
            .then(result => {
                this.setState({
                    name: result.data.logisticUser_id ? result.data.logisticUser_id : "",
                    wareHouse: result?.data?.warehouse,
                    wareHouseId: result?.data?.warehouseId,
                    email: result.data.email ? result.data.email : "", 
                    type: result.data.type ? result.data.type.split(",") : [],
                    oldPassword: result.data.password, 
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                NotificationManager.error("Sorry, couldn't fetch the user properly")
            })
    }

    handleUpdateUSer = e => {
        e.preventDefault()
        let obj = {
            name: this.state.name,
            password: this.state.password,
            email: this.state.email,
            type: this.state.type.toString(),
            _id: localStorage.getItem("code"),
            oldPassword: this.state.oldPassword,
            wareHouse: this.state.wareHouse,
            warehouseId: this.state.wareHouseId
        }
        let validate = this.validateData(obj)
        if (validate.isValid) {
            this.setState({ loading: true })
            axios.put("/admin/updateLogisticUser", obj)
                .then(result => {
                    if (result.data === 1) {
                        NotificationManager.success("Logistic User updated successfully")
                        this.setState({ loading: false, redirect: true })
                    } else {
                        NotificationManager.error("Failed to update user")
                    }
                })
                .catch(err => {
                    console.log(err)
                    NotificationManager.error("Failed to update user")
                })
        } else {
            this.setState({ errors: validate.errors })
        }
    }

    validateData(data) {
        let errors = {}

        if (_.isEmpty(data.name)) {
            errors.name = "Name should not be empty"
        }

        if (_.isEmpty(data.email)) {
            errors.email = "Email Address should not be empty"
        } else if (!validator.isEmail(data.email)) {
            errors.email = "Invalid Email Address"
        }

        return { errors: errors, isValid: _.isEmpty(errors) }
    }

    handleWareHouse = (value) => {
        this.setState({ wareHouse:value.warehouse_code, wareHouseId:value.warehouse_id })
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to="/admin/logisticUsersList" />
            )
        } else {
            return (
                <div>
                    <br/>
                    <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
                    <div className="container-fluid">
                        <div className="card" style={{ padding: "1em" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Logistic User Id</label>
                                        <input type="text" className="form-control"
                                            placeholder="Logistic User Id"
                                            value={this.state.name}
                                            onChange={(e) => this.setState({ name: e.target.value })} />
                                    </div>
                                    {this.state.errors.name && <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.name}</span>}
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="text" className="form-control"
                                            placeholder="Email Address"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ email: e.target.value, errors: {} })} />
                                    </div>
                                    {this.state.errors.email && <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.email}</span>}
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <TagsInput 
                                            value={this.state.type}
                                            onChange={type => this.setState({ type })}
                                            onlyUnique
                                            addKeys={[9, 13, 32, 188]}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control"
                                            placeholder="Email Password"
                                            value={this.state.password}
                                            onChange={(e) => this.setState({ password: e.target.value, errors: {} })} />
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Search Warehouse</label>
                                        <br />
                                        <Selectsearch setter={this.handleWareHouse} wareHouse={this.state.wareHouse} />
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-3">
                                    <button className="btn btn-outline-primary" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500', width: "140px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px', fontSize: '15px' }} onClick={this.handleUpdateUSer}>Update User</button>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
