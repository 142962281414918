import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import "../../assets/css/productList.css";
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import CustomButton from '../../components/CustomButton/CustomButton';

const InWardUpload = () => {
  const [productItems, setProductItems] = useState([]); 
  const token = localStorage.getItem("token");
  // const decode = jwt_decode(token);
  const [csvData, setCsvData] = useState([]);
  const [csvNewData, setCsvNewData] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [warranty, setWarranty] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [code, setCode] = useState("");
  const [itemId, setItemId] = useState("");
  const [limit, setlimit] = useState(100);
  const [page, setPage] = useState(1);
  const [pageUp, setPageUp] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageCountIm, setPageCountIm] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageIm, setCurrentPageIm] = useState(1);
  const [showPerPage, setShowPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [supllierList, setSupllierList] = useState([]);
  const [csvResults, setCsvResults] = useState([]);

  const modalStyle = {
    margin: "auto",
    position: "relative",
  };

  useEffect(() => {

	getcsvData(1, showPerPage) ;
  }, []);
	

	
	const handlePageClick = (e) => { 
        getcsvData((e.selected + 1), showPerPage) 
    };
    
    // const handlePageClickIm = (e) => { 
    //     getcsvImData((e.selected + 1), showPerPage) 
    // }; 

	const getcsvData = (currentPage,showPerPage) =>{
		
		let suppId = localStorage.getItem("userId")
		
		axios.get("/wms/getUploadInwardCsv?page="+currentPage+"&limit="+showPerPage+"+&id="+suppId)
		.then(resData => {
			let count = resData.data.items;
			let rows = []
            for (var i = 0; i < Math.ceil(resData.data/limit); i++) {
                rows.push(i+1)
            } 
            setPage(rows)
            setPageCount(Math.ceil(resData.data.totalCount / showPerPage))
            setCurrentPage(currentPage)
            setCsvResults(resData.data.items);
		})
    };
    

    //Add Product from CSV File
  const addCSvProduct = (e) => {
    e.preventDefault();
    
    const scvdata = new FormData();
    scvdata.append('file', csvData[0]);
    scvdata.append('id', localStorage.getItem("userId"));
    // console.log(scvdata)
    axios
      .post('/wms/uploadInwardCsv', scvdata)
      .then((res) => {
        console.log(res.data.error);
		// if (res.data.includes('success')) {
		if (res.data.error==0) {
          NotificationManager.success('File uploaded Successfully');
        } else {
          res.data.error
            ? NotificationManager.error(res.data.Response.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

return (
    <div className="mainBox">
      <br />
    <div className="container-fluid">
      <div className="card" style={{padding: "1em"}}>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <label htmlFor='productImage' style={{fontSize: 15 }}>Upload Inward File</label>
            <form onSubmit={addCSvProduct} style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
              <div className='form-group' style={{margin: "0"}}>        
                <div id='fileContents'></div>
                <input
                  type='file'
                  className='form-control text-center'
                  name='avatar'
                  onChange={(e) => { 
                  setCsvData(e.target.files);
                  }}
                  encType='multipart/form-data'
                  accept='.csv'
                />
                </div>
                <div className='' style={{margin: "0 0 0 10px"}}>
                <CustomButton fill type='submit'>
                  Upload Inward
                </CustomButton>
                </div>
            </form>
            {/* <a href="https://static-sellercentral.shopperr.in/import_export/2021/10/upload8eQonE_605194af3da40def6172dfac.csv" target="_blank">Sample Csv</a> */}
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid">
      <div className="card" style={{padding: "1em"}}>
        <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="table-responsive">
                <p>Inward File CSV</p>
                              <table className="table table-hover ProductListTable">
                                  <thead>
                                      <tr className="d-flex">
                      <th className='text-center' scope="col" >S.NO.</th>
                                          <th className='text-center' scope="col" >Status</th>
                                          <th className='text-center' scope="col" >Action</th>  
                                          <th className='text-center' scope="col" >Logs</th>  
                                          <th className='text-center' scope="col" >Created Date</th>  
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {
                                        !_.isEmpty(csvResults)
                                          ?  
                                              csvResults.map((item, index) => {
                                                  const {_id, status , action , resUrl , date} = item
                                                  return(
                                                      <tr key={index} className="d-flex">
                              <td className='text-center'>{((currentPage - 1) * showPerPage) + (index + 1)}</td>
                                                          <td className='text-center' style={{color: (item.status == 'Success' ? 'Green' : 'orange' ) }}> {status||"NA"}</td>
                                                          <td className='text-center'><a href={ action }>Import</a></td>
                                                          <td className='text-center'><a href={ resUrl }>Download</a></td>
                                                          <td className='text-center'>{date}</td> 
                                                      </tr>
                                                  )
                                              })
                                          :
                                              <tr>
                                                  <td colSpan="5" className="text-center" style={{fontSize: "20px"}}>No Data Found</td>
                                              </tr>
                                      }
                                  </tbody>
                              </table>
                          </div>
              <ReactPaginate
                                  previousLabel={'<'}
                                  nextLabel={'>'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={handlePageClick.bind(this)}
                                  containerClassName={'pagination'}
                                  subContainerClassName={'pages pagination'}
                                  activeClassName={'active'}
                              />
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
  );
};

export default InWardUpload;
