import React, { useState } from 'react';
import axios from 'axios';
import "../../assets/css/supplier.css"; // Ensure this file contains consistent styles
import { Table } from "react-bootstrap";  
import { NotificationManager } from "react-notifications";
import { Box, Button, Typography } from "@mui/material";

const Challan = () => {
  const [barcode, setBarcode] = useState('');
  const [scannedData, setScannedData] = useState({ productCode: '', productName: '', quantity: '' });
  const [consigneeDetails, setConsigneeDetails] = useState({ name: '', address: '' });
  const [manualQuantity, setManualQuantity] = useState('');
  const [challanNumber, setChallanNumber] = useState(generateRandomChallanNumber());
  const [isPrintMode, setIsPrintMode] = useState(false);
  const [productDetails, setProductDetails] = useState([]);

      
  function generateRandomChallanNumber() {
    const characters = 'osfpl1234';
    let result = '';
    for (let i = 0; i < 9; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  const handleScanInput = (e) => {
    const value = e.target.value;
    setBarcode(value);
    if (value.length === 8 || value.length === 10) {
      fetchProductDetails(value);
      setTimeout(() => setBarcode(''), 3000); 
    }
  };
  

  const fetchProductDetails = async (scannedBarcode) => {
    try {
      const response = await axios.get(`/wms/products/${scannedBarcode}`);
      const productData = response.data;
      const newProduct = {
        productCode: productData.Code,
        productName: productData.name,
        quantity:  productData.quantity
      };

      setProductDetails((prevProducts) => [...prevProducts, newProduct]);
      setScannedData(newProduct);
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  const handleConsigneeInputChange = (e) => {
    const { name, value } = e.target;
    setConsigneeDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleManualQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setManualQuantity(value); 
    } else if (Number(value) > 0 && Number(value) <= scannedData.quantity) {
      setManualQuantity(value);
    } else {
      NotificationManager.error(`Please enter a quantity greater than 0 and less than or equal to ${scannedData.quantity}`);
    }
  };
  
  const handleSubmitChallan = async () => {
    const productEntry = {
        productCode: scannedData.productCode,
        productName: scannedData.productName,
        quantity: manualQuantity || scannedData.quantity, 
    };

    setProductDetails((prevProducts) => {
        const productIndex = prevProducts.findIndex(product => product.productCode === productEntry.productCode);

        if (productIndex > -1) {
            const updatedProducts = [...prevProducts];
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                quantity: manualQuantity || scannedData.quantity 
            };
            return updatedProducts;
        } else {
            return [...prevProducts, productEntry];
        }
    });


    const challanData = {
        challanNumber,
        name: consigneeDetails.name,
        address: consigneeDetails.address,
        productDetails: [...productDetails] 
    };

    try {
        // Save challan to server
        await axios.post('/wms/save-challan', challanData);
        NotificationManager.success('Challan successfully saved!');

        setScannedData({ productCode: '', productName: '', quantity: '' });
        setManualQuantity(''); 
        setIsPrintMode(true); 
    } catch (error) {
        NotificationManager.error('Failed to save challan.');
    }
};
  const handlePrint = () => {
    window.print();
  };

  return (
    <Box
      id="wrapper"
      sx={{
        padding: '40px',
        border: '2px solid #ddd',
        borderRadius: '12px',
        backgroundColor: '#f4f4f4',
        fontFamily: 'Arial, sans-serif',
        width: '700px',
        margin: '0 auto'
      }}
    >
      <style>
        {`
          @media print {
            button,
            .no-print {
              display: none; 
            }
           @page {
             margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 150px;
  margin-left: 80px;

          }
            #wrapper {
              display: block;
            }
          }
        `}
      </style>
      {isPrintMode ? (
        <div>
          <div style={{
            textAlign: 'center',
            paddingBottom: '20px',
            borderBottom: '2px solid #ccc'
          }}>
            <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: '500', color: '#555' }}>
              One Stop Fashions Pvt. Ltd.
            </Typography>
            <Typography variant="body1" sx={{ color: '#777' }}>
              225, 2nd Floor, Phase IV, Udyog Vihar, Sector 18, Gurugram, Haryana 122008
            </Typography>
            <Typography variant="h4" sx={{ fontSize: '36px', fontWeight: '500', color: '#333' }}>
              Challan
            </Typography>
          </div>

          <div style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '2px solid #ccc',
            paddingBottom: '10px'
          }}>
            <div style={{ flexBasis: '48%' }}>
              <Typography variant="h5" sx={{ color: '#444' }}>
                <strong>Challan Number:</strong> {challanNumber}
              </Typography>
              <Typography variant="h5" sx={{ color: '#444' }}>
                <strong>Date:</strong> {new Date().toLocaleDateString()}
              </Typography>
            </div>
            <div style={{ flexBasis: '50%' }}>
              <Typography variant="h5" sx={{ color: '#444' }}>
                <strong>Consignee Details:</strong>
              </Typography>
              <Typography variant="h6" sx={{ margin: '5px 0', color: '#555' }}>
                <strong>Name:</strong> {consigneeDetails.name}
              </Typography>
              <Typography variant="h6" sx={{ margin: '5px 0', color: '#555' }}>
                <strong>Address:</strong> {consigneeDetails.address}
              </Typography>
            </div>
          </div>

          <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            marginBottom: '20px',
            fontSize: '16px',
            color: '#555'
          }}>
            <thead>
              <tr style={{ backgroundColor: '#f1f1f1' }}>
                <th style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>S.No</th>
                <th style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>Product Code</th>
                <th style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>Product Name</th>
                <th style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {productDetails.map((product, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>{product.productCode}</td>
                  <td style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>{product.productName}</td>
                  <td style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>{product.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Button
              variant="contained"
              onClick={handlePrint}
              className="no-print"
              sx={{
                backgroundColor: 'rgb(255, 160, 0)',
                fontSize: '16px',
                padding: '10px 30px',
                fontWeight: '600',
                color: '#fff'
              }}>
              Print Challan
            </Button>
          </div>

          <div style={{
            textAlign: 'center',
            marginTop: '40px',
            paddingTop: '20px',
            borderTop: '2px solid #ccc'
          }}>
            <Typography variant="body1" sx={{ fontSize: '14px', color: '#888' }}>
              Thank you for doing business with us!
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          <Typography variant="h3" sx={{ fontSize: '32px', fontWeight: 'bold', color: '#333', textAlign: 'center' }}>Challan</Typography>

          <Typography variant="h4" sx={{ color: '#333' }}><strong>Consignee Details:</strong></Typography>
          <input
            type="text"
            name="name"
            value={consigneeDetails.name}
            onChange={handleConsigneeInputChange}
            placeholder="Enter name"
            style={{ fontSize: '16px', color: '#555', marginBottom: '10px', width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <input
            type="text"
            name="address"
            value={consigneeDetails.address}
            onChange={handleConsigneeInputChange}
            placeholder="Enter address"
            style={{ fontSize: '16px', color: '#555', width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />

          <Typography variant="h4" sx={{ color: '#333' }}><strong>Product Details (Scan to fetch):</strong></Typography>
          <input
            type="text"
            value={barcode}
            onChange={handleScanInput}
            placeholder="Scan Product Here"
            style={{ padding: '12px', fontSize: '18px', width: '100%', marginBottom: '20px', border: '2px solid #ccc', borderRadius: '5px' }}
          />
          {scannedData.productName ? (
            <Table striped bordered hover responsive style={{ border: '1px solid #ddd' }}>
              <thead>
                <tr>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Add Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{scannedData.productCode}</td>
                  <td>{scannedData.productName}</td>
                  <td>{scannedData.quantity}</td>
                  <td>
                    <input
                      type="number"
                       value={manualQuantity}
                      onChange={handleManualQuantityChange}
                      placeholder={`Enter quantity`}
                      style={{ padding: '8px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '5px', width: '100%' }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <p>No product scanned yet.</p>
          )}
          <Button variant="contained" onClick={handleSubmitChallan} sx={{ backgroundColor: 'rgb(255, 160, 0)', borderColor: 'rgb(255, 160, 0)', fontSize: '15px', fontWeight: '500', color: '#FFFFFF', padding: '10px 20px' }}>
            Make Challan
          </Button>
        </div>
      )}
    </Box>
  );

};

export default Challan;
          