import React, { useState, useEffect } from "react";

const Iframe = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  const src = source;     
  return (
      <>
        <iframe src={src} referrerPolicy="origin" style={{height:"100%", width:"100%"}}></iframe>
      </>
  );
};

export default Iframe; 