import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NotificationManager } from 'react-notifications';

const OrderHistory = () => {
  const { id } = useParams(); 
  const [order, setOrder] = useState({});

  useEffect(() => {
    axios
      .get(`/admin/order/${id}`)
      .then((res) => {
        const order = res.data.results.order;
        setOrder(order);
        NotificationManager.success("Order data fetched successfully!");
      })
      .catch((error) => {
        // console.error("There was an error fetching the order data!", error);
        NotificationManager.error("Error fetching order data");
      });
  }, [id]);

  return (
    <div className="content">
      <Row>
        <Col md={12}>
          <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
            title="Order History"
            category="Details of the order status"
            content={
              <Table responsive hover bordered>
                <thead>
                  <tr>
                    <th><strong style={{ color: 'black' }}>Serial Number</strong></th>
                    <th><strong style={{ color: 'black' }}>Status Type</strong></th>
                    <th><strong style={{ color: 'black' }}>State</strong></th>
                    <th><strong style={{ color: 'black' }}>Remark</strong></th>
                    <th><strong style={{ color: 'black' }}>Date</strong></th>
                    <th><strong style={{ color: 'black' }}>Related Content</strong></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Payment Status Row */}
                  <tr>
                    <td align="center">1</td>
                    <td>Payment Status</td>
                    <td>{order.pStatus}</td>
                    <td>
                      {order.pStatus === "PD"
                        ? "Paid"
                        : order.pStatus === "PR"
                        ? "Partial Refund"
                        : order.pStatus === "PP"
                        ? "Partial Payment"
                        : ""}
                    </td>
                    <td>{new Date(order.paidDate).toUTCString()}</td>  
                    <td>
                      {order.oStatus === "OB" ? "Default" : order.oStatus === "OC" ? "By user intervention" : ""}
                    </td>
                  </tr>

                  {/* Order Status Row */}
                  <tr>
                    <td align="center">2</td>
                    <td>Order Status</td>
                    <td>{order.oStatus || ""}</td>
                    <td>
                      {order.oStatus === "OB" ? "Default" : order.oStatus === "OC" ? "By user intervention" : ""}
                    </td>
                    <td>{new Date(order.orderDate).toUTCString() || ""}</td>
                    <td>{order.related || ""}</td>
                  </tr>
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrderHistory;
