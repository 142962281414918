import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  backgroundColor: "#f5f5f5",
});

const GateEntryList = () => {
  const [entrys, setEntrys] = useState([]);
  const [courierNames, setCourierNames] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState("");
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const printRef = useRef(null);

  useEffect(() => {
    axios
      .get("/wms/get_GateEntrys")
      .then((response) => {
        const allEntrys = response.data.GateEntrys;
  
        const sortedEntrys = allEntrys.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  
        setEntrys(sortedEntrys);
  
        const uniqueCouriers = [...new Set(sortedEntrys.map((entry) => entry.courier))];
        const uniqueVehicleTypes = [...new Set(sortedEntrys.map((entry) => entry.vehical))];
  
        setCourierNames(uniqueCouriers);
        setVehicleTypes(uniqueVehicleTypes);
      })
      .catch((error) => {
        console.error("Error fetching entries:", error);
        NotificationManager.error("Error fetching entries");
      });
  }, []);
  

  const filteredEntrys = entrys.filter((entry) => {
    const entryDate = new Date(entry.createdAt).toISOString().split('T')[0];
    return (
      (selectedCourier === "" || entry.courier === selectedCourier) &&
      (selectedVehicleType === "" || entry.vehical === selectedVehicleType) &&
      (selectedDate === "" || entryDate === selectedDate)
    );
  });

  const displayedEntrys = filteredEntrys.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handlePageClick = (data) => {
    setPage(data.selected);
  };

  const handlePrint = (entry) => {
    const printWindow = window.open('', '_blank', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Gate Entry</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            h1 { text-align: center; margin-bottom: 20px; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f5f5f5; font-weight: bold; }
          </style>
        </head>
        <body>
          <h1>One Stop Fashions Pvt. Ltd</h1>
          <h2>Gate Entry Details</h2>
          <table>
            <tr><th>Courier</th><td>${entry.courier}</td></tr>
            <tr><th>Boxes</th><td>${entry.box}</td></tr>
            <tr><th>Vehicle Name</th><td>${entry.vehicleName}</td></tr>
            <tr><th>Vehicle Type</th><td>${entry.vehical}</td></tr>
            <tr><th>Date</th><td>${moment.utc(new Date(entry.createdAt)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss')}</td></tr>
          </table>
          <script>
            window.print();
            window.onafterprint = () => { window.close(); }
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <div style={{ padding: "20px", width: "100%", margin: "0 auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h4>GateEntry List</h4>
        <Link to="/wms/AddGateEntry">
          <Button
            variant="contained"
            color="warning"
            style={{
              fontWeight: "500",
              height: "40px",
              borderRadius: 4,
              fontSize: "15px",
            }}
          >
            Add Gate Entry
          </Button>
        </Link>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl fullWidth>
            <select
              className="form-control"
              value={selectedCourier}
              onChange={(e) => setSelectedCourier(e.target.value)}
              style={{ height: "40px" }}
            >
              <option value="">All Couriers</option>
              {courierNames.map((courier, index) => (
                <option key={index} value={courier}>
                  {courier}
                </option>
              ))}
            </select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <FormControl fullWidth>
            <select
              className="form-control"
              value={selectedVehicleType}
              onChange={(e) => setSelectedVehicleType(e.target.value)}
              style={{ height: "40px" }}
            >
              <option value="">All Vehicle Types</option>
              {vehicleTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            fullWidth
            style={{ height: "40px" }}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: "20px", overflowY: "auto" }}>
        <Table stickyHeader aria-label="GateEntry table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: "16px" }}>Courier</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Boxes</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Vehical</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Vehical Type</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Date</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedEntrys.map((GateEntry) => (
              <TableRow key={GateEntry._id}>
                <TableCell style={{ fontSize: "16px" }}>{GateEntry.courier}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{GateEntry.box}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{GateEntry.vehicleName}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{GateEntry.vehical}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>
                  {moment.utc(new Date(GateEntry.createdAt)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss')}
                </TableCell>
                <TableCell>
                  <Button
                  color="warning"
                  style={{
                    fontWeight: "500",
                    height: "40px",
                    borderRadius: 4,
                    fontSize: "15px",
                  }}
                    variant="contained"
                    onClick={() => handlePrint(GateEntry)}
                  >
                    Print
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: "flex", marginTop: "20px" }}>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredEntrys.length / rowsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
          previousLinkStyle={{
            margin: "0 5px",
            cursor: "pointer",
            textDecoration: "none",
            color: "black",
          }}
          nextLinkStyle={{
            margin: "0 5px",
            cursor: "pointer",
            textDecoration: "none",
            color: "black",
          }}
        />
      </div>
    </div>
  );
};

export default GateEntryList;
