import React, { useState, useEffect } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import '../../assets/css/settings.css'
import { NotificationManager } from 'react-notifications'
import Card from '../../components/Card/Card.jsx'
import CustomButton from '../../components/CustomButton/CustomButton'

const AdminProfile = () => {
	const [name, setName] = useState('')
	const [priorityChina, setPriorityChina] = useState('0')
	const [priorityIndia, setPriorityIndia] = useState('0')
	const [priorityBulk, setPriorityBulk] = useState('0')
	const [id, setId] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState()
	const [password, setPassword] = useState('')

	const token = localStorage.getItem('token')
	const decode = jwt_decode(token)
	useEffect(() => {
		getOrderPriority()
		setId(decode.id)
	}, [])

	const getOrderPriority = () => {
		// console.log('decode', decode.id)
		axios.get('/admin/getPriorityList').then((res) => {
			if(res.data.status == 1){
				setPriorityChina(res.data.data[0].china)
				setPriorityIndia(res.data.data[0].india)
				setPriorityBulk(res.data.data[0].bulk)
			}
			console.log(res.data.data[0].china)
			// setEmail(res.data.items.email)
			// setName(res.data.items.name)
			// setUsername(res.data.items.username)
			// setPhone(res.data.items.phoneNo)
		})
	}                

	const updateSettings = async (e) => {
		e.preventDefault()
		try {
			let res = await axios.post('/admin/orderPriority', {
				priorityBulk,
				priorityIndia,
				priorityChina,
				merchantId:decode.id
			}).then((result) => {
				// console.log(result)
				if(result.data.status == 1){
					NotificationManager.success('Settings Updated Successfully.')
				}else{
					NotificationManager.error('Something Went Wrong.')
				}
			})
			
		} catch (error) {
			NotificationManager.error('something unusual happened')
		}
	}

	return (
		<div className='content'>
			<Grid fluid>
				<Row>
					<Col md={12}>
						
						<div class="card " id="cardid">
							<div class="header">
								<h4 class="title" style={{"marginLeft": "10px"}}>Priority for Order</h4>
								<p class="category" style={{"marginLeft": "10px"}}></p>
							</div>
							<div class="content new-content">
								<div class="row">
									<form onSubmit={updateSettings} style={{"padding": "0px 15px", "marginBottom": "0px"}}>
										<div class="col-md-7">
											<div class="form-group">
												<label for="inputEmail3" class="col-sm-3 col-form-label">China</label>
												<div class="col-sm-5">
													<input type="text" class="form-control" id="priority_ch" name="priority_ch" placeholder="0" value={priorityChina || ''} onChange={(e) => setPriorityChina(e.target.value)} />
												</div>
											</div>
										</div>
										<div class="col-md-7">
											<div class="form-group">
												<label for="inputEmail3" class="col-sm-3 col-form-label">India</label>
												<div class="col-sm-5">
													<input type="text" class="form-control" id="priority_in" name="priority_in" placeholder="0" value={priorityIndia || ''} onChange={(e) => setPriorityIndia(e.target.value)} />
												</div>
											</div>
										</div>
										<div class="col-md-7">
											<div class="form-group">
												<label for="inputEmail3" class="col-sm-3 col-form-label">Bulk</label>
												<div class="col-sm-5">
													<input type="text" class="form-control" id="priority_bk" name="priority_bk" placeholder="0" value={priorityBulk || ''} onChange={(e) => setPriorityBulk(e.target.value)} />
												</div>
											</div>
										</div>
										<div class="col-md-7">
											<div class="footer">
												<div class="stats">
													<div className='col-md-3' style = {{ padding: '0px' }}>
														<CustomButton fill type='submit' name='button'>
															Update
														</CustomButton>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
								
							</div>
						</div>
						
					</Col>
				</Row>
			</Grid>
		</div>
	)
}

export default AdminProfile
