import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';

const MerchantLogout = ({ location }) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && jwt_decode(token).category == "merchant") {
        localStorage.removeItem('token')
    } 
    const redirectUrl = location.search.split("=")[1];
    window.location.href = redirectUrl;
  }, []);

  return (
    <div className='wrapper' id='wrapper-login'>
        Please wait...
    </div>
  );
};
export default MerchantLogout;
