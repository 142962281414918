import React, { useState, useEffect } from 'react';
import { Grid, Row, Col, Button, ButtonGroup, Table } from 'react-bootstrap';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { getPriceFormatter } from "../../helpers/index.js";
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';  
import Card from '../../components/Card/Card.jsx'
import '../../assets/css/viewProduct.css'; // Custom CSS for styling

const ViewProduct = (props) => {
    const [product, setProduct] = useState(null); // New state for product details
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('inventoryInfo');
    const [productDescription, setProductDescription] = useState('');

    useEffect(() => {
        fetchProductData();
    }, []);

    const fetchProductData = async () => {
        try {
            const result = await axios.get(`/admin/getItem/${localStorage.getItem("code")}`);
            if (result.data === 1) {
                setLoading(false);
                NotificationManager.error("Product not found");
            } else {
                let productData = result.data.product[0];
                setProduct(productData);
                setProductDescription(productData.description);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            NotificationManager.error("Failed to fetch product data");
            console.error("Error fetching product data:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }  
    const renderInventoryInfo = () => (
        <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
            title="Inventory Info"
             category="Info"
            content={
                <Grid fluid>
                    <Row>
                        <Col md={4}>
                            <div className="shadow-box">
                                <label style={{ color: '#2f4f4f', fontSize: '16px', fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif' }}>Shopperr</label>
                                <p>{product.stock.cn_qty}</p>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="shadow-box">
                                <label style={{ color: '#2f4f4f', fontSize: '16px', fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif' }}>
                                    iStore ({product.supplierName ? product.supplierName : 'N/A'})
                                </label>
                                <div className="stock-columns">
                                    <div className="column">China Stock : {product.stock.cn_qty}</div>
                                    <div className="column">Bulk Stock :{product.stock.blk_qty}</div>
                                    <div className="column">India Stock :{product.stock.in_qty}</div>
                                    <div className="column">China1 Stock :{product.stock.china1}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            }
        />
    );

    const renderInventoryLog = () => (
        <Card
            title="Inventory Log"
            content={
                <Grid fluid>
                    <Row>
                        <Col md={2}>
                            <div className="shadow-box">
                                <h5>Product Status</h5>
                                {/* Additional content can go here */}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="shadow-box">
                                <h5>Before BFL change</h5>
                                {/* Additional content can go here */}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="shadow-box">
                                <h5>After BFL changes</h5>
                                {/* Additional content can go here */}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="shadow-box">
                                <h5>China Warehouse</h5>
                                {/* Additional content can go here */}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="shadow-box">
                                <h5>India Warehouse</h5>
                                {/* Additional content can go here */}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="shadow-box">
                                <h5>Update time</h5>
                                {getPriceFormatter(product.price)}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            }
        />
    );

    return (
        <div className="product-details">
            {product ? (
                <>
                    <Grid fluid>
                        <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
                            title={product.name}
                            category="Product-Images"
                            content={
                                <Table responsive hover bordered>
                                    <div className="product-images-box shadow-box">
                                        {/* <label style={{ color: '#2f4f4f', fontSize: '16px', fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif' }}>Product Images</label> */}
                                        <div className="product-images">
                                            {product.imgUrl && product.imgUrl.map((img, index) => (
                                                <img
                                                    key={index}
                                                    src={img.src}
                                                    alt={`Product Image ${index + 1}`}
                                                    className="product-image"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Table>
                            }
                        />
                    </Grid>

                    <Grid fluid>

                        <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
                            title="Product Basic Information"
                            category="Information:"
                            content={
                                <Table responsive hover bordered>
                                    <thead>
                                        <th>Product Categories:</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong style={{ color: 'black' }}>supplier</strong></td>
                                            <td>{product.supplierName || 'NA'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong style={{ color: 'black' }}>Product Code</strong></td>
                                            <td>{product.blk_qty || 'NA'}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td><strong style={{ color: 'black' }}>Product Model:</strong></td>
                                            <td> {product.blk_qty || 'NA'}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td><strong style={{ color: 'black' }}>Specification:</strong></td>
                                            <td>{product.blk_qty || 'NA'}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            }
                        />

                        <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
                            title="Product Packaging Information"
                            category="Information:"
                            content={
                                <Table responsive hover bordered>
                                    <thead>
                                        <th>Dimensions:</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong style={{ color: 'black' }}>Length*Width*Height :</strong></td>
                                            <td>{product.length}mm*{product.width}mm*{product.height}mm</td>
                                        </tr>
                                        <tr>
                                            <td><strong style={{ color: 'black' }}>weight</strong></td>
                                            <td>{product.weight || 'NA'}gm</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            }
                        />
                        <Row>
                            <Col md={12}>
                                <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
                                    title="Product Price Information"
                                    category="Specification:"
                                    content={
                                        <Table responsive hover bordered>
                                            <thead>
                                                <th>Price List:</th>
                                                <tr>
                                                    <th><strong style={{ color: 'black' }}>Price</strong></th>
                                                    <th><strong style={{ color: 'black' }}>Lead time (days) : </strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Payment Status Row */}
                                                <tr>
                                                    <td align="center">{getPriceFormatter(product.sp)}</td>
                                                    <td>7</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }
                                />
                            </Col>
                        </Row>
                        <br /><br />
                        <Grid fluid >
                            <ButtonGroup>
                                <Button
                                    className="button-info"
                                    style={{ color: 'rgb(255, 255, 255)', fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: 'rgb(255,160,0)', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', borderColor: 'rgb(255, 160, 0)' }}
                                    onClick={() => setActiveTab('inventoryInfo')}
                                    active={activeTab === 'inventoryInfo'}
                                >
                                    Inventory Info
                                </Button>
                                {/* <Button
                                    style={{ color: 'rgb(255, 255, 255)', fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: 'rgb(255,160,0)', alignItems: 'center', justifyContent: 'center', borderRadius: '4px',borderColor:'rgb(255, 160, 0)' }}
                                    onClick={() => setActiveTab('inventoryLog')}
                                    active={activeTab === 'inventoryLog'}
                                >
                                    Inventory Log
                                </Button> */}
                            </ButtonGroup>
                        </Grid>

                        <Grid fluid>
                            {activeTab === 'inventoryInfo' && renderInventoryInfo()}
                            {activeTab === 'inventoryLog' && renderInventoryLog()}
                        </Grid>
                        <br /><br />
                        <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
                            title="Description"
                            content={
                                <ReactQuill
                                theme={"snow"}
                                value={productDescription}
                                modules={{
                                    toolbar: false  
                                }}
                                placeholder={"Write something..."}
                            />
                            
                            }
                        />
                    </Grid>
                </>
            ) : (
                <div>Product not found</div>
            )}
        </div>
    );
};

export default ViewProduct;
