import React, { useState, useMemo } from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import "../../assets/css/supplier.css";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import countryList from "react-select-country-list";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
function AddVendor() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [gst, setGst] = useState("");
  const [bankName, setBankName] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("");
  // const [typeCheck, setTypeCheck] = useState(true);
  const [type, setType] = useState("text");
  const [origin, setOrigin] = useState("");
  const [errors, setErrors] = useState({});

  const updateName = (e) => setName(e.target.value);
  const updateEmail = (e) => setEmail(e.target.value);
  const updateAddress = (e) => setAddress(e.target.value);
  const updateCity = (e) => setCity(e.target.value);
  const updateState = (e) => setState(e.target.value);
  const updatePostalCode = (e) => setPostalCode(e.target.value);
  const updateGst = (e) => setGst(e.target.value);
  const updateBankName = (e) => setBankName(e.target.value);
  const updateBeneficiaryName = (e) => setBeneficiaryName(e.target.value);
  const updateAccountNumber = (e) => setAccountNumber(e.target.value);

  // const handleClick = () => {
  //     setTypeCheck(!typeCheck);
  //     if (typeCheck == true) {
  //         setType('text');
  //     } else {
  //         setType('password');
  //     }
  // };

  const options = useMemo(() => countryList().getData(), []);
  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!name) {
      isValid = false;
      errors["fullName"] = "Please enter your name.";
    }
    if (!origin) {
      isValid = false;
      errors["origin"] = "Please select origin.";
    }
    if (!email) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }
    if (!Address) {
      isValid = false;
      errors["Address"] = "Please enter your company address.";
    }
    if (!phoneNumber) {
      isValid = false;
      errors["phoneNumber"] = "Please Enter Phone Number.";
    }
    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!city) {
      isValid = false;
      errors["city"] = "Please enter your city.";
    }

    if (!state) {
      isValid = false;
      errors["state"] = "Please enter your state/province.";
    }

    if (!postalCode) {
      isValid = false;
      errors["postalCode"] = "Please enter postal/zip code.";
    }

    if (!gst) {
      isValid = false;
      errors["gst"] = "Please enter your GST number.";
    }

    setErrors(errors);
    return isValid;
};


  const addVendor = (e) => {
    e.preventDefault();
    if (validate()) {
      const obj = {
        name,
        email,
        address: Address,
        city,
        state,
        postalCode,
        gst,
        phoneNumber,
        origin: origin.value,
      };

      console.log("Request Payload:", obj);

      axios
        .post("/wms/AddVendor", obj)
        .then((response) => {
          NotificationManager.success("New Supplier Created Successfully");
          setName("");
          setEmail("");
          setAddress("");
          setCity("");
          setState("");
          setPostalCode("");
          setGst("");
          setBankName("");
          setBeneficiaryName("");
          setAccountNumber("");
          setPhoneNumber("");
          setOrigin("");
        })
        .catch((error) => {
          console.error("Request Failed:", error.response); // Log detailed error information
          NotificationManager.error(
            "Vendor creation failed: " + error.response.data.message
          );
        });
    }
  };
  return (
    <div className="container-fluid">
      <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
      <div
        className="form-group col-md-6 mx-auto"
        style={{ padding: "1em", paddingBottom: "0px", marginTop: "10px" }}
      >
        <Card sx={{ maxWidth: 1000 }}>
          <CardActionArea id="cardContentClass">
            {/* <CardMedia
          component="img"
          height="140"
          image="https://i.ibb.co/q1ZcWFy/luke-chesser-p-Jad-Qetz-Tk-I-unsplash.jpg"
          alt="green iguana"
        /> */}
            <CardContent>
              <form onSubmit={addVendor}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="fullName"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Vendor Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullName"
                    value={name}
                    onChange={updateName}
                    placeholder="Please Enter Vendor Name"
                    style={{ border: "1px solid #1D8BF7" }}
                  />
                  <div className="text-danger">{errors.fullName}</div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="email"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Email
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={updateEmail}
                    placeholder="Enter Email"
                  />
                  <div className="text-danger">{errors.email}</div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="Address"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Company Address
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="Address"
                    className="form-control"
                    name="Address"
                    value={Address}
                    onChange={updateAddress}
                    placeholder="Please Enter Company Address"
                  />
                  <div className="text-danger">{errors.Address}</div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="email"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Origin
                  </label>
                  <Select
                    options={options}
                    value={origin}
                    onChange={(e) => {
                      setOrigin(e);
                    }}
                    style={{ color: "black" }}
                  />
                  <div className="text-danger">{errors.origin}</div>
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    City
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="text"
                    className="form-control"
                    value={city}
                    onChange={updateCity}
                    placeholder="Enter City"
                  />
                  <div className="text-danger">{errors.city}</div>
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    State / Province
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="text"
                    className="form-control"
                    value={state}
                    onChange={updateState}
                    placeholder="Enter State / Province"
                  />
                  <div className="text-danger">{errors.state}</div>
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    Postal / Zip code
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="text"
                    className="form-control"
                    value={postalCode}
                    onChange={updatePostalCode}
                    placeholder="Enter Zip"
                  />
                  <div className="text-danger">{errors.postalCode}</div>
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    GST
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="text"
                    className="form-control"
                    value={gst}
                    onChange={updateGst}
                    placeholder="Enter GST"
                  />
                  <div className="text-danger">{errors.gst}</div>
                </div>
                <div className="form-group col-sm-8 col-md-8 col-lg-8">
                  <label
                    htmlFor="phone"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Phone number
                  </label>
                  <ReactPhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={(phone) => setPhoneNumber(phone)}
                    inputStyle={{ width: "100%", border: "1px solid #1D8BF7" }}
                    // containerStyle={{ color: "black", fontWeight: "500px", position: "relative" }}
                    // buttonStyle={{ border: "none", background: "none", paddingRight: "0px" }} // Remove button border/background
                    placeholder="Please Enter Phone Number"
                    containerClass="custom-phone-input"
                  />
                  {/* 
                  <script>
                    document.querySelector('.arrow').style.display = 'none';
                  </script> */}

                  <div className="text-danger">{errors.phoneNumber}</div>
                </div>
                <div className="form-group col-lg-12">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    Bank Details :
                  </label>{" "}
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    Bank Name
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="text"
                    className="form-control"
                    value={bankName}
                    onChange={updateBankName}
                    placeholder="Enter Bank Name"
                  />
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    Beneficiary Name
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="text"
                    className="form-control"
                    value={beneficiaryName}
                    onChange={updateBeneficiaryName}
                    placeholder="Enter Beneficiary Name"
                  />
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4">
                  <label style={{ color: "black", fontWeight: "500px" }}>
                    Account Number
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="text"
                    className="form-control"
                    value={accountNumber}
                    onChange={updateAccountNumber}
                    placeholder="Enter Account Number"
                  />
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label>
                    {" "}
                    <Button
                      type="submit"
                      className="btn btn-primary btn-fill"
                      style={{
                        borderColor: "rgb(255, 160, 0)",
                        fontWeight: "500",
                        color: "#FFFFFF",
                        width: "138px",
                        backgroundColor: "rgb(255, 160, 0)",
                        fontSize: "15px",
                      }}
                    >
                      Create
                    </Button>
                  </label>
                </div>
              </form>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
      <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
    </div>
  );
}

export default AddVendor;
