import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Link, Redirect } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'
import jwt_decode from 'jwt-decode';
import PaymentConfirm from "../views/PaymentConfirm";


function ShowOrder(props) {
    
    useEffect(()=> {
        
    },[])
    
    return (
        <div>
            <div className="confirmed clearfix" style={{"width": "50%", "margin": "50px auto 20px", "paddingBottom": "120px", "paddingTop": "50px", "background": "#fff", "border": "1px solid #e7eef0", "borderRadius": "4px", "boxShadow": "0px 1px 2px #e7eef0", "position": "relative"}}>
                <span className="svg-payment"></span>
                <b style={{"display": "block", "marginTop": "20px", "fontSize": "20px", "fontWeight": "600", "textAlign": "center", "lineHeight": "20px"}}>Your order is confirmed.</b>
                <p style={{"display": "block", "width": "37%", "margin": "20px auto", "fontSize": "14px", "color": "#333", "textAlign": "center"}}>We are processing the order. You will receive a confirmation email with your order shortly. You can check all updates in <Link to={"orders"}>Orders</Link> page.</p>
            </div>
        </div>
    )
}

export default ShowOrder