import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

import Modal from "react-responsive-modal";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from "react-bootstrap";
import "../../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OffShelf() {
  const [showUpload, setShowUpload] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [errormessage, setErrorMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(25);
//   const [selectStore, setSelectStore] = useState("Select Store Name");
  const [storeData, setStoreData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [tempCustomerData, setTempCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [error, setErrors] = useState({});
//   const [defaultStore, setDefaultStore] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [open, setOpen] = useState(false);
  const [moreDesc, setMoreDesc] = useState(false);
  const [msg, setMsg] = useState("");
  const [singleProduct, setSingleProduct] = useState([]);
  const [fulfillstatus, setfulfillstatus] = useState();
  const [percentValue, setpercentValue] = useState();
  const [percentDate, setpercentDate] = useState("");
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  
  
  useEffect(() => {
    // let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    
    setLoading(false);
    // setDefaultStore(domain);
    // setSelectStore(domain);
    getOrders(currentPage, showPerPage, {});
  }, []);
  
  const getOrders = (
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/orderPickup?page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          setOrders({ data: { currentPage: res.data } });
          setPageData(res.data.result);
          setTotalData(res.data.count);

          // console.log("res.data.count"+res.data);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    getOrders(e.selected + 1, showPerPage, {});
  };

  const handleSearchInvoice = (e) => {
    setSearchString(e.target.value);
  };

  function scanInvoice(){
    console.log(searchString);
  
    getOrders(1, showPerPage, {
      text: searchString,
      });
    }

  return (
    <div className="content">
      
      {/* <div className="uploadBtn">
        {showUpload ? (
          <button
            style={{
              background: "rgb(255, 160, 0)",
              borderColor: "rgb(255, 160, 0)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="btn btn-primary btn-fill"
            onClick={handleNavigation}
          >
            INWARD UPLOAD
          </button>
        ) : (
          <button
            style={{
              background: "rgb(255, 160, 0)",
              borderColor: "rgb(255, 160, 0)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="btn btn-primary btn-fill"
            onClick={handleNavigation}
          >
            BACK TO INWARDS
          </button>
        )}
      </div> */}

      {/* {showUpload ? ( */}
        <div className="content ">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label>Search Off-Shelf</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Off-Shelf"
                      onChange={handleSearchInvoice}
                      id="searchOrder"
                      autoComplete="off"
                      onKeyUp={() => scanInvoice()}
                    />
                  </div>
                </div>
                
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => scanInvoice()}
                  >
                    SEARCH
                  </button>
                </div>
                
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getOrders(currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Order Pickup:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Off-Shelf Number</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Order Quantity</th>
                    <th scope="col">Product Quantity</th>
                    <th scope="col">Created Time</th>
                    <th scope="col">Action</th>
                    <th scope="col">Warehouse Id</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    
                    pageData.map((inw) => {
                      let d = new Date(inw.op_create_date);
                      // let lp = new Date(inw.op_last_update);
                      // let st = new Date(inw.op_start_time);
                      // let et = new Date(inw.op_end_time);
                      return (
                        <tr key={inw._id}>
                          <td style={{ fontSize: "14px"}}>
                            {inw.op_code}
                          </td>
                          <td style={{ fontSize: "14px"}}>{inw.op_orders_type}</td>
                          <td style={{ fontSize: "14px"}}>{inw.op_status}</td>
                          <td style={{ fontSize: "14px"}}>{inw.op_order_cnt}</td>
                          <td style={{ fontSize: "14px"}}>{inw.op_product_cnt}</td>
                          <td style={{ fontSize: "14px"}}>{d.toUTCString()}</td>
						              <td style={{ fontSize: "14px"}}><a href={`/print/print-offshelf/opcode/${inw.op_code}`} target="_blank">Print Off-Shelf List(A4)</a>
                          </td> 
                          <td style={{ fontSize: "14px"}}>{inw.warehouse_name}</td> 
                          
                        </tr>
                      );
                    
                  })
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              /> */}
              <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  forcePage={currentPage - 1}
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={10}
                  onPageChange={(e) => handlePageClick(e)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
            </div>
          </div>
        </div>
      {/*) : (
        <h1>Hello</h1>
      )} */}
    </div>
  );
}
export default OffShelf;
