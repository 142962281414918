import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../components/Card/Card.jsx";
import jwt_decode from "jwt-decode";
import "../assets/css/productPage.css";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import isEmpty from "is-empty";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;`;

const Products = () => {
  const [productList, setProductList] = useState([]);
  const [productImageUrl, setProductImageUrl] = useState([]);
  const [startIndex, setStartIndex] = useState("");
  const [title, setSearch] = useState("");
  const [code, setSearchSku] = useState("");
  const [status, setStatus] = useState("");

  const [showPerPage, setShowPerPage] = useState(50);
  
  const [pageCount, setPageCout] = useState(0);
  const [productTotal, setProductTotal] = useState("");
  const [domain, setDomain] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [fileLink, setFileLink] = useState("");
  let history = useHistory();
  const token = localStorage.getItem("token");
  let decode = jwt_decode(token);
  useEffect(() => {
    getProductList(currentPage, showPerPage);
  }, []);

  const onPaginationChange = (e) => {
    setCurrentPage(e.selected + 1);
    getProductList(e.selected + 1, showPerPage, {
      title: "",
      code: "",
      domain,
    });
  };

  const getProductList = (
    currentPage,
    showPerPage,
    params = { title, code, domain }
  ) => {
    setLoading(true);
    axios
      .get(
        "/shopperrds/subscribedProduct?page=" +
          currentPage +
          "&limit=" +
          showPerPage +
          "&id=" +
          decode.id +
          "&domain=" +
          params.domain +
          "&title=" +
          params.title +
          "&code=" +
          params.code
      )
      .then((products) => {
        setLoading(false);
      if (products.data.item!='') {
          let all = products.data.item.results;
          
          all = all.sort(
            (a, b) => new Date(b.uploaded_on) - new Date(a.uploaded_on)
          );
          all.forEach((e, i) => {
            if (!e.selliingPrice) {
              return;
            }
            if (e.varientArray.length > 0) {
              //
              all[i].lowRange = getSellingRange(e.varientArray);
              all[i].highRange = getHighRange(e.varientArray);
            } else {
              all[i].lowRange = e.selliingPrice.toString();
              all[i].highRange = e.selliingPrice.toString();
            }
          });
          setProductList(all);
          setProductImageUrl(products.data.url);
          setProductTotal(products.data.item.count);
          setStartIndex(products.data.item.startIndex);
          setPageCout(Math.ceil(products.data.item.count / showPerPage));
          setLoading(false);
        } else {
          
          // alert("No product found.");
        }
      });
  };

  const handleShowPerPage = (limit) => {
    getProductList(1, limit);
  };
  const handleNavigation = () => {
    history.push("/merchant/uploadSubscribeProducts");
  };

  const handleOnUnSubcribe = (data = "") => {
    let checkedPids = [];
    if (data == "") {
      [...document.getElementsByClassName("select_products")].forEach((el) => {
        //
        if (el.checked) {
          checkedPids.push(el.value);
        }
        // el.checked = e.target.checked;
        //
      });
    } else {
      checkedPids = [data["id"]];
    }
    let mId = decode.id;

    UnSubscribeProduct(mId, checkedPids, 1);
  };

  const listOnSopify = (data = "") => {
    let checkedPids = [];
    if (data == "") {
      [...document.getElementsByClassName("select_products")].forEach((el) => {
        //
        if (el.checked) {
          checkedPids.push(el.value);
        }
        // el.checked = e.target.checked;
        //
      });
    } else {
      checkedPids = [data["id"]];
    }
    let mId = decode.id;

    SenToShopify(mId, checkedPids, 1);
  };

  const onSelect = (e) => {
    [...document.getElementsByClassName("select_products")].forEach((el) => {
      el.checked = e.target.checked;
    });
  };
  const filterItems = productList.filter((plist) => {
    return plist.name.toLowerCase().includes(title.toLowerCase());
  });
  const headers = [
    { label: "SKU", key: "skus" },
    { label: "Invoice Desc", key: "invoicedesc" },
    { label: "Image1", key: "imgUrl[0].src" },
    { label: "Image2", key: "imgUrl[1].src" },
    { label: "Image3", key: "imgUrl[2].src" },
    { label: "Image4", key: "imgUrl[3].src" },
    { label: "Image5", key: "imgUrl[4].src" },
    { label: "Image6", key: "imgUrl[5].src" },
    { label: "Image7", key: "imgUrl[6].src" },
    { label: "Image8", key: "imgUrl[7].src" },
    { label: "Image9", key: "imgUrl[8].src" },
    { label: "Image10", key: "imgUrl[9].src" },
    { label: "Price", key: "price" },

    { label: "Channel", key: "channel" },
    { label: "Name", key: "name" },
    // { label: 'Description', key: 'description' },
  ];

  const getSellingRange = (arr) => {
    return arr.reduce(function (prev, curr) {
      return parseFloat(prev.selliingPrice) < parseFloat(curr.selliingPrice)
        ? prev
        : curr;
    }).selliingPrice;
  };

  const getHighRange = (arr) => {
    return arr.reduce(function (prev, curr) {
      return parseFloat(prev.selliingPrice) > parseFloat(curr.selliingPrice)
        ? prev
        : curr;
    }).selliingPrice;
  };

  const handlClick = (e) => {
    e.preventDefault();
    setDomain(e.target.value);
    getProductList(1, showPerPage, { domain: e.target.value });
  };

  function showDateRangeData() {
    getProductList(1, showPerPage, { domain, title, code });
  }

  function downloadOrder() {
    setLoading(true);
    axios
      .get(
        "/merchant/downloadSubscribeProductCsv?page=" +
          currentPage +
          "&limit=" +
          showPerPage +
          "&id=" +
          decode.id +
          "&domain=" +
          domain +
          "&title=" +
          title +
          "&code=" +
          code
      )
      .then((data) => {
        let result = data.data;
        setLoading(false);
        if (result.error == 0) {
          window.open(result.response);
          setFileLink(result.response);
        } else if (result.error == 1) {
          NotificationManager.error(result.message);
        }
      });

    let errors = {};
    // if(!isEmpty(fromDate) && !isEmpty(toDate)){
    //     // let d = new Date(fromDate)
    //     // if(d.getFullYear() > 9999){
    //     //     errors.fromDate = "From Date must be less than 9999"
    //     // }else if(d.getFullYear() < 1950){
    //     //     errors.fromDate = "From Date must be greater than 1950"
    //     // }
    //     // let d1 = new Date(toDate)
    //     // if(d1.getFullYear() > 9999){
    //     //     errors.toDate = "To Date must be less than 9999"
    //     // }else if(d1.getFullYear() < 1950){
    //     //     errors.toDate = "To Date must be greater than 1950"
    //     // }
    //     // if(isEmpty(errors)){
    //     //     const date1 = new Date(fromDate);
    //     //     const date2 = new Date(toDate);
    //     //     const diffTime = date2 - date1
    //     //     const diffDays = (diffTime / (1000 * 60 * 60 * 24))
    //     //     if(diffDays < 0){
    //     //         errors.toDate = "End date needs to be after From date";
    //     //     }else {
    //     //         //getDownloadOrders(selectStore,1,showPerPage,{fromDate,toDate,text:searchString})
    //     //     }
    //     // }
    //     setErrors(errors)
    // } else if(searchString){
    //     //getDownloadOrders(selectStore,1,showPerPage,{text:searchString})
    // }else{
    //     //getDownloadOrders(selectStore,currentPage,showPerPage,{})
    // }
  }

  const UnSubscribeProduct = (mid, pids, issubscribe) => {
    let unsubscribePayLoad = {
      merchantId: mid,
      ids: pids,
      unsubscribe: 1,
      margin: 75,
      is_draft: 1,
      channel: "ds",
    };

    axios.post("/shopperrds/subscribeProduct", unsubscribePayLoad).then((result) => {
      if (result.data.error == 1) {
        NotificationManager.error(result.data.message);
      } else {
        getProductList(currentPage, showPerPage);
        NotificationManager.success(result.data.message);
      }
    });
  };

  const SenToShopify = (mid, pids, issubscribe) => {
    let unsubscribePayLoad = {
      merchantId: mid,
      ids: pids,
      unsubscribe: 1,
      margin: 75,
      is_draft: 1,
      channel: "ds",
    };

    if(pids.length==0){
      NotificationManager.error('Please select products.');
      return false;
    }

    axios.post("/shopperrds/createProduct", unsubscribePayLoad).then((result) => {
      // if (result.data.error == 1) {
      //   NotificationManager.error(result.data.message);
      // } else {
      //   getProductList(currentPage, showPerPage);
      //   NotificationManager.success(result.data.message);
      // }
    });
  };

  return (
    <div>
      {/*---------------------------------------------------------------------------------------------------*/}
      {/* Bulk Subscribe Button */}

      {/*---------------------------------------------------------------------------------------------------*/}
      <div
        style={{
          backgroundColor: "#fff",
          margin: "5px 15px 0px 15px",
          display: "flex",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <input
            type="text"
            className="search_box"
            placeholder="Search By Title"
            onChange={(e) => setSearch(e.target.value)}
            id="searchTitle"
            autoComplete="off"
          />
          <input
            type="text"
            className="search_box"
            placeholder="Search By Sku"
            onChange={(e) => setSearchSku(e.target.value)}
            id="searchSku"
            autoComplete="off"
          />
          <select className="search_box" onChange={(e) => handlClick(e)}>
            <option value="all">All</option>
            <option value="amazon">Amazon</option>
            <option value="shopify">Shopify</option>
          </select>
          <div>
            {" "}
            <a
              className="button-24"
              style={{ cursor: "pointer" }}
              onClick={() => showDateRangeData()}
            >
              Get
            </a>
          </div>
        </div>

        <div>
          {" "}
          <a
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleNavigation}
          >
            GO TO BULK SUBSCRIBE
          </a>
        </div>
      </div>
      <div className="content bdycontent">
        <SyncLoader
          css={override}
          size={15}
          margin={6}
          color={"#3E3E3E"}
          loading={loading}
        />

        <div
          className="container-fluid"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div
            className="text-center"
            style={{ color: "red", backgroundColor: "#fff" }}
          >
            {status !== "" ? (
              <div>
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-circle-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>{" "}
                {status}
              </div>
            ) : null}

            {/* <div className="card-button">
            <CustomButton fill type="button" onClick={(e) => exportProducts(e)}>
              Export Products on Amazon
            </CustomButton>
          </div> */}
          </div>

          {/* Select and download layout */}
          <div className="excelDownload">
            <div>
              <a
                style={{ cursor: "pointer" }}
                className="button-24 "
                onClick={() => handleOnUnSubcribe()}
              >
                Unsubscribe Selected Products
              </a>
            </div>
            <div>
              <a
                style={{ cursor: "pointer" }}
                className="button-24 "
                onClick={() => listOnSopify()}
              >
                List on Shopify
              </a>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <select
                className="search_box"
                onChange={(e) => handleShowPerPage(e.target.value)}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>

              <CSVLink
                className="button-24 "
                data={productList}
                headers={headers}
              >
                Download
              </CSVLink>
            </div>
          </div>

          <div>
            <Grid fluid>
              <Row>
                <Col md={12} style={{ padding: "0px" }}>
                  <Card
                    title="Product List"
                    category={"Total Products :" + productTotal}
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <div>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>
                                <input
                                  type="checkbox"
                                  name="check"
                                  onClick={(e) => onSelect(e)}
                                />
                              </th>
                              <th>Image</th>
                              <th>Name</th>
                              {/* <th>Invoice Description</th> */}
                              <th>SKU</th>
                              {/* <th>Category</th> */}
                              <th>Margin</th>
                              <th>Selling Price</th>
                              <th>Unsubscribe Product</th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(filterItems)}
                            {!isEmpty(filterItems) ? (
                            filterItems.map((item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{startIndex + (key + 1)}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="select_products"
                                      name="ids[]"
                                      value={`${item._id}`}
                                    />
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {item.imgUrl[0] ? (
                                      !!item.imgUrl[0].src ? (
                                        <img
                                          className="product-logo"
                                          src={`${productImageUrl}/${item.imgUrl[0].src}`}
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                          }}
                                        />
                                      ) : (
                                        "Image not Available"
                                      )
                                    ) : (
                                      "Image not Available"
                                    )}
                                  </td>
                                  <td>{item.name}</td>
                                  {/* <td>{item.invoicedesc}</td> */}
                                  <td style={{ width: "10%" }}>
                                    {item?.vd?.length > 0
                                      ? item?.vd?.["0"]?.sku
                                      : item.code}
                                  </td>

                                  <td>{item.margin} %</td>
                                  <td style={{ width: "10%" }}>
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item.price}
                                  </td>
                                  <td>
                                    <a
                                      onClick={() => handleOnUnSubcribe(item)}
                                      className="button-24 "
                                    >
                                      Unsubscribe
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                            ) : (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  <h4>No Data Found</h4>
                                </td>
                              </tr>
                            )
                            }
                            
                          </tbody>
                        </Table>
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Grid>

            {!!productTotal ? (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={onPaginationChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Products;
