import React, { useState, useEffect } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import '../../assets/css/settings.css'
import { NotificationManager } from 'react-notifications'
import Card from '../../components/Card/Card.jsx'
import CustomButton from '../../components/CustomButton/CustomButton'

const AdminProfile = () => {
	const [name, setName] = useState('')
	const [username, setUsername] = useState('')
	const [id, setId] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState()
	const [password, setPassword] = useState('')

	const token = localStorage.getItem('token')
	const decode = jwt_decode(token)
	useEffect(() => {
		getAdminProfile()
		setId(decode.id)
	}, [])

	const getAdminProfile = () => {
		// console.log('decode', decode.id)
		axios.get('/admin/adminProfile' + decode.id).then((res) => {
			setEmail(res.data.items.email)
			setName(res.data.items.name)
			setUsername(res.data.items.username)
			setPhone(res.data.items.phoneNo)
		})
	}                

	const updateSettings = async (e) => {
		e.preventDefault()
		try {
			let res = await axios.patch('/admin/adminAccount', {
				username,
				phone,
				name,
				id,
				email,
			}).then((result) => {
				if(result.data == 'success'){
					NotificationManager.success('Settings Updated Successfully.')
				}else{
					NotificationManager.success('Something Went Wrong.')
				}
			})
			
		} catch (error) {
			NotificationManager.error('something unusual happened')
		}
	}

	return (
		<div className='content'>
			<Grid fluid>
				<Row>
					<Col md={12}>
						<Card
							title='Accounts Settings'
							ctTableFullWidth
							ctTableResponsive
							content={
								<form onSubmit={updateSettings} style={{padding: '0px 15px' , marginBottom : '0px'}}>
									<div className='card' style={{ marginBottom : '0px' }}>
										<div className='form-group'>
											<label htmlFor='product_email'>Name</label>
											<input
												type='text'
												value={name || ''}
												onChange={(e) => setName(e.target.value)}
												className='form-control'
												id='product_email'
												placeholder='Your Name'
											/>
										</div>
										<div className='form-group'>
											<label htmlFor='product_quantity'>Username</label>
											<input
												type='text'
												value={username || ''}
												onChange={(e) => setUsername(e.target.value)}
												min='0'
												className='form-control'
												id='product_id'
												placeholder='Enter Username'
											/>
										</div>
										<div className='form-group'>
											<label htmlFor='product_name'>Email</label>
											<input
												type='email'
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												min='0'
												className='form-control'
												id='product_name'
												placeholder='example@any.com'
												required
											/>
										</div>
										<div className='form-group'>
											<label htmlFor='product_location'>Phone No</label> 
											<input
												type='tel'
												value={phone || ''}
												onChange={(e) => setPhone(e.target.value)}
												min='0'
												maxLength='10'
												className='form-control'
												id='phone'
												name='phone'
												pattern='[0-9]{10}'
												placeholder='Enter Phone No.'
											/>
											<small>Enter 10-digit phone no.</small>
										</div>
										{/*  <div className="form-group">
                      <label for="product_location">Password</label>

                      <input
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        min="0"
                        className="form-control"
                        id="product_location"
                        placeholder="Enter Password"
                      />
                    </div>*/}
                    					<div className='col-md-3' style = {{ padding: '0px' }}>
											<CustomButton fill type='submit' name='button'>
												Update Profile
											</CustomButton>
										</div>
									</div>
								</form>
							}
						/>
					</Col>
				</Row>
			</Grid>
		</div>
	)
}

export default AdminProfile
