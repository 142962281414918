import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
// import jwt_decode from "jwt-decode";

// import moment from 'moment';

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [pageData, setPageData] = useState([]);
  const [errormessage, setErrorMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(20);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [orderdateFrom, setOrderdateFrom] = useState("");
  const [orderdateTo, setOrderdateTo] = useState("");
  const [error, setErrors] = useState({});
  const [tempData, setTempData] = useState([]);

  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(null);

  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    
    setLoading(false);
    // setDefaultStore(domain);
    // setSelectStore(domain);
    getOrders(currentPage, showPerPage, {});
  }, []);
  
  const getOrders = (
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/stockManagementList?spID=${userId}&page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          // setOrders({ data: { currentPage: res.data } });
          setPageData(res.data.results);
          setTotalData(res.data.count);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const getExport = (
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/stockManagementExport?spID=${userId}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.error==0) {
          setTimeout(() => {
            // console.log(res.data.message)
            handlePrint(res.data.message);
          }, 1000);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handlePrint = (iframeUrl) => {
    
    const printWindow = window.open(iframeUrl, '_blank');

    printWindow.addEventListener('load', () => {
      printWindow.print();
    });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    // getOrders(e.selected + 1, showPerPage, {});
    getOrders(e.selected + 1, showPerPage, {
      text: searchString,
      orderdateFrom:orderdateFrom,
      orderdateTo:orderdateTo,
    });
  };

  const handleSearchInvoice = (e) => {
    setSearchString(e.target.value);
  };

  const handleFromdate = (orderdateSearch) => {
    setOrderdateFrom(orderdateSearch);
    setErrors({});
  };
  const handleTodate = (orderdateSearch) => {
    setOrderdateTo(orderdateSearch);
    setErrors({});
  };

  // function orderSearch(orderStatus){
    
  //   getOrders(1, showPerPage, {
  //     text: searchString,
  //     orderdateFrom:orderdateFrom,
  //     orderdateTo:orderdateTo,
  //   });
  // }

  function scanInvoice(){
  
    getOrders(1, showPerPage, {
      text: searchString,
      orderdateFrom:orderdateFrom,
      orderdateTo:orderdateTo,
   });
  }

  function createHistory(code){
    console.log(code)

    axios
      .get(
        `/wms/tempManagementHistory?code=${code}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);

        if (!res.data.hasOwnProperty("message")) {
          setOpen(true)
          
          setTempData(res.data.results);
        } else {
          setErrorMessage(res.data.message);
        }
        
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
    
  }

  function exportInvoice(){
  
    getExport({
      text: searchString,
      orderdateFrom:orderdateFrom,
      orderdateTo:orderdateTo,
   });
  }

  return (
    <div className="content">
      
        <div className="content ">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-md-3">
                  {/* <div className="form-group"> */}
                    <label>Search</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Text"
                      onChange={handleSearchInvoice}
                      id="searchOrder"
                      autoComplete="off"
					            onKeyUp={() => scanInvoice()}
                    />
                  {/* </div> */}
                </div>

                <div className="col-md-3">
                    <label>
                      Date From &nbsp;
                      <span
                      onClick={() => {
                        document.getElementById("orderdateFrom").value = ""; // Clears the input value
                        setOrderdateFrom(""); // Clears the state
                      }}
                      style={{ cursor: "pointer", color: "#42d0ed" }}
                    >
                      (Clear)
                    </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="orderdateFrom"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Order Date"
                      value={orderdateFrom}
                      onChange={(e) => handleFromdate(e.target.value)}
                    />
                    {!isEmpty(error.orderdateFrom) && (
                      <span className="text-danger">{error.orderdateFrom}</span>
                    )}
                </div>

                <div className="col-md-3">
                    <label>
                      Date To &nbsp;
                      <span
                        onClick={() =>{
                          document.getElementById("orderdateTo").value = "";
                          setOrderdateTo("");
                        }}
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="orderdateTo"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Order Date"
                      value={orderdateTo}
                      onChange={(e) => handleTodate(e.target.value)}
                    />
                    {!isEmpty(error.orderdateTo) && (
                      <span className="text-danger">{error.orderdateTo}</span>
                    )}
                </div>


              </div>
              
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => scanInvoice()}
                  >
                    SEARCH
                  </button>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => exportInvoice()}
                  >
                    Export
                  </button>
                </div>
              </div>


            </div>
          </div>

          <div className="container-fluid">
            <div className="card " style={{ padding: "1em", "overflowX": "auto" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getOrders(currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Total Inwards:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Shelf Location</th>
                    <th scope="col">Code</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">saleable Qty</th>
                    <th scope="col">nonSaleable Qty</th>
                    <th scope="col">damage Qty</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    pageData.map((inw) => {
                      
                      return (
                        <tr key={inw._id}>
                          <td>{inw.shelf}</td>
                          <td>{inw.code}</td>
                          <td>{inw.quantity}</td>
                          <td>{inw.saleableQty}</td>
                          <td>{inw.nonSaleableQty}</td>
                          <td> {inw.damageQty} </td>
                          <td>{inw.date}</td>
                          <td><a style={{"cursor":"pointer"}} onClick={() => createHistory(inw.code)}>Ageing</a></td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>


        <div className="shopperr-modal" style={{display:(open ? "block" : "none") }}>
        <div className="shopperr-modal-content" style={{"width":"55%"}}>
          <div className="shopperr-modal-header">
            <span className="shopperr-modal-close set-margin-modal-close" onClick={() => { setOpen(false) }}>&times;</span>
            <h2>Ageing</h2>
          </div>
          <div className="shopperr-modal-body" >
              
              <div className="row" style={{"marginTop": "10px"}}>
                  <div className="col-md-12">
                    <table className="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">Shelf Location</th>
                          <th scope="col">Code</th>
                          <th scope="col">saleable Qty</th>
                          <th scope="col">nonSaleable Qty</th>
                          <th scope="col">damage Qty</th>
                          <th scope="col">Date</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(tempData) ? (
                          tempData.map((inw) => {
                            
                            return (
                              <tr key={inw._id}>
                                <td>{inw.shelf}</td>
                                <td>{inw.code}</td>
                                <td>{inw.saleableQty}</td>
                                <td>{inw.nonSaleableQty}</td>
                                <td> {inw.damageQty} </td>
                                <td>{inw.date}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <h4>No Data Found</h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

              </div>

              
              <div className="row" style={{"marginTop": "10px"}}>
                <div className='col-md-2'>
                    
                </div>
              </div>
              
            
          </div>
          <div className="shopperr-modal-footer" style={{display:(errorOpen ? "block" : "none") }}>
            <div className="btm-msgs clr-success"></div>
          </div>
        </div>
      </div>
     
    </div>
  );
}
export default OrderList;
