import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import Select from 'react-select'
import jwt_decode from 'jwt-decode';
const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`

function CustomerList() {
    const [customers, setCustomers] = useState([])
    const [pageData, setPageData] = useState([])
    const [errormessage, setErrorMessage] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [showPerPage, setShowPerPage] = useState(50)
    const [selectStore, setSelectStore] =  useState("Select Store Name")
    const [storeData, setStoreData] = useState([])
    const [searchString, setSearchString] = useState("")
    const [tempCustomerData, setTempCustomerData] = useState([])
    const [loading, setLoading] = useState([true])
    const [defaultStore, setDefaultStore] = useState("")

    useEffect(() => {
        let domain =  jwt_decode(localStorage.getItem('token')).storeDomain
        setLoading(false)
        setDefaultStore(domain)
        getCustomer(domain) 
    },[])
    const merchantId = localStorage.getItem("userId");
    
    const getCustomer = (storeDomain) => {
        setLoading(true)
        axios.get(`/shopperrds/customers?store=${storeDomain}&merchantId=${merchantId}`, {
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(res => {
            setLoading(false)
            if (!res.data.hasOwnProperty('message')) { 
                setCustomers(res.data)
                setPageCount(Math.ceil(res.data.length / showPerPage))
                const customerData = res.data.filter((customer, index) => {
                    if(index < showPerPage) return customer
                })
                console.log(customerData);
                setPageData(customerData);
            }else {
                setErrorMessage(res.data.message)
            }
        })
        .catch(err => {
            setLoading(false) 
        })
    }

    const handleEdit = () => {
        console.log("Button")
    }

    const handleChange = (e) => { 
        setSelectStore(e.target.value)
        getCustomer(e.target.value)
        document.getElementById("searchCompanyName").value = ""
        document.getElementById("searchGstNumber").value = ""
        document.getElementById("searchMobileNumber").value = ""
        document.getElementById("searchName").value = ""
    }

    const handlePageClick = (e) => {
        setLoading(true)
        displayPageData(e.selected, tempCustomerData, searchString)
    }

    function displayPageData(page, customerData, searchString){
        if(isEmpty(searchString)){
            const tempcustomerData = customers.filter((customer, index) => {
                if ((page === 0) && (index < showPerPage)) return customer
                else if((page !== 0) && (index >= (page * showPerPage)) && (index < (page * showPerPage) + showPerPage) ) return customer
            })
            setPageData(tempcustomerData)
            setPageCount(Math.ceil(customers.length / showPerPage))
            setLoading(false)
        }else{
            const tempcustomerData = customerData.filter((customer, index) => {
                if ((page === 0) && (index < showPerPage)) return customer
                else if((page !== 0) && (index >= (page * showPerPage)) && (index < (page * showPerPage) + showPerPage) ) return customer
            })
            setPageData(tempcustomerData)
            setPageCount(Math.ceil(customerData.length / showPerPage))
            setLoading(false)
        }
    }

    const handleSearchName = e => {
        setLoading(true)
        document.getElementById("searchCompanyName").value = ""
        document.getElementById("searchGstNumber").value = ""
        document.getElementById("searchMobileNumber").value = ""
        const customerData = customers.filter(customer => {
            let name = `${customer.firstName} ${!isEmpty(customer.lastName) ? customer.lastName : ""}`
            return !isEmpty(customer.firstName) && name.toUpperCase().includes(e.target.value.toUpperCase())
        })
        setSearchString(e.target.value)
        setTempCustomerData(customerData)
        displayPageData(0, customerData, e.target.value)
    }

    const handleMobileNumber = e => {
        setLoading(true)
        document.getElementById("searchCompanyName").value = ""
        document.getElementById("searchGstNumber").value = ""
        document.getElementById("searchName").value = ""
        const customerData = customers.filter(customer => {
            return !isEmpty(customer.mobileNumber) && customer.mobileNumber.includes(e.target.value)
        })
        setSearchString(e.target.value)
        setTempCustomerData(customerData)
        displayPageData(0, customerData, e.target.value)
    }
    
    const handleGSTNumber = e => {
        let findString = ""
        for(let i = 0; i < e.target.value.length; i++){
            if (e.target.value.match(/[a-z]/i))
                findString = findString += e.target.value[i].toLowerCase()
            else findString += e.target.value[i]
        }
        setLoading(true)
        document.getElementById("searchCompanyName").value = ""
        document.getElementById("searchName").value = ""
        document.getElementById("searchMobileNumber").value = ""
        const customerData = customers.filter(customer => {
            return !isEmpty(customer.gstNumber) && customer.gstNumber.includes(findString)
        })
        setSearchString(e.target.value)
        setTempCustomerData(customerData)
        displayPageData(0, customerData, e.target.value)
    }

    const handleCompanyName = e => {
        setLoading(true)
        document.getElementById("searchName").value = ""
        document.getElementById("searchGstNumber").value = ""
        document.getElementById("searchMobileNumber").value = ""
        const customerData = customers.filter(customer => {
            return !isEmpty(customer.companyName) && customer.companyName.toUpperCase().includes(e.target.value.toUpperCase())
        })
        setSearchString(e.target.value)
        setTempCustomerData(customerData)
        displayPageData(0, customerData, e.target.value)
    }

    return (
        <div className="content bdycontent">
            <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
            <div className="container-fluid"> 
                <div className="card" style={{padding: "1em"}}>
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search By Name"
                                onChange={ handleSearchName }
                                id="searchName"
                                autoComplete="off"
                            />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search By Mobile Number"
                                onChange={ handleMobileNumber }
                                id="searchMobileNumber"
                                autoComplete="off"
                            />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search By GST Number"
                                onChange={ handleGSTNumber.bind(this) }
                                id="searchGstNumber"
                                autoComplete="off"
                                uppercase="true"
                            />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search By Company Name"
                                onChange={ handleCompanyName }
                                id="searchCompanyName"
                                autoComplete="off"
                            />
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card" style={{padding: "1em"}}>
                    <div style={{cursor: "pointer"}} onClick={() => getCustomer(selectStore)}>
                        <div className="form-group">
                            <h5 className="text-bold">Total Customers: <span className="text-muted">{customers.length}</span>
                                &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                            </h5>
                        </div>
                    </div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Company</th>
                                <th scope="col">GST No</th>
                                <th scope="col">Mobile</th>
                                {/* <th scope="col">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>  
                            {
                                !isEmpty(pageData)
                                ?
                                    pageData.map((customer ,index)=> {
                                        console.log(customer);
                                        return (
                                            <tr key={customer._id}>
                                                <td scope="row">{ index+1 }</td>
                                                <td>{ (customer?.firstName ?? customer?.customer?.firstName ?? "NA") + " " + (customer?.lastName ?? customer?.customer?.lastName ?? "NA") }</td>
                                                <td>{ customer?.email ?? customer?.customer?.email ?? "NA" }</td>
                                                <td>{ customer?.companyName ?? "NA" }</td>
                                                <td>{ customer?.gstNumber?.toUpperCase() ?? "NA" }</td>
                                                <td>{ customer?.mobileNumber ?? customer?.customer?.mobileNumber ?? "NA" }</td>
                                                {/* <td colSpan="2"><Link   to={`/merchant/customer/${customer._id}`}> <i className="fa fa-eye" aria-hidden="true"></i> </Link> <Link   to={`/merchant/edit-customer/${customer._id}`}> <i className="fa fa-pencil" aria-hidden="true"></i> </Link> </td> */}
                                            </tr>
                                        )
                                    })
                                :
                                    <tr>
                                        <td colSpan="8" className="text-center"><h4>No Data Found</h4></td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={ pageCount }
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={10}
                        onPageChange={(e)=> handlePageClick(e)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </div>
    )
         
}
export default CustomerList
 