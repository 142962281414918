import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import { Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import "../../index.css";

function OffShelfFind() {
  const [orders, setOrders] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [hasSearched, setHasSearched] = useState(false); 

  useEffect(() => {
    if (searchString) {
      getOrders(searchString);
    } else {
      setOrders([]); 
      setHasSearched(false);  
    }
  }, [searchString]);

  const getOrders = (combineCode) => {
    const endpoint = `/wms/orderOpCode/${combineCode}`;

    axios
      .get(endpoint, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (!res.data.hasOwnProperty("message")) {
          setOrders(res.data);
        } else {
          setOrders([]); // Clear orders if an error message is returned
        }
        setHasSearched(true); // Set to true to indicate a search was attempted
      })
      .catch((err) => {
        console.log("Error", err);
        setHasSearched(true); // Set to true to indicate a search was attempted
      });
  };

  const handleSearchInvoice = (e) => {
    setSearchString(e.target.value);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <Card 
          title="Search Box Code"
          content={
            <>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={handleSearchInvoice}
                      id="searchOrder"
                      autoComplete="off"
                      style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '10px', width:"350px" }} // Custom styling
                    />
                  </div>
                </div>
              </div>

              <Table striped bordered hover responsive className="mt-3">
                <thead>
                  <tr style={{width:"350px"}}>
                    <th scope="col">OffSelf Code</th>
                  </tr>
                </thead>
                <tbody >
                  {!isEmpty(orders) ? (
                    orders.map((order) => (
                      <tr key={order._id}>
                        <td>{order.op_code}</td>
                      </tr>
                    ))
                  ) : (
                    hasSearched && ( // Show message if a search was attempted and no data is found
                      <tr>
                        <td colSpan="1" className="text-center">
                          <h4>No Data Found</h4>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </>
          }
        />
      </div>
    </div>
  );
}

export default OffShelfFind;
