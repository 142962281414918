import React, {useState, useEffect} from 'react'
import axios from 'axios'

function FinanceUserDashborad() {
    return (
        <div>
            <h3>Welcome to Finance Dashboard</h3>
        </div>
    )
}

export default FinanceUserDashborad