import React, { useState, useEffect } from "react";
import '../assets/css/MerchantSupport.css';  
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import Card from "../components/Card/Card.jsx";
import "../assets/css/productList.css";
import CustomButton from '../components/CustomButton/CustomButton';
//Add Product from CSV File

const MerchantSubscribe = () => { 
    const token = localStorage.getItem("token");
    const decode = jwt_decode(token);
    const [csvData, setCsvData] = useState([]);
    const [productItems, setProductItems] = useState([]); 
    const [platform,setPlatform] = useState(3);
    const [isuploaded,setIsUploaded] = useState();
    useEffect(() => {
        getProductData();
    }, []);

    const getProductData = () => {
        axios.get("/shopperrds/getSubscribeExcelDataByMerchant/"+decode.id).then((products) => {
            console.log('products.data.data: ', products.data.data);
            setProductItems(products.data.data);
            console.log(products.data.data);
            
        });
    };

    const addCSvProduct = (e) => {
        e.preventDefault();
        const scvdata = new FormData();
        scvdata.append('file', csvData[0]);
        scvdata.append("platform",platform);
        scvdata.append('merchant_id', decode.id);
        axios.post('/shopperrds/uploadSubscribeProductCsv', scvdata).then((res) => {
            if (res.data.includes('success')) {
                NotificationManager.success('File uploaded Successfully');
            } else {
                res.data.error? NotificationManager.error(res.data.error.toString()): NotificationManager.error('There is a problem with this csv');
            }
        }).catch((error) => {
            error? NotificationManager.error(error.toString()): NotificationManager.error('There is a problem with this csv');
        });
    };

    const addCSvProductToAmazon = (e) => {
        e.preventDefault();
        const scvdata = new FormData();
        scvdata.append('file', csvData[0]);
        scvdata.append('merchant_id', decode.id);
        scvdata.append('is_uploaded', isuploaded);
        axios.post('/shopperrds/uploadAmazonCsv', scvdata,{
          headers: {
            'auth-token': localStorage.getItem('token')
          }
        }).then((res) => {
            if (res.data.includes('success')) {
                NotificationManager.success('File uploaded Successfully');
            } else {
                res.data.error? NotificationManager.error(res.data.error.toString()): NotificationManager.error('There is a problem with this csv');
            }
        }).catch((error) => {
            error? NotificationManager.error(error.toString()): NotificationManager.error('There is a problem with this csv');
        });
    };

    return (
        <div>
        
            <div className='content bdycontent merchantStore'>
                <Grid fluid>
                    <Row>
                        <div className='col-md-6'> 
                            <form onSubmit={addCSvProduct}>
                                <div className='card card-input'>
                                    <div className='form-group'>
                                        <p  > Upload CSV File </p>
                                        <div id='fileContents'></div>
                                        <input type='file' className='form-control text-center' name='avatar' 
                                        onChange={(e) => { 
                                            setCsvData(e.target.files);
                                        }} encType='multipart/form-data' accept='.csv' /> 
                                        <br />
                                        <div className='card-button' style = {{ margin : '0px' }}>
                                            <CustomButton fill type='submit' >
                                            Upload Products</CustomButton>
                                        </div>
                                        <a href="https://static-sellercentral.shopperr.in/merchantSubscribeList/2021/10/upload_YYXZ3E_60b46b7da974fcd3e1163bf9.csv" target="_blank">Sample Csv</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-md-6'> 
                            <form onSubmit={addCSvProductToAmazon}>
                                <div className='card card-input'>
                                    <div className='form-group'>
                                        <p >Upload CSV File For Amazon Upload </p>
                                        <div id='fileContents'></div>
                                        <input type='file' className='form-control text-center' name='avatar' 
                                        onChange={(e) => { 
                                            setCsvData(e.target.files);
                                        }} encType='multipart/form-data' accept='.csv' /> 
                                        <br />

                                        <input className="form-check-input" 
                                        type="checkbox" 
                                        name="isUploaded" 
                                        onChange={(e) => { 
                                            setIsUploaded(e.target.checked);
                                        }} />
                                        &nbsp;&nbsp;
                                        <label style={{lineHeight:"35px;"}}>is this products already uploaded?
                                            <span>
                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                            </span>
                                        </label>

                                        <div className='card-button' style = {{ margin : '0px' }}>
                                            <CustomButton fill type='submit'>
                                            Upload Products</CustomButton>
                                        </div>
                                        <a href="https://static-sellercentral.shopperr.in/merchantSubscribeList/2021/10/upload_YYXZ3E_60b46b7da974fcd3e1163bf9.csv" target="_blank">Sample Csv</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Row> 
                
                  <Row>
                    <Col md={12}>
                      <Card style={{ border: '1px solid lightgray' }}
                        title="CSV File Export / Import List"
                        category={"Total Exports & Import :" + productItems.length}
                        ctTableFullWidth
                        ctTableResponsive
                        content={
                          <Table striped hover>
                            <thead>
                                <tr>
                                    <th className='text-center'><strong style={{ color : 'black' }}>SNo.</strong></th>
                                    <th className='text-center' style={{width:"30%"}}><strong style={{ color : 'black' }}>Status</strong></th>
                                    <th className='text-center' style={{width:"20%"}}><strong style={{ color : 'black' }}>Action</strong></th>
                                    <th className='text-center' style={{width:"20%"}}><strong style={{ color : 'black' }}>Logs</strong></th>
                                    <th className='text-center' style={{width:"30%"}}><strong style={{ color : 'black' }}>Date</strong></th> 
                                </tr>
                            </thead>
                            <tbody>
                              {productItems.map((item, key) => {
                                return (
                                    <tr key={key}> 
                                        <td className='text-center' style={{width:"10%"}}>{key +1 }</td>
                                        <td  className='text-center' style={{color: (item.status == 'Success' ? 'Green' : 'orange' ) }} >{item.status||"NA"}</td>
                                        <td  className='text-center'><a href={ item.url }>{"View"||"NA"}</a></td>
                                        <td  className='text-center'>{item.resUrl && item.resUrl != '' && <a href={ item.resUrl }>{"Download"||"NA"}</a>}</td>
                                        <td  className='text-center'>{item.date||"NA"}</td> 
                                    </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
            </div>
        </div>
    );
};

export default MerchantSubscribe;


