import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";

import moment from 'moment';

// import { NotificationManager } from "react-notifications";
// import CustomButton from '../../components/CustomButton/CustomButton';
import "react-step-progress-bar/styles.css";
import "../../index.css";

// const override = `
//     position: fixed;
//     display: block;
//     top: 45%;
//     left: 50%;
//     transform: translateX(-50%);
//     transform: translate(-50%, -50%);
//     z-index: 1;
// `;

function OrderList() {
  // const [csvData, setCsvData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // setLoading(false);
    getWinwardList(currentPage, showPerPage, {});
  }, []);

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    getWinwardList(e.selected + 1, showPerPage, {});
  };

  const getWinwardList = (
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/notwinward?page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          // setOrders({ data: { currentPage: res.data } });
          setPageData(res.data.result);
          setTotalData(res.data.count);

          // console.log("res.data.count"+res.data);
        } else {
          // setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };


  return (
    <div className="content">
        <div className="content ">
            
          <div className="container-fluid">
            <div className="card" style={{ "padding": "1em", "overflowX": "auto" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getWinwardList(currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Dustbin:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Inv Number</th>
                    <th scope="col">MKP OrderId</th>
                    <th scope="col">Cff Orderno</th>
                    <th scope="col">I-Orderno</th>
                    <th scope="col">S_Orderno</th>
                    <th scope="col">Description</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Total Price</th>
                    <th scope="col">RMB Price</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Return Reason</th>
                    <th scope="col">Remark</th>
                    <th scope="col">QC</th>
                    <th scope="col">Claim</th>
                    <th scope="col">Type</th>
                    <th scope="col">Account Name</th>
                    <th scope="col">RTO AWB</th>
                    <th scope="col">Return AWB</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">Refund Date</th>
                    <th scope="col">Return Date</th>
                    <th scope="col">Invoice Date</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    
                    pageData.map((inw) => {
                      // let d = new Date(inw.created_date);

                      // const dateString = '2024-11-14T18:30:00.000+00:00';
                      // const formattedDate = moment.utc(inw.refund_date).format('YYYY-MM-DD hh:mm A');

                      // console.log(inw.refund_date);
                      // console.log(formattedDate);

                      let refundDate = '-';
                      if(inw.refund_date!==null){
                        refundDate = moment.utc(new Date(inw.refund_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let createdDate = '-';
                      if(inw.created_date!==null){
                        createdDate = moment.utc(new Date(inw.created_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let returnDate = '-';
                      if(inw.return_date!==null){
                        returnDate = moment.utc(new Date(inw.return_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let invoiceDate = '-';
                      if(inw.invoiceDate){
                        invoiceDate = moment.utc(new Date(inw.invoiceDate)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }
                      

                      // let createdDate = '';
                      // if(inw.created_date!=null){
                      //   createdDate = new Date(inw.created_date);
                      //   createdDate = createdDate.toUTCString()
                      // }else{
                      //   createdDate = '-';
                      // }
                      // let refundDate = '';
                      // if(inw.refund_date!=null){
                      //   refundDate = new Date(inw.refund_date);
                      //   refundDate = refundDate.toUTCString()
                      // }else{
                      //   refundDate = '-';
                      // }
                      // let returnDate = '';
                      // if(inw.return_date!=null){
                      //   returnDate = new Date(inw.return_date);
                      //   returnDate = returnDate.toUTCString();
                      // }else{
                      //   returnDate = '-';
                      // }
                      // let invoiceDate = '';
                      // if(inw.invoiceDate!=null){
                      //   invoiceDate = new Date(inw.invoiceDate);
                      //   invoiceDate = invoiceDate.toUTCString();
                      // }else{
                      //   invoiceDate = '-';
                      // }
                      
                      return (
                        <tr key={inw._id}>
                          <td style={{ fontSize: "14px"}}> {inw.inv_number} </td>
                          <td style={{ fontSize: "14px"}}> {inw.mkpOrderId} </td>
                          <td style={{ fontSize: "14px"}}>{inw.cff_orderno}</td>
                          <td style={{ fontSize: "14px"}}>{inw.i_orderno}</td>
                          <td style={{ fontSize: "14px"}}>{inw.s_orderno}</td>
                          <td style={{ fontSize: "14px"}}>{inw.description}</td>
                          <td style={{ fontSize: "14px"}}>{inw.qty}</td>
                          <td style={{ fontSize: "14px"}}>{inw.unitPrice}</td>
                          <td style={{ fontSize: "14px"}}>{inw.totalPrice}</td>
                          <td style={{ fontSize: "14px"}}>{inw.rmbPrice}</td>
                          <td style={{ fontSize: "14px"}}>{inw.sku}</td>
                          <td style={{ fontSize: "14px"}}>{inw.returnReason}</td>
                          <td style={{ fontSize: "14px"}}>{inw.remark}</td>
                          <td style={{ fontSize: "14px"}}>{inw.qc}</td>
                          <td style={{ fontSize: "14px"}}>{inw.claim}</td>
                          <td style={{ fontSize: "14px"}}>{inw.type}</td>
                          <td style={{ fontSize: "14px"}}>{inw.account_name}</td>
                          <td style={{ fontSize: "14px"}}>{inw.rto_awb}</td>
                          <td style={{ fontSize: "14px"}}>{inw.return_awb}</td>
                          <td style={{ fontSize: "14px"}}>{inw.order_status}</td>
                          <td style={{ fontSize: "14px"}}>{createdDate}</td>
                          <td style={{ fontSize: "14px"}}>{refundDate}</td>
                          <td style={{ fontSize: "14px"}}>{returnDate}</td>
                          <td style={{ fontSize: "14px"}}>{invoiceDate}</td>
                          {/* <td style={{ fontSize: "14px"}}>{d.toUTCString()}</td> */}
						              
                        </tr>
                      );
                    
                  })
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              
              <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  forcePage={currentPage - 1}
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={10}
                  onPageChange={(e) => handlePageClick(e)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
            </div>
          </div>
          
        </div>
     
    </div>
  );
}
export default OrderList;
