import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import Card from '../../components/Card/Card.jsx';

const AutoMargin = () => {
  const [margin, setMargin] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState('');

  useEffect(() => {
    getCategoryList();    
  }, []);

  const updatePrice = async (e) => {
    e.preventDefault();
    try {
      if (margin === '') {
        NotificationManager.error('enter margin ');
      } else if (parseFloat(margin) === 0) {
        NotificationManager.error('enter margin properly');
      } else if (category === '') {
        NotificationManager.error('Select category ');
      } else {
        const getProCategory = await axios.patch('/admin/autoMargin', { margin, category });
        if (getProCategory.data.includes('success')) {
          NotificationManager.success(`${category} category Product selling price updated with ${margin}% Successfully`);
          setMargin('');
          getCategoryList();
        }
      }
    } catch (error) {
      NotificationManager.error('Something unusual happened');
    }
  };

  const getCategoryList = () => {
    axios.get('/admin/totalCategoryMargin')
      .then(data => {
        data.data.sort((a, b) => Date(b.margin_updated) - Date(a.margin_updated));
        setCategoryList(data.data);
      });        
  };

  return (
    <div className="">
      <div className='content'>
        <Grid fluid>
          <div className="">
            <form onSubmit={updatePrice} className='row'>
              <div className="col-md-5">
                <select className="form-control" value={category} onChange={(e) => setCategory(e.target.value)}>
                  <option>Select Category</option>
                  {categoryList.map(item => {
                    return (
                      <option key={item.category}>{item.category}</option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-4">
                <input type="number" min="0" className="form-control" placeholder="Enter Margin calculated in %" value={margin} onChange={(e) => setMargin(e.target.value)} />
              </div>
              <div className="col-md-3">
                <button type="submit" className="btn btn-primary btn-fill">Update</button>
              </div>
            </form>
          </div>
          <Row>
            <Col md={12}>
              <Card
                category={'Total Categories :' + categoryList.length}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Margin</th>
                        <th>Updated On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryList.slice(0, 20).map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.category}</td>
                            <td>{item.margin || 0}%</td>
                            <td>{item.margin_updated || '-'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
};

export default AutoMargin;
