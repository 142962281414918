import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Icon from '@material-ui/icons';
import axios from 'axios'
import Swal from 'sweetalert2'
import SyncLoader from "react-spinners/SyncLoader"

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 1;
`

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function AddShopifyApi() {
    const classes = useStyles();
    const [shopifyApiKey, setShopifyApiKey] = useState('');
    const [shopifySecretKey, setShopifySecretKey] = useState('');
    const [storeDomain, setStoreDomain] = useState('');
    const [storeData, setStoreData] = useState({});
    const [isValue, setIsValue] = useState(false)
    const [loading, setLoading] = useState(true)
  
    useEffect(() => {
        // similar to componentDidMount
        setLoading(true)
            axios.get(`/shopperrds/shopdata`, {
                headers: {
                    'auth-token': localStorage.getItem('token')
            }
        }).then(res => {
            const shopData = res.data.storeData
            setStoreData(shopData)
            shopData.length && setIsValue(true)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    },[]);

  const handleSubmit = () => {
    //   e.preventDefault()
    if(shopifyApiKey && shopifySecretKey) {
        const formData = {
          storeDomain: storeDomain,
          shopifyApiKey: shopifyApiKey,
          shopifySecretKey: shopifySecretKey
        }
        setLoading(true)
        axios.post('/shopperrds/createApi',formData, {
          headers: {
            'auth-token': localStorage.getItem('token')
          }
        })
          .then(res => {
            const data = res.data
            if (data.hasOwnProperty('error')) {
              Swal.fire(data.error)
            } else { 
              Swal.fire('Added Successfully')
              setStoreDomain('')
              setShopifyApiKey('')
              setShopifySecretKey('')
              setStoreData(data.storeData)
              console.log("storeData - ", data.storeData)
              setIsValue(true)
              console.log("StoreDomain - ", storeDomain)
              window.location.reload();
            }
            setLoading(false)
          })
          .catch(err => {
            Swal.fire('Invalid Api or secret')
            console.log("error ------- ", err)
            setLoading(false)
          })
      } else {
        Swal.fire({text: "Fields can't be blank" , confirmButtonColor: "rgb(255, 160, 0)"})
      }

  }
  const handleDelete = (data) => {
    const confirm = window.confirm("Are you sure ?")
    if (confirm) {
      setLoading(true)
      axios.put('/sscapi/delete', data, {
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      })
        .then(res => {
          if (!res.hasOwnProperty("error")) {
            const shopData = res.data 
            setStoreData(shopData.storeData)
            Swal.fire("Deleted Successfully")
          }
          setLoading(false)
        })
        .catch(err => {
          alert("Error Deleting data")
          setLoading(false)
        })
    }
  } 
  return (
    <div className="content  merchantStore">
      <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
      <div className="container-fluid"> 
          <div className="row">
            <div className="col-md-12">
                <div className="card">
                  <div  style={{padding: '15px'}}>
                      <form className={classes.root} noValidate autoComplete="off" >
                        <TextField id="outlined-basic" value={ storeDomain } label="Store Domain" variant="outlined" onChange={(e) =>
                        setStoreDomain(e.target.value) } />
                        <TextField id="outlined-basic" value={ shopifyApiKey } label="API key" variant="outlined" onChange={(e) =>
                        setShopifyApiKey(e.target.value) } />
                        <TextField id="outlined-basic" value={ shopifySecretKey } label="Secret Key" variant="outlined" onChange={(e) =>
                        setShopifySecretKey(e.target.value) } />
                        <Button variant="contained" style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , color : 'white', fontSize: "15px" , fontWeight: "500"}} size="large" className={classes.button} onClick={(e) => handleSubmit(e) }>Save</Button>
                      </form>
                     {/* <table className="table table-striped">
                        <thead>
                            <tr>
                              <th scope="col">Store Domain</th>
                              <th className ='text-center' scope="col">API Key</th>
                              <th className ='text-center' scope="col">Secret Key</th>
                              <th className ='text-center' scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            storeData.length && storeData.map(data => {
                            return (
                            <tr key={data._id}>
                                <td scope="row"  >{ data.storeDomain || `--` }</td>
                                <td className ='text-center'>{ data.shopifyApiKey || `--` }</td>
                                <td className ='text-center'>{ data.shopifySecretKey || `--` }</td>
                                <td className ='text-center'> 
                                    <span className="text-danger" onClick={() => handleDelete(data)}><i className="fa fa-trash" aria-hidden="true" style={{fontSize: '22px'}}></i></span>
                                </td>
                            </tr>
                            )
                            })
                            }
                        </tbody>
                      </table>
                          */}
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
        
    
  );
}

export default AddShopifyApi
