import React, {Component  } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

class amazonConnection extends Component {
    connectToAmazon = (mId,spapi_oauth_code,selling_partner_id,decode) => {
        let fromDropShipper = 0;
        if(decode && decode.redirect_url){
            fromDropShipper = 1;
        }
    // window.location.href = 'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0f4ae59e-a495-4ae7-9a72-0fc6bd4fc8ab';
        return axios.post('https://amazon.shopperr.in/api/amazon/saveData',{marchantId:mId,spapi_oauth_code,selling_partner_id,fromDropShipper}).then((data)=>data.data).then((data) => {
            if(!data.error){
                if(fromDropShipper){
                    localStorage.removeItem('sptoken');
                    window.location.href = decode.redirect_url;
                }
                //window.location.href = 'https://app.shopperr.in/merchant/marketplace';
            }
        });
    };

    render() {
        const tokenData = localStorage.getItem('token');
        const sptokenData = localStorage.getItem('sptoken');
        const search = this.props.location.search;
        const searchObj = new URLSearchParams(search)
        const amazon_callback_uri = searchObj.get("amazon_callback_uri");
        const spapi_oauth_code = searchObj.get("spapi_oauth_code");
        const selling_partner_id = searchObj.get("selling_partner_id");
        let mId = searchObj.get("mId");
        let decode = null;

        let redirect_url = searchObj.get("redirect_url");
        let phone = searchObj.get("phone");
        let email = searchObj.get("email");
        if(redirect_url && phone && email){
            axios.post('https://amazon.shopperr.in/api/amazon/connect',{phone,email,redirect_url}).then((data)=>data.data).then((data) => {
                if(!data.error){
                    if(!data.isconnected){
                        localStorage.setItem('sptoken',data.token);
                        window.location.href = data.location;
                    } else {
                        window.location.href = redirect_url;
                    }
                } else {
                    window.location.href = redirect_url;
                }
                console.log(data);
            });
        } else if(!spapi_oauth_code && !amazon_callback_uri){
            localStorage.removeItem('sptoken');
        }
        if(tokenData || sptokenData) {
            decode = sptokenData ? jwt_decode(sptokenData) : jwt_decode(tokenData);
            if(amazon_callback_uri){
                const amazon_state = searchObj.get("amazon_state");
                axios.get('https://amazon.shopperr.in/api/amazon/app-data').then((data)=>data.data).then((data) => {
                     window.location.href = `https://sellercentral.amazon.in/apps/authorize/consent?application_id=${data.sp_app_id}&state=${amazon_state}&version=beta&mId=${decode.id}`;
                });
            }
            mId = decode.id;
        }
        
        if(spapi_oauth_code && decode.id){
			mId = decode.id;
           this.connectToAmazon(mId,spapi_oauth_code,selling_partner_id,decode);
        }
        return (
            <div>
            <div className='content'>
            <div className="common-con-style common-con-style2 marketplace-con clearfix">
                <p>Please Wait...</p>
            </div>
            </div>
            </div>
        );
    }
};

export default amazonConnection;
