import React, { useState } from "react";
import axios from "axios";
import "../../assets/css/supplier.css";
import { NotificationManager } from "react-notifications";

function LogisticUser() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  // const [typeCheck, setTypeCheck] = useState(true)
  const [type, setType] = useState("text");
  const [errors, setErrors] = useState({});
  const updateName = (e) => {
    setName(e.target.value);
  };

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };
  const updatePhoneNo = (e) => {
    setPhoneNo(e.target.value);
  };
  const updatePwd = (e) => {
    setPassword(e.target.value);
  };
  const updateCategory = (e) => {
    setCategory(e.target.value);
  };

  // const handleClick = () => {
  // 	setTypeCheck(!typeCheck)
  // 	if (typeCheck == true) {
  // 		setType('text')
  // 	} else {
  // 		setType('password')
  // 	}
  // }

  const addLogisticUser = (e) => {
    e.preventDefault();
    if (validate()) {
      const obj = {
        logisticUser_id: name,
        email: email,
        password: password,
        category:category
      };
      axios
        .post("/admin/addLogisticUser", obj)
        .then((data) => {
          if (data) {
            NotificationManager.success(
              "New Logistic User Created Successfully"
            );
            setName("");
            setEmail("");
            setPassword("");
            setCategory("");
            setPhoneNo("");
          }
        })
        .catch((err) => {
          NotificationManager.error("Id not Created" + err.message);
        });
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;
    if (!name) {
      isValid = false;
      errors["fullName"] = "Please enter your name.";
    }

    if (!email) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }
    if (!category) {
      isValid = false;
      errors["category"] = "Please enter your category.";
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!password) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    } else if (typeof password !== "undefined") {
      if (password.length < 8) {
        isValid = false;
        errors["password"] = "Password must be greater than eight.";
      }
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <div className="container-fluid">
      <div style={{ padding: "1em", paddingBottom: "0px", marginTop: "15px" }}>
        <div className="form-group col-sm-12 col-md-12 col-lg-12">
          <form onSubmit={addLogisticUser}>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div
              className="form-group col-sm-6 col-md-6 col-lg-6"
              style={{ paddingLeft: "8px" }}
            >
              <label htmlFor="fullName">Username</label>
              <input
                type="text"
                name="fullName"
                className="form-control"
                value={name}
                onChange={updateName}
                style={{ border: "1px solid #1D8BF7" }}
              />
              <div className="text-danger">{errors.fullName}</div>
            </div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div
              className="form-group col-sm-6 col-md-6 col-lg-6"
              style={{ paddingLeft: "8px" }}
            >
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={email}
                onChange={updateEmail}
                style={{ border: "1px solid #1D8BF7" }}
              />
              <div className="text-danger">{errors.email}</div>
            </div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div
              className="form-group col-sm-6 col-md-6 col-lg-6"
              style={{ paddingLeft: "8px" }}
            >
              <label htmlFor="email">Category</label>
              <input
                type="category"
                name="category"
                className="form-control"
                value={category}
                onChange={updateCategory}
                style={{ border: "1px solid #1D8BF7" }}
              />
              <div className="text-danger">{errors.category}</div>
            </div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div
              className="form-group col-sm-6 col-md-6 col-lg-6"
              style={{ paddingLeft: "8px" }}
            >
              <label htmlFor="email">phoneNo</label>
              <input
                type="text"
                name="phoneNo"
                className="form-control"
                value={phoneNo}
                onChange={updatePhoneNo}
                style={{ border: "1px solid #1D8BF7" }}
              />
              <div className="text-danger">{errors.phoneNo}</div>
            </div>
            
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>

            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div
              className="form-group col-sm-6 col-md-6 col-lg-6"
              style={{ paddingLeft: "8px" }}
            >
              <label htmlFor="password">Password</label>
              <input
                type={type}
                name="password"
                className="form-control"
                value={password}
                onChange={updatePwd}
                style={{ height: 40, border: "1px solid #1D8BF7" }}
              />
              <small>Password must be eight characters in length.</small>
              <div className="text-danger">{errors.password}</div>
            </div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>

            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
            <div
              className="form-group col-sm-6 col-md-6 col-lg-6"
              style={{ paddingLeft: "8px" }}
            >
              <button
                className="btn btn-fill"
                style={{
                  backgroundColor: "#1d8bf7",
                  color: "rgb(255, 255, 255)",
                  fontWeight: "500",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 4,
                  border: "0px",
                  fontSize: "15px",
                }}
              >
                Create
              </button>
            </div>
            <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LogisticUser;
