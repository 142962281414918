import React, { useEffect, useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  backgroundColor: "#f5f5f5",
});

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); 

  // Fetch vendors from API
  useEffect(() => {
    axios
      .get("/wms/get_vendors")
      .then((response) => {
        setVendors(response.data.vendors);
      })
      .catch((error) => {
        console.error("Error fetching vendors:", error);
        NotificationManager.error("Error fetching vendors");
      });
  }, []);

  // Calculate the vendors to be displayed on the current page
  const displayedVendors = vendors.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Handle page change
  const handlePageClick = (data) => {
    setPage(data.selected); // Update the page state based on clicked page
  };

  return (
    <div style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Vendor List</h4>
        <Link to="/wms/AddVendor">
          <Button
            style={{
              backgroundColor: "rgb(255, 160, 0)",
              color: "#fff",
              fontWeight: "500",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 4,
              fontSize: "15px",
            }}
          >
            Add Vendor
          </Button>
        </Link>
      </div>

      <TableContainer component={Paper} style={{ marginTop: "20px", overflowY: "auto" }}>
        <Table stickyHeader aria-label="vendor table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: "16px" }}>Vendor Name</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Email</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Address</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>City</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>State</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>GST</StyledTableCell>
              <StyledTableCell style={{ fontSize: "16px" }}>Phone Number</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedVendors.map((vendor) => (
              <TableRow key={vendor._id}>
                <TableCell style={{ fontSize: "16px" }}>{vendor.name}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{vendor.email}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{vendor.address}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{vendor.city}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{vendor.state}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{vendor.gst}</TableCell>
                <TableCell style={{ fontSize: "16px" }}>{vendor.phoneNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: "flex",  marginTop: "20px" }}>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          breakLabel={"..."}
          pageCount={Math.ceil(vendors.length / rowsPerPage)} // Total number of pages
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick} // Page change handler
          containerClassName={"pagination"}
          activeClassName={"active"}
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
          previousLinkStyle={{
            margin: "0 5px",
            cursor: "pointer",
            textDecoration: "none",
            color: "black",
          }}
          nextLinkStyle={{
            margin: "0 5px",
            cursor: "pointer",
            textDecoration: "none",
            color: "black",
          }}
          pageLinkStyle={{
            margin: "0 5px",
            cursor: "pointer",
            textDecoration: "none",
            color: "black",
          }}
          activeLinkStyle={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        />
      </div>
    </div>
  );
};

export default VendorList;
