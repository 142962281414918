import React, {useState, useEffect} from "react";
import axios from 'axios'
import {Redirect, Link, BrowserRouter as Router,

Switch,

Route} from 'react-router-dom';
import '../assets/css/Footer.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.js';


const Footer=()=> {



  return (
        <footer id="footer">
          
    <div className="footer-container">
        <div className="container">
            <div className="row" >
                
                    <div className="col-md-4">
    <a href="https://sellercentral-staging.shopperr.in/" id="nav1-logo">
        <img className="logo img-responsive img-fluid" src={process.env.REACT_APP_S3_URL.trim()+'/img/shopperr_logo2.png'} alt="Melisxpress-The Dropshipping Marketplace"/>
    </a>





    
            <p>About dropshipping marketplace before login</p>
        <a className="btn btn-primary" href="/login-supplier">
          Supplier Login
        </a>
        <a className="btn btn-primary" href="/login-admin">
        Admin Login
          
        </a>
    </div>



                
            </div>
            <div className="row">
                
                    
                
            </div>
        </div>
        <p className="text-sm-center footer-copyright">
            
                <a className="_blank" href="sellercentral-staging.shopperr.in" target="_blank" rel="nofollow">
                © 2020 - Shopperr "The Dropshipping Marketplace"
                </a>
            
        </p>
    </div>

        </footer>
      
    


  );
}
export default Footer;
