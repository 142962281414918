import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
// import jwt_decode from "jwt-decode";

import moment from 'moment';

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [pageData, setPageData] = useState([]);
  const [errormessage, setErrorMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(20);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [orderdateFrom, setOrderdateFrom] = useState("");
  const [orderdateTo, setOrderdateTo] = useState("");
  const [returnDateFrom, setReturnDateFrom] = useState("");
  const [returnDateTo, setReturnDateTo] = useState("");
  const [refundDateFrom, setRefundDateFrom] = useState("");
  const [refundDateTo, setRefundDateTo] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [error, setErrors] = useState({});

  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    
    setLoading(false);
    // setDefaultStore(domain);
    // setSelectStore(domain);
    getOrders(currentPage, showPerPage, {});
  }, []);
  
  const getOrders = (
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/dataManagementList?spID=${userId}&page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          // setOrders({ data: { currentPage: res.data } });
          setPageData(res.data.results);
          setTotalData(res.data.count);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    // getOrders(e.selected + 1, showPerPage, {});
    getOrders(e.selected + 1, showPerPage, {
      text: searchString,
      orderdateFrom:orderdateFrom,
      orderdateTo:orderdateTo,
      returnDateFrom:returnDateFrom,
      returnDateTo:returnDateTo,
      refundDateFrom:refundDateFrom,
      refundDateTo:refundDateTo,
      orderStatus:orderStatus
      });
  };

  // const handleChange = (e) => {
  //   console.log("Came to handle Change", e.target.value);
  //   document.getElementById("searchOrder").value = "";
  //   // setFromDate("");
  //   // setTodate("");
  //   // setSelectStore(e.target.value);
  //   getOrders(currentPage, showPerPage, {});
  // };

  const handleSearchInvoice = (e) => {
    setSearchString(e.target.value);
  };

  const handleFromdate = (orderdateSearch) => {
    setOrderdateFrom(orderdateSearch);
    setErrors({});
  };
  const handleTodate = (orderdateSearch) => {
    setOrderdateTo(orderdateSearch);
    setErrors({});
  };

  const handleReturndateFrom = (returnDate) => {
    setReturnDateFrom(returnDate);
    setErrors({});
  };

  const handleReturndateTo = (returnDate) => {
    setReturnDateTo(returnDate);
    setErrors({});
  };

  const handleRefunddateFrom = (refundDate) => {
    setRefundDateFrom(refundDate);
    setErrors({});
  };

  const handleRefunddateTo = (refundDate) => {
    setRefundDateTo(refundDate);
    setErrors({});
  };

  const handleOrderStatus = (e) => {
    setOrderStatus(e.target.value);
    
    orderSearch(e.target.value)
  };
  
  function orderSearch(orderStatus){
    
    getOrders(1, showPerPage, {
      text: searchString,
      orderdateFrom:orderdateFrom,
      orderdateTo:orderdateTo,
      returnDateFrom:returnDateFrom,
      returnDateTo:returnDateTo,
      refundDateFrom:refundDateFrom,
      refundDateTo:refundDateTo,
      orderStatus:orderStatus
    });
  }

  function scanInvoice(){
    // console.log(searchString);
    // orderdateSearch;
    // returnDate;
    // refundDate;
    // orderStatus;

    getOrders(1, showPerPage, {
      text: searchString,
      orderdateFrom:orderdateFrom,
      orderdateTo:orderdateTo,
      returnDateFrom:returnDateFrom,
      returnDateTo:returnDateTo,
      refundDateFrom:refundDateFrom,
      refundDateTo:refundDateTo,
      orderStatus:orderStatus
    });
  }


  return (
    <div className="content">
      
        <div className="content ">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-md-3">
                  {/* <div className="form-group"> */}
                    <label>Search</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Text"
                      onChange={handleSearchInvoice}
                      id="searchOrder"
                      autoComplete="off"
					            onKeyUp={() => scanInvoice()}
                    />
                  {/* </div> */}
                </div>

                
                <div className="col-md-3">
                  {/* <div className="form-group"> */}
                    <label>Order Status</label>
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Order Status"
                      onChange={(e) => setOrderStatus(e.target.value)}
                      id="searchOrder"
                      autoComplete="off"
                      value={orderStatus}
					          /> */}
                    <select id="searchOrder" className="form-control" onChange={handleOrderStatus} >
                      <option value="">Select</option>
                      <option value="0">Initialize</option>
                      <option value="1">Dispatch from Warehouse</option>
                      <option value="2">In-Transite</option>
                      <option value="3">Deliever</option>
                      <option value="4">RTO</option>
                      <option value="5">Return</option>
                      <option value="6">QC</option>
                      <option value="7">In Stock</option>
                      <option value="8">Damage</option>
                      <option value="9">Refund</option>
                      <option value="10">Invoiced</option>
                      <option value="11">Cancel Before Dispatch</option>
                      <option value="shipped">Shipped</option>
                    </select>
                  {/* </div> */}
                </div>

                <div className="col-md-3">
                    <label>
                      Order Date From &nbsp;
                      <span
                      onClick={() => {
                        document.getElementById("orderdateFrom").value = ""; // Clears the input value
                        setOrderdateFrom(""); // Clears the state
                      }}
                      style={{ cursor: "pointer", color: "#42d0ed" }}
                    >
                      (Clear)
                    </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="orderdateFrom"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Order Date"
                      value={orderdateFrom}
                      onChange={(e) => handleFromdate(e.target.value)}
                    />
                    {!isEmpty(error.orderdateFrom) && (
                      <span className="text-danger">{error.orderdateFrom}</span>
                    )}
                </div>

                <div className="col-md-3">
                    <label>
                      Order Date To &nbsp;
                      <span
                        onClick={() =>{
                          document.getElementById("orderdateTo").value = "";
                          setOrderdateTo("");
                        }}
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="orderdateTo"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Order Date"
                      value={orderdateTo}
                      onChange={(e) => handleTodate(e.target.value)}
                    />
                    {!isEmpty(error.orderdateTo) && (
                      <span className="text-danger">{error.orderdateTo}</span>
                    )}
                </div>

                <div className="col-md-3">
                    <label>
                      Return Date From &nbsp;
                      <span
                        onClick={() =>
                          {
                            document.getElementById("returnDateFrom").value = "";
                            setReturnDateFrom("");
                        }}
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="returnDateFrom"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Return Date"
                      value={returnDateFrom}
                      onChange={(e) => handleReturndateFrom(e.target.value)}
                    />
                    {!isEmpty(error.returnDateFrom) && (
                      <span className="text-danger">{error.returnDateFrom}</span>
                    )}
                </div>

                <div className="col-md-3">
                    <label>
                      Return Date To &nbsp;
                      <span
                        onClick={() =>{
                          document.getElementById("returnDateTo").value = "";
                          setReturnDateTo("");
                        }}
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="returnDateTo"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Return Date To"
                      value={returnDateTo}
                      onChange={(e) => handleReturndateTo(e.target.value)}
                    />
                    {!isEmpty(error.returnDateTo) && (
                      <span className="text-danger">{error.returnDateTo}</span>
                    )}
                </div>
                
                <div className="col-md-3">
                    <label>
                      Refund Date From &nbsp;
                      <span
                        onClick={() =>{
                          document.getElementById("refundDateFrom").value = "";
                          setRefundDateFrom("");
                        }}
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="refundDateFrom"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Refund Date"
                      value={refundDateFrom}
                      onChange={(e) => handleRefunddateFrom(e.target.value)}
                    />
                    {!isEmpty(error.refundDateFrom) && (
                      <span className="text-danger">{error.refundDateFrom}</span>
                    )}
                </div>

                <div className="col-md-3">
                    <label>
                      Refund Date To &nbsp;
                      <span
                        onClick={() =>{
                          document.getElementById("refundDateTo").value = "";
                          setRefundDateTo("");
                        }}
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="refundDateTo"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="Refund Date"
                      value={refundDateTo}
                      onChange={(e) => handleRefunddateTo(e.target.value)}
                    />
                    {!isEmpty(error.refundDateTo) && (
                      <span className="text-danger">{error.refundDateTo}</span>
                    )}
                </div>

                
                
              </div>
              
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => scanInvoice()}
                  >
                    SEARCH
                  </button>
                </div>
              </div>


            </div>
          </div>

          <div className="container-fluid">
            <div className="card " style={{ padding: "1em", "overflowX": "auto" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getOrders(currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Total Inwards:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    {/* <th scope="col" style={{"width":"70%"}}>Supplier Name</th>
                    <th scope="col">OrderId</th> */}
                    <th scope="col">Mkp Orderid</th>
                    <th scope="col">Orderserial</th>
                    <th scope="col">Invoice No</th>
                    <th scope="col">SFC OrderNo</th>
                    <th scope="col">Bafulia Order no</th>
                    <th scope="col">SKU(Code)</th>
                    <th scope="col">Description</th>
                    <th scope="col">Order Qty</th>
                    <th scope="col">Received Qty</th>
                    <th scope="col">Return damaged qty</th>
                    <th scope="col">Order unit price</th>
                    <th scope="col" style={{ maxWidth: '100px' }}>Order total Price</th>
                    <th scope="col">SFC unit Price</th>
                    <th scope="col">SFC total price </th>
                    <th scope="col">SFC RMB price</th>
                    <th scope="col">Safe-T claim Amount</th>
                    <th scope="col">Safe-T claim Approved Amount</th>
                    <th scope="col">Last Mile Traking Id</th>
                    <th scope="col">Last mile Courior Name</th>
                    <th scope="col">Return AWB</th>
                    <th scope="col">Return Type</th>
                    <th scope="col">Account Name</th>
                    <th scope="col">Ship Phone</th>
                    <th scope="col">Weight</th>
                    <th scope="col">Process By</th>
                    <th scope="col">Order status</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Cancelled by China Date</th>
                    <th scope="col">Invoice Generated Date</th>
                    <th scope="col">Dispatch Date</th>
                    <th scope="col">Delievered date</th>
                    <th scope="col">Return Date</th>
                    <th scope="col">Refund Date</th>
                    <th scope="col">Received Scan Date</th>
                    <th scope="col">Safe-t claim date</th>
                    <th scope="col">Received Safe-t claim date</th>
                    <th scope="col">Declined safe-t claim date</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    pageData.map((inw) => {
                      let dd = '';

                      let orderDate = '-';
                      if(inw.orderDate!==null){
                        orderDate = moment.utc(new Date(inw.orderDate)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let disFormat = '-';
                      if(inw.dispatch_date!==null){
                        disFormat = moment.utc(new Date(inw.dispatch_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let refFormat = '-';
                      if(inw.refund_date!==null){
                        refFormat = moment.utc(new Date(inw.refund_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let canFormat = '-';
                      if(inw.cancel_date!==null){
                        canFormat = moment.utc(new Date(inw.cancel_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let retFormat = '-';
                      if(inw.rto_date!==null){
                        retFormat = moment.utc(new Date(inw.rto_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let rtdFormat = '-';
                      if(inw.return_date!==null){
                        rtdFormat = moment.utc(new Date(inw.return_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let tdFormat = '-';
                      if(inw.ticketed_date!==null){
                        tdFormat = moment.utc(new Date(inw.ticketed_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let cbdFormat = '-';
                      if(inw.cancel_before_dispatch!==null){
                        cbdFormat = moment.utc(new Date(inw.cancel_before_dispatch)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let deldFormat = '-';
                      if(inw.deleivered_date!==null){
                        deldFormat = moment.utc(new Date(inw.deleivered_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let rsdFormat = '-';
                      if(inw.received_scan_date!==null){
                        rsdFormat = moment.utc(new Date(inw.received_scan_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let scdFormat = '-';
                      if(inw.safety_claim_date!==null){
                        scdFormat = moment.utc(new Date(inw.safety_claim_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let rscdFormat = '-';
                      if(inw.received_safety_claim_date!==null){
                        rscdFormat = moment.utc(new Date(inw.received_safety_claim_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let dscdFormat = '-';
                      if(inw.declined_safety_claim_date!==null){
                        dscdFormat = moment.utc(new Date(inw.declined_safety_claim_date)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let cchFormat = '-';
                      if(inw.cancelled_by_china!==null){
                        cchFormat = moment.utc(new Date(inw.cancelled_by_china)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      let d = '-';
                      if(inw.invoiceDate!==null){
                        d = moment.utc(new Date(inw.invoiceDate)).utcOffset("+0530:00").format('YYYY-MM-DD hh:mm:ss');
                      }

                      
                      
                      
                      // if(inw.dispatch_date!==null){
                      //   dd = new Date(inw.dispatch_date);
                      //   // disFormat = `${dd.getDate()}-${
                      //   //   dd.getMonth() + 1
                      //   // }-${dd.getFullYear()}`;
                      //   disFormat = dd.toUTCString();
                      // }else if(inw.dispatch_date!=''){
                      //   disFormat = '-';
                      // }else{
                      //   disFormat = '-';
                      // }
                      
                      // let d = '';
                      // if(inw.invoiceDate!=null){
                      //   let d1 = new Date(inw.invoiceDate);
                      //   d = d1.toUTCString();
                      // }else{
                      //   d = '-';
                      // }
                      
                      // let dd = inw.dispatch_date!='undefined' ? new Date(inw.dispatch_date):'0000:00:00';
                      // let disFormat = `${dd.getDate()}-${
                      //   dd.getMonth() + 1
                      // }-${dd.getFullYear()}`;

                      // let cd = new Date(inw.cancel_date);
                    
                    let order_status = '';  
                    if(inw.order_status == 0){
                      order_status = 'Initialize';
                    }else if(inw.order_status == 1){
                      order_status = 'Dispatch from Warehouse';
                    }else if(inw.order_status == 2){
                      order_status = 'In-Transite';
                    }else if(inw.order_status == 3){
                      order_status = 'Deliever';
                    }else if(inw.order_status == 4){
                      order_status = 'RTO';
                    }else if(inw.order_status == 5){
                      order_status = 'Return';
                    }else if(inw.order_status == 6){
                      order_status = 'QC';
                    }else if(inw.order_status == 7){
                      order_status = 'In Stock';
                    }else if(inw.order_status == 8){
                      order_status = 'Damage';
                    }else if(inw.order_status == 9){
                      order_status = 'Refund';
                    }else if(inw.order_status == 10){
                      order_status = 'Invoiced';
                    }else if(inw.order_status == 11){
                      order_status = 'Cancel Before Dispatch';
                    }else{
                      if(inw.trackingId && !inw.return_date && !inw.refund_date){
                        order_status = 'Shipped';
                      }else{
                        order_status = '';
                      }
                      
                    }

                    let processBy = '';  
                    if(inw.processBy == 0){
                      processBy = 'India';
                    }else if(inw.processBy == 1){
                      processBy = 'China';
                    }else if(inw.processBy == 3){
                      processBy = 'Bulk';
                    }else if(inw.processBy == 4){
                      processBy = 'China1';
                    }
                    
                    let orderUnitPrice = inw.orderUnitPrice?inw.orderUnitPrice.toFixed(2):0;
                    let OrderTotalPrice = inw.orderUnitPrice?inw.OrderTotalPrice.toFixed(2):0;

                      return (
                        <tr key={inw._id}>
                          {/* <td scope="row"></td> */}
                          {/* <td>{inw.orderId}</td> */}
                          <td>{inw.mkpOrderId}</td>
                          <td>{inw.orderSerial}</td>
                          <td>{inw.inv_number}</td>
                          <td> {inw.cff_orderno} </td>
                          <td>{inw.i_orderno}</td>
                          <td>{inw.code}</td>
                          <td>{inw.description}</td>
                          <td>{inw.qty}</td>
                          <td>{inw.receiveQty}</td>
                          <td>{inw.returnDamagedQty}</td>
                          <td>{orderUnitPrice}</td>
                          <td>{OrderTotalPrice}</td>
                          <td>{inw.unitPrice}</td>
                          <td>{inw.totalPrice}</td>
                          <td>{inw.rmbPrice}</td>
                          <td>{inw.safetyClaimAmt}</td>
                          <td>{inw.safetyClaimApprovedAmt}</td>
                          <td>{inw.trackingId}</td>
                          <td>{inw.courierName}</td>
                          <td>{inw.return_awb}</td>
                          <td>{inw.return_type}</td>
                          <td>{inw.account_name}</td>
                          <td>{inw.ship_phone}</td>
                          <td>{inw.weight}</td>
                          <td>{processBy}</td>
                          <td>{order_status}</td>
                          <td>{orderDate}</td>
                          <td>{cchFormat}</td>
                          <td>{d}</td>
                          <td>{disFormat}</td>
                          <td>{deldFormat}</td>
                          <td>{rtdFormat}</td>
                          <td>{refFormat}</td>
                          <td>{rsdFormat}</td>
                          <td>{scdFormat}</td>
                          <td>{rscdFormat}</td>
                          <td>{dscdFormat}</td>

                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
     
    </div>
  );
}
export default OrderList;
