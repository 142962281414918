import React, { Component } from 'react';
import { Grid, Row, Col, Table , Button, Modal} from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NotificationManager } from 'react-notifications';
import CustomButton from '../../components/CustomButton/CustomButton.jsx';
import Card from '../../components/Card/Card.jsx';
import SyncLoader from "react-spinners/SyncLoader"
import { Link } from "react-router-dom"
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`

export default class AdminProduct extends Component{
	constructor(){
		super()
		this.state = {
			loading: false,
			adminData: [], 
			adminTotalCount: 0, 
		}
	}

	componentDidMount(){
		this.getAdminData()  
	}
	getAdminData(){
		this.setState({loading: true})
		axios.get(`/admin/getSubAdmin`)     
		.then(result => {
			this.setState({adminData: result.data.items, loading: false , adminTotalCount: result.data.totalCount}) 
		})
		.catch(err => {
			console.log(err)
			this.setState({loading: false})
			NotificationManager.error("Something went wrong, while getting products data")
		})
	}
  

	storeUniqueId = item => {
		localStorage.setItem('code' , item) 
	}

	  
 	  
	render(){
		return(
			<div>
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
				<br></br>
				<div className={this.state.loading ? "parentDisable" : ""}>
					<div className="container-fluid">
						<div className="card" style={{padding: "1em"}}>
							<div className="row">
								<div className="col-sm-3 col-md-3 col-lg-3">
									 
								</div>
								<div className="col-sm-3 col-md-3 col-lg-3">
									 
								</div>
								<div className="col-sm-3 col-md-3 col-lg-3">
									 
								</div> 
								<div className="col-sm-1 col-md-1 col-lg-1 text-right">
								</div>
								<div className="col-sm-2 col-md-2 col-lg-2 text-right">
									<button className='btn btn-fill' style={{ 
										backgroundColor: 'rgb(255, 160, 0)', 
										color: 'rgb(255, 255, 255)', 
										fontWeight: '500', 
										height: "40px", 
										display: "flex", 
										alignItems: "center", 
										justifyContent: "center", 
										borderRadius: 4, 
										border:'0px', 
										'fontSize': '15px'
									}} > <a style={{ color: 'rgb(255, 255, 255)' }} href = "/admin/add-subAdmin">Add Sub Admin</a>  </button>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid" >
						<div className="card" style={{padding: "1em"}}>
							<div style={{cursor: "pointer"}} onClick={() => this.getAdminData(1, this.state.showPerPage, {})}>
								<div className="form-group">
									<h5 className="text-bold">Total Products: <span className="text-muted">{this.state.adminTotalCount}</span>
										&nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
									</h5>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-hover">
									<thead>
										<tr className="d-flex text-center">
											<th scope="col" className="text-center" >Sno</th> 
											<th scope="col" className="text-center">Name</th>
											<th scope="col" className="text-center">Email</th>
											<th scope="col" className="text-center">Category</th> 
											<th scope="col" className="text-center">Action</th> 
										</tr>
									</thead>
									<tbody>
										{
											!_.isEmpty(this.state.adminData)
											?
												this.state.adminData.map((item, index) => {
													const {_id, name, email, category} = item
													return(
														<tr key={index} className="d-flex text-center">
															<td> {index + 1} </td>
															<td>{name}</td>
															<td >{email}</td>
															<td >{category}</td> 
															<td>
																<Link to="/admin/editSubAdmin" onClick={() => this.storeUniqueId(_id)}>
									                            	<i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '22px'}}></i> 
									                            </Link>
															</td>
														</tr>
													)
												})
											:
												<tr>
													<td colSpan="5" className="text-center" style={{fontSize: "20px"}}>No Admin Found</td>
												</tr>
										}
									</tbody>
								</table>
							</div> 
						</div>
					</div>
					<br></br>
				</div>
			</div>
		)
	}
}