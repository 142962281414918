import React, { useState, useEffect, Component } from 'react';
import { Grid, Row, Col, Table, Checkbox } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import _ from 'lodash'
import Swal from 'sweetalert2'
import BarcodePo from './BarcodePo'

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 1;
`
const statusArray = [
	{
		"id": 1,
		"display": "1- New",
		"value": "New"
	},
	{
		"id": 2,
		"display": "2- Acknowledge",
		"value": "Acknowledge"
	},
	{
		"id": 3,
		"display": "3- Barcode Generated (Barcode Printed)",
		"value": "Barcode Generated (Barcode Printed)"
	},
	{
		"id": 4,
		"display": "4- PO Packaging",
		"value": "PO Packaging"
	},
	{
		"id": 5,
		"display": "5- Ready for Dispatch",
		"value": "Ready for Dispatch"
	},
	{
		"id": 6,
		"display": "6- Ready for Pickup",
		"value": "Ready for Pickup"
	},
	{
		"id": 7,
		"display": "7- Pickup from source",
		"value": "Pickup from source"
	},
	{
		"id": 8,
		"display": "8- Reached China Warehouse",
		"value": "Reached China Warehouse"
	},
	{
		"id": 9,
		"display": "9- Manifest Awaited",
		"value": "Manifest Awaited"
	},
	{
		"id": 10,
		"display": "10- Manifest Generated (Barcode Printed)",
		"value": "Manifest Generated (Barcode Printed)"
	},
	{
		"id": 11,
		"display": "11- Ready for Dispatch to India",
		"value": "Ready for Dispatch to India"
	},
	{
		"id": 12,
		"display": "12- Packing Label Generated",
		"value": "Packing Label Generated"
	},
	{
		"id": 13,
		"display": "13- Pick Up Requested",
		"value": "Pick Up Requested"
	},
	{
		"id": 14,
		"display": "14- Pickup from China warehouse",
		"value": "Pickup from China warehouse"
	},
	{
		"id": 15,
		"display": "15- In transit",
		"value": "In transit"
	},
	{
		"id": 16,
		"display": "16- Reached to India Customs",
		"value": "Reached to India Customs"
	},
	{
		"id": 17,
		"display": "17- BCD payment awaited",
		"value": "BCD payment awaited"
	},
	{
		"id": 18,
		"display": "18- BCD payment done",
		"value": "BCD payment done"
	},
	{
		"id": 19,
		"display": "19- Custom Cleared",
		"value": "Custom Cleared"
	},
	{
		"id": 20,
		"display": "20- Dispatch to India Warehouse",
		"value": "Dispatch to India Warehouse"
	},
	{
		"id": 21,
		"display": "21- Receiving of goods at India Warehouse",
		"value": "Receiving of goods at India Warehouse"
	},
	{
		"id": 22,
		"display": "22- PO Completed",
		"value": "PO Completed"
	}
]

const monthsArray = [
	{
		"id": '01',
		"name": "January" 
	},
	{
		"id": '02',
		"name": "February" 
	},
	{
		"id": '03',
		"name": "March" 
	},
	{
		"id": '04',
		"name": "April" 
	},
	{
		"id": '05',
		"name": "May" 
	},
	{
		"id": '06',
		"name": "June" 
	},
	{
		"id": '07',
		"name": "July" 
	},
	{
		"id": '08',
		"name": "August" 
	},
	{
		"id": '09',
		"name": "September" 
	},
	{
		"id": '10',
		"name": "October" 
	},
	{
		"id": '11',
		"name": "November" 
	},
	{
		"id": '12',
		"name": "December" 
	},
]

const headers = {
  userkey: 'AKIAZQ3DPSAOTI3ZHWEF',
  usertoken: 'WQjJMBKTaHcjWx0TevZydD12vPbGs7J+fnZzCMUc',
};

export default class FinanceBilling extends Component{
	constructor(props){
		super(props)

		this.yearRef = React.createRef();
		this.monthRef = React.createRef();
		this.batchRef = React.createRef();

		let newDate = new Date();

		this.state = {
			pageDataCount: 0,
			loading: false,
			orders: [],
			currentPage: 1,
			showPerPage: 50,
			pageCount: 0,
			productTotalCount: 0,
			query: { source: "orders" },
			fromDate: "",
			toDate: "",
			errors: {},
			searchSku: "",
			selectedIds: [],
			selectedStatus: 0,
			barcodeStatus: 0,
			allSelected: false,
            type: [],
            loadStatus: false,
            currentYear: newDate.getFullYear(),
            currentMonth: (newDate.getMonth() + 1).toString().padStart(2, '0'),
            years: [],
            batchData: '',
            showDownloadBtn: false
		}
	}

	getBatchData(){
    let errors = {};

    if (!this.yearRef.current.value) {
    	NotificationManager.error('Please select the year.');
    	return false;
    }

    if (!this.monthRef.current.value) {
    	NotificationManager.error('Please select the month.');
    	return false;
    }

		this.setState({loading: true})

		const url = 'api/billing/batch';
		
		const params = {
		  year: this.yearRef.current.value,
		  month: this.monthRef.current.value,
		};

		axios.get(url, { params, headers })
		  .then(result => {
		  	
		  	this.setState({loading: false});

		    if (result.data.error == true) {
			  this.setState({batchData: '', showDownloadBtn: false});
			  NotificationManager.error(result.data.message);
			  const selectBatch = document.getElementById('selectBatch');
			  selectBatch.innerHTML = `<option value=''>Select Batch</option>`; // Clear previous options
			} else {
			  this.setState({batchData: result.data.dropdown, showDownloadBtn:true});
			  const selectBatch = document.getElementById('selectBatch');
			  selectBatch.innerHTML = `<option value=''>Select Batch</option>`; // Clear previous options
			  selectBatch.innerHTML += this.state.batchData;
			}

		  })
		  .catch(error => {
		    console.error(error);
		  });
    }

    downloadBatchData = () => {

    	if (!this.yearRef.current.value) {
	    	NotificationManager.error('Please select the year.');
	    	return false;
	    }

	    if (!this.monthRef.current.value) {
	    	NotificationManager.error('Please select the month.');
	    	return false;
	    }

	    if (!this.batchRef.current.value) {
	    	NotificationManager.error('Please select the batch.');
	    	return false;
	    }

	    this.setState({ loading: true });

	    const url = 'api/billing/export-csv';
	    const params = {
	        year: this.yearRef.current.value,
	        month: this.monthRef.current.value,
	        batch: this.batchRef.current.value,
	    };

	    axios.get(url, {
	        params,
	        headers,
	        responseType: 'blob' // Set response type to 'blob' for downloading files
	    })
	    .then(response => {

	        this.setState({ loading: false });

	        // Create a blob from the response data
	        const blob = new Blob([response.data], { type: 'text/csv' });

	        // Create a download link
	        const url = window.URL.createObjectURL(blob);
	        const link = document.createElement('a');
	        link.href = url;
	        link.setAttribute('download', `batch_data_${params.year}_${params.month}_${params.batch}.csv`);

	        // Append to the DOM and trigger the download
	        document.body.appendChild(link);
	        link.click();

	        // Clean up and remove the link
	        link.parentNode.removeChild(link);

	        NotificationManager.success('Batch data downloaded successfully!');
	    })
	    .catch(error => {
	        this.setState({ loading: false });
	        console.error(error, error.message);
	        NotificationManager.error('No data found for the specified batch.');
	    });
	}

	componentDidMount() {
		// Fetch the year list when the component mounts
		this.getYearList();
	}

	getYearList = async () => {
		try {
			this.setState({ loading: true });

			const headers = {
			  userkey: 'AKIAZQ3DPSAOTI3ZHWEF',
			  usertoken: 'WQjJMBKTaHcjWx0TevZydD12vPbGs7J+fnZzCMUc',
			};

			const response = await axios.get('/api/billing/years', { headers }); // Adjust the API endpoint as needed			
			if (response.data.success) {
				this.setState({ years: response.data.years });
			}
		} catch (error) {
			console.error('Error fetching years:', error);
		} finally {
			this.setState({ loading: false });
		}
	};

	render() {
		const { years, currentYear, currentMonth, showDownloadBtn, loading } = this.state;

		return (
			<div>
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
				{
					this.state.barcodeStatus ? (
						<div>
							<BarcodePo selectedIds={this.state.selectedIds} />
						</div>
					) : (
						<div>
							<div className="container-fluid">
								<div className="card" style={{ padding: "1em" }}>
									<div className="row">
										<div className="col-sm-12 col-md-2 col-lg-2 col-xl-3">
											<div className="form-group">
												<h6>&nbsp;</h6>
												<select ref={this.yearRef} className="form-control" id="selectYear" onChange={() => this.getBatchData()}>
													<option value="">Select Year</option>
													{
														years.length > 0 && 
														years.map((year, index) => (
															<option key={index} value={year}>
																{year}
															</option>
														))
													}
												</select>
											</div>
										</div>
										<div className="col-sm-12 col-md-2 col-lg-2 col-xl-3">
											<div className="form-group">
												<h6>&nbsp;</h6>
												<select ref={this.monthRef} className="form-control" id="selectMonth" onChange={() => this.getBatchData()}>
													<option value="">Select Month</option>
													{
														monthsArray.length &&
														monthsArray.map((month, i) => {
															const { id, name } = month;
															const isSelected = currentMonth === id;
															return (
																<option key={id} value={id}>
																	{ name }
																</option>
															);
														})
													}
												</select>
											</div>
										</div>
										<div className="col-sm-12 col-md-2 col-lg-2 col-xl-3">
											<div className="form-group">
												<h6>&nbsp;</h6>
												<select ref={this.batchRef} className="form-control" id="selectBatch">
													<option value="">Select Batch</option>
												</select>
											</div>
										</div>
										{/*<div className="col-sm-12 col-md-3 col-lg-2 col-xl-2">
											<h6>&nbsp;</h6>
											<button className="btn btn-primary" onClick={() => this.getBatchData()}>Get Batch</button>
										</div>*/}
										{
											showDownloadBtn &&
											<div className="col-sm-12 col-md-3 col-lg-2 col-xl-2">
												<h6>&nbsp;</h6>
												<button className="btn btn-success" onClick={() => this.downloadBatchData()}>Download</button>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					)
				}
			</div>
		);
	}
}