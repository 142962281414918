// import Dashboard from "./views/Dashboard.jsx";
// import AddOnProduct from "./views/AddProduct.jsx"
// import ProductList from "./views/ProductList.jsx"
// import CsvProduct from "./views/CsvProduct.jsx"
// import ShopifyProduct from "./views/ShopifyProduct.jsx"
//import ProductLayout from "./layouts/Products.jsx";
// import SubscribeProduct from "./layouts/SubscribeProduct.jsx";
// // import Orders from "./views/Orders.jsx"
// // import AmazonMarketPlace from "./views/AmazonMarketPlace.jsx";
// import MarketPlace from "./views/MarketPlace.jsx";
// // import FulfilledOrders from "./views/FulfilledOrders.jsx"
// import MerchantProfileSetting from "./views/MerchantProfileSetting.jsx";

import PrintA4 from "./views/WmsScreenRoutes/PrintA4.jsx";
import PrintIndiaA4 from "./views/WmsScreenRoutes/PrintIndiaA4.jsx";


import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/admin_style.css";
import jwt_decoder from "jwt-decode";
const jwt = require("jwt-simple");
const uuid = require("uuid");
const url = require("url");
const subdomain = "creationist";
const shared_key = "DvYGZ3Rj0hb2IMIhSnbPsoLMTR48JF1t3BXz2YpPc0ePrtZ9";
const token = localStorage.getItem("token");
let decoder = token ? jwt_decoder(token) : "";
const dashboardRoutes = [
  {
    path: '/print-offshelf/opcode/:opcode',
    name: "Print",
    component: PrintA4,
    layout: '/print',
  },
  {
    path: '/print-indiaoffshelf/offshelf/:offshelf',
    name: "Print",
    component: PrintIndiaA4,
    layout: '/print',
  }
];

function zendeskUrl() {
  // console.log(decoder)
  let payload = {
    iat: new Date().getTime() / 1000,
    jti: uuid.v4(),
    name: decoder.name,
    email: decoder.email,
  };
  var token = jwt.encode(payload, shared_key);
  var redirect =
    "https://" + subdomain + ".zendesk.com/access/jwt?jwt=" + token;
  var query = url.parse(window.location.href, true).query;
  if (query["return_to"]) {
    redirect += "&return_to=" + encodeURIComponent(query["return_to"]);
  }
  return redirect;
}
export default dashboardRoutes;
