import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import InWardUpload from "../../views/WmsScreenRoutes/InWardUpload";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import CustomButton from '../../components/CustomButton/CustomButton';
import "react-step-progress-bar/styles.css";
import "../../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [csvData, setCsvData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // setLoading(false);
    getWinwardList(currentPage, showPerPage, {});
  }, []);

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    getWinwardList(e.selected + 1, showPerPage, {});
  };

  const getWinwardList = (
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/getwinward?page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          // setOrders({ data: { currentPage: res.data } });
          setPageData(res.data.result);
          setTotalData(res.data.count);

          // console.log("res.data.count"+res.data);
        } else {
          // setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const addCSvProduct = (e) => {
    e.preventDefault();
    
    const scvdata = new FormData();
    scvdata.append('file', csvData[0]);
    scvdata.append('id', localStorage.getItem("userId"));
    if(csvData.length==0){return false;}

    axios
      .post('/wms/uploadRefundCsv', scvdata)
      .then((res) => {
        // console.log(res.data.error);
		// if (res.data.includes('success')) {
		if (res.data.error==0) {
          NotificationManager.success(res.data.message);

        } else {
          res.data.error
            ? NotificationManager.error(res.data.Response.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

  return (
    <div class="content">
        <div className="content ">
            <div className="container-fluid">
              <div className="card" style={{padding: "1em"}}>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label htmlFor='productImage' style={{fontSize: 15 }}>Upload Refund File</label>
                    <form onSubmit={addCSvProduct} style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
                      <div className='form-group' style={{margin: "0"}}>        
                        <div id='fileContents'></div>
                        <input
                          type='file'
                          className='form-control text-center'
                          name='avatar'
                          onChange={(e) => { 
                          setCsvData(e.target.files);
                          }}
                          encType='multipart/form-data'
                          accept='.csv'
                        />
                        </div>
                        <div className='' style={{margin: "0 0 0 10px"}}>
                        <CustomButton fill type='submit'>
                          Upload Refund
                        </CustomButton>
                        </div>
                    </form>
                    <a href="https://shopperrcdn.shopperr.in/inward/6613cc084184e227e4815e4e2024/12/nhLTeZ_6613cc084184e227e4815e4e.csv" target="_blank">Sample Csv</a>
                  </div>
                </div>
              </div>
            </div>

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getWinwardList(currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Refund Order:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">MKP OrderId</th>
                    <th scope="col">Order Serial</th>
                    <th scope="col">Refund Date</th>
                    <th scope="col">Order Date</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    
                    pageData.map((inw) => {
                      // let d = new Date(inw.orderDate);
                      let refundDate = '';
                      if(inw.refund_date!=null){
                        refundDate = new Date(inw.refund_date);
                        refundDate = refundDate.toUTCString()
                      }else{
                        refundDate = '-';
                      }

                      let orderDate = '';
                      if(inw.orderDate!=null){
                        orderDate = new Date(inw.orderDate);
                        orderDate = orderDate.toUTCString()
                      }else{
                        orderDate = '-';
                      }
                      // let lp = new Date(inw.op_last_update);
                      // let st = new Date(inw.op_start_time);
                      // let et = new Date(inw.op_end_time);
                      return (
                        <tr key={inw._id}>
                          <td style={{ fontSize: "14px"}}>
                            {inw.mkpOrderId}
                          </td>
                          <td style={{ fontSize: "14px"}}>
                            {inw.s_orderno}
                          </td>
                          <td style={{ fontSize: "14px"}}>{refundDate}</td>
                          <td style={{ fontSize: "14px"}}>{orderDate}</td>
						              
                        </tr>
                      );
                    
                  })
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              
              <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  forcePage={currentPage - 1}
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={10}
                  onPageChange={(e) => handlePageClick(e)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
            </div>
          </div>
          
        </div>
     
    </div>
  );
}
export default OrderList;
