import React, { useState, useEffect } from "react";
import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import axios from 'axios'
import {Link, useHistory  } from 'react-router-dom'


const PaymentConfirm = ({totalPrice, balance, orderId}) => {
  const [productItems, setProductItems] = useState([]); 
  const history = useHistory();

  useEffect(() => {
    // getProductData();
  }, []);
  
  function confirmPay(){
    
    var data = {
        'orderId':orderId, 
        'totalPrice' : totalPrice,
        'user' :  jwt_decode(localStorage.getItem('token')).id,
    };

    axios.post(`/shopperrds/payConfirm/`, data)
        .then(res=> {
            if(res.data.error==0){
                history.push('/merchant/success');
            }
        })

  }

return (
    <div className="card row">
        <div className="col-md-6" style={{"textAlign": "center"}}>
            <div className="modify-con">
                <h3>Confirm Payment</h3>
                <div className="modify-tips">
                
                    <table className="table table-hover">
                        <tbody>
                        <tr>
                            <td className="text-right">Total : </td>
                            <td>Rs.{totalPrice}</td>
                        </tr>
                        <tr>
                            <td className="text-right">Current balance :</td>
                            <td>Rs.{balance}</td>
                        </tr>
                        <tr>
                            <td className="text-right">Balance after payment : </td>
                            <td>Rs.{(balance-totalPrice).toFixed(2)}</td>
                        </tr>
                        </tbody>
                    </table>
                    
                </div>
                <div className="col-md-6">&nbsp;</div>
                <div className="confirm-btn col-md-3">
                    <button className="btn btn-fill btn-primary btn-color1 clearfix" type="button" onClick={() => confirmPay()}>Confirm</button>
                
                </div>
            </div>
        </div>
    </div>
  );
};

export default PaymentConfirm;
