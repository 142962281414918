import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import CustomButton from "../../components/CustomButton/CustomButton.jsx";
import Card from "../../components/Card/Card.jsx"
import '../../assets/css/OrderTicketed.css';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';

const OrderTicketed = (props) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [tickets, setTickets] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [order, setOrder] = useState({}); // Initialize order state
  const [errorOpen, setErrorOpen] = useState(null);
  const [transaction, setTransaction] = useState({}); // Initialize transaction state
  const [mercantData, setMercantData] = useState({}); // Initialize mercantData state
  const [type, selectType] = useState("default");
  const [showPercentageInput, setShowPercentageInput] = useState(false);
  const [percentage, setPercentage] = useState('');
  const [refundAmt, setRefundAmt] = useState('');
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [comment, setComment] = useState();
  const [orderDisputes, setOrderDisputes] = useState([]);
  const [replyText, setReplyText] = useState('');
  const [orderSerial, setOrderSerial] = useState(''); // Initial state can be initialized with appropriate values
  const [merchantId, setMerchanntId] = useState(''); // Initial state can be initialized with appropriate values
  const [orderConsumerPrice, setOrderConsumerPrice] = useState('');

  // const [adminUserId, setAdminUserId] = useState(null);
  const adminUserId = props._id;
  // console.log(adminUserId)
  const [items, setItems] = useState({
    default: {
      spm: 35,
      maxP: 2,
      minP: 0,
      confirm: 1
    },
    // amazon: {
    //   spm: 35,
    //   maxP: 2,
    //   minP: 0,
    //   confirm: 0
    // },
    // shopify: {
    //   spm: 75,
    //   maxP: 2,
    //   minP: 0,
    //   confirm: 0
    // }
  });

  // useEffect(() => {
  //   if (props._id) {
  //     setAdminUserId(props._id);
  //   }
  // }, [props._id]);
  // console.log(adminUserId)
  useEffect(() => {
    axios
      .get(`/admin/order/${id}`)
      .then((res) => {
        const order = res.data.results.order;
        setOrderDisputes(order.order_dispute || []);
        setMessages(res.data.results.messages || []);
        setOrder(order);
        // console.log(order)
      })
      .catch((error) => {
        console.error("There was an error fetching the order data!", error);
      });
  }, [id]); // Dependency on 'id' to re-fetch when it changes
  // Function to toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const openReplyModal = () => {
    setShowReplyModal(true);
  };
  const closeReplyModal = () => {
    setShowReplyModal(false);
  };

  const addTicketDetail = (e) => {
    e.preventDefault();


    const requestData = {
      orderId: id,
      merchantId: merchantId,
      orderSerial: orderSerial,
      orderConsumerPrice: orderConsumerPrice,
      refundAmt: refundAmt,
      // refundType: refundType, // Assuming refundType is also a state variable
      comment: e.target.elements.comment.value || "Refund issued"// Example of capturing comments from form element
    };

    axios.post(`/admin/refund/${id}`, requestData)
      .then((res) => {
        NotificationManager.success('Refund processed successfully.');
        // Optionally update state or perform any other action upon success
        // Example: Clear form fields, update ticket status, etc.
      })
      .catch((error) => {
        NotificationManager.error('Failed to process refund.');
        console.error('Error processing refund:', error);
      });
  };


  const handlePercentageChange = (e) => {
    const value = e.target.value;
    setPercentage(value);
    const percentageValue = parseFloat(value);
    if (!isNaN(percentageValue) && percentageValue > 0 && percentageValue <= 100) {
      const amount = order.consumerPrice;
      const refundAmt = amount * (percentageValue / 100);
      setRefundAmt(refundAmt.toFixed(2));
    } else {
      setRefundAmt('');
    }
  };

  const handleRefundTypeChange = (e) => {
    const selectedValue = e.target.value;
    setShowPercentageInput(selectedValue === 'partially');

    // Reset percentage and refund amount on change
    if (selectedValue === 'fully') {
      // setPercentage('');
      setRefundAmt(orderConsumerPrice); // Set refund amount to the consumer price for fully refund
    } else {
      setPercentage('');
      setRefundAmt('');
    }
  };

  // const handleReplyChange = (e) => {
  //   e.preventDefault(); 
  //   setReplyText(e.target.value);
  // };

  function handleReplySubmit(e) {
    e.preventDefault();

    const replyData = {
      reason: replyText,
      adminUser: localStorage.getItem("userId"),
      orderId: id,
    };
    axios.post(`/admin/${id}/dispute`, replyData)
      .then((res) => {
        NotificationManager.success('Reply added successfully.');
        setOrderDisputes([...orderDisputes, res.data]); // Append new dispute
        setReplyText('');
        closeReplyModal();
      })
      .catch((error) => {
        NotificationManager.error('There was an error adding the reply.');
        console.error('There was an error!', error);
      });
  }

  const handleCloseTicket = () => {
    const confirmed = window.confirm("Are you sure to close this ticket?");
    if (confirmed) {
      const closeData = {
        adminUser: localStorage.getItem("userId"), // Assuming adminUserId is available in localStorage
        dispute_date: new Date(), // Current date/time
        type: 'admin', // Assuming type 'admin' for admin initiated closes
        reason: 'Ticket closed by admin', // You can adjust the reason accordingly
      };

      axios.post(`/admin/${id}/closeTicket`, closeData)
        .then((res) => {
          NotificationManager.success('Ticket closed successfully.');
          // Optionally update state or perform any other action upon success
          // You may want to reload the order data or update UI after closing
          // Example: Reload order data or update UI
          // fetchOrderData(); // Example: Function to reload order data
        })
        .catch((error) => {
          NotificationManager.error('Failed to close ticket.');
          console.error('Error closing ticket:', error);
        });
    }
  };


  function showpercentage(event) {
    const value = event.target.value;
    setPercentage(value);
    const percentageValue = parseFloat(value);
    if (!isNaN(percentageValue) && percentageValue > 0 && percentageValue <= 100) {
      const amount = order.consumerPrice;
      const refundAmt = amount - (amount * (percentageValue / 100));
      setRefundAmt(refundAmt.toFixed(2));
    } else {
      setRefundAmt('');
    }

  }
  function openModel() {
    setOpen(true); // Open the modal
    setOrderSerial(order.orderSerial);
    setOrderConsumerPrice(order.consumerPrice);
    setMerchanntId(order.merchantId)
  }

  return (
    <div>
      <div />
      {/* <div className='content bdycontent'> */}
        {/* <div className="container-fluid"> */}
          {/* <div className="row"> */}
            {/* <div  > */}
              {/* <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2"> */}
                {/* <div className="form-group">
                  <h6>&nbsp;</h6>
                  <select className="form-control cust-select"  >
                    <option value="default">Default</option>
                    <option value="amazon">Amazon</option>
                    <option value="shopify">Shopify</option>
                  </select>
                </div> */}
              {/* </div> */}

              {/* <Row> */}
                {/* <Col md={12}> */}
                  <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
                   title="Ticket Details"
                   category="Details"
                    content={
                      <Table responsive bordered hover>
                        <thead>
                          <tr>
                            <th className='text-center'><strong style={{ color: 'black' }}>State</strong></th>
                            <th className='text-center' style={{ width: "30%" }}><strong style={{ color: 'black' }}>Title</strong></th>
                            <th className='text-center' style={{ width: "30%" }}><strong style={{ color: 'black' }}>Date</strong></th>
                            <th className='text-center' style={{ width: "30%" }}><strong style={{ color: 'black' }}>Operate</strong></th>
                          </tr>
                        </thead>
                        <tbody>
                          {items[type] && items[type].confirm ?
                            <tr>
                              <td className='text-center' style={{ width: "10%" }}> </td>
                              <td className='text-center'>{items[type].maxP}</td>
                              <td className='text-center'  >{items[type].minP} %</td>
                              <div className="form-group">
                                <Row className="col-md-12">
                                  <div className="button-spacing">
                                    <CustomButton fill type="button" onClick={openReplyModal}>
                                      Reply
                                    </CustomButton>
                                  </div>

                                  <div className="button-spacing">
                                    <CustomButton fill type="button" onClick={() => { openModel() }}>
                                      Refund
                                    </CustomButton>
                                  </div>

                                  <div className="button-spacing">
                                    <CustomButton fill type="button" onClick={handleCloseTicket}>
                                      Close ticket
                                    </CustomButton>
                                  </div>
                                </Row>
                              </div>


                              {type !== "default" && <td className='text-center'><i className="fa fa-pencil" aria-hidden="true" style={{ fontSize: '22px', color: '#1dc7ea', cursor: 'pointer' }}></i></td>}
                            </tr> : ""
                          }
                        </tbody>
                      </Table>
                    }
                  />
                  <Card style={{ fontSize: '22px', lineHeight: '30px', margin: '0', color: '#333333', fontWeight: '300' }}
                  title= "History Record"
                    category="Records"
                    content={
                      <>       
                       <Table responsive hover bordered>
                          <thead>
                            <tr>
                              <th className='text-center'><strong style={{ color: 'black' }}>order</strong></th>
                              <th className='text-center'><strong style={{ color: 'black' }}>Dispute</strong></th>
                              <th className='text-center'><strong style={{ color: 'black' }}>Reason</strong></th>
                              <th className='text-center'><strong style={{ color: 'black' }}>Date</strong></th>
                              <th className='text-center'><strong style={{ color: 'black' }}>Picture</strong></th>
                            </tr>
                          </thead>
                          <tbody>

                            {orderDisputes.map((dispute, index) => (
                              <tr key={index}>
                                <td className='text-center'> {dispute.userId}</td>
                                <td className='text-center'>  {dispute.disputeHead}</td>
                                <td className='text-center'> {dispute.reason}</td>
                                <td className='text-center'>{new Date(dispute.dispute_date).toLocaleString()}</td>
                                {dispute.img_url && dispute.img_url.length > 0 &&
                                  <img src={dispute.img_url[0]?.file0} alt="Product" width="50" height="50" className='text-center' />}
                              </tr>
                            ))}

                          </tbody>
                        </Table>
                      </>
                    }
                  />             
          <br />
      <div className="shopperr-modal" style={{ display: (open ? "block" : "none") }}>
      <div className="shopperr-modal-content">
  <div className="shopperr-modal-header">
    <span className="shopperr-modal-close set-margin-modal-close" onClick={() => setOpen(false)}>&times;</span>
    <h6>Details</h6>
  </div>
  <form onSubmit={addTicketDetail}>
    <div className="shopperr-modal-body">
      <div className="new-row">
        <div className="resp-div">
          <p className="label">PO#</p>
          <input 
            type="text" 
            className="input-field" 
            value={orderSerial} 
            onChange={(e) => setOrderSerial(e.target.value)} 
            readOnly 
          />
        </div>
      </div>
      <div className="new-row">
        <div className="resp-div">
          <p className="label">Order Amount</p>
          <input 
            type="text" 
            className="input-field" 
            value={orderConsumerPrice} 
            onChange={(e) => setOrderConsumerPrice(e.target.value)} 
            readOnly 
          />
        </div>
      </div>
      <div className="new-row">
        <div className="resp-div">
          <p className="label">Refund Type</p>
          <select className="select-field" onChange={handleRefundTypeChange}>
            <option value="fully">Fully Refund</option>
            <option value="partially">Partially Refund</option>
          </select>
          <textarea className="textarea-field" name="comment" rows="4" placeholder="Add a comment..."></textarea>
        </div>
      </div>
      {showPercentageInput && (
        <div className="new-row">
          <div className="resp-div">
            <p className="label">Refund Percentage</p>
            <input 
              type="number" 
              className="input-field" 
              value={percentage} 
              onChange={handlePercentageChange} 
            />
            <p className="label">Refund Amount: {refundAmt}</p>
          </div>
        </div>
      )}
      <div className="new-row">
        <div className="resp-div centered-button">
          <button 
            id="subscribe-button" 
            className="btn btn-fill submit-btn"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
  {errorOpen && (
    <div className="shopperr-modal-footer">
      <div className="btm-msgs clr-success">Error message here</div>
    </div>
  )}
</div>
</div>

      <div className="shopperr-modal" style={{ display: (showReplyModal ? "block" : "none") }}>


      <div className="shopperr-modal-content">
  <div className="shopperr-modal-header">
    <span
      className="shopperr-modal-close set-margin-modal-close"
      onClick={() => setShowReplyModal(false)}
    >
      &times;
    </span>
    <h6 className="modal-title">Reply:</h6>
  </div>
  <form onSubmit={handleReplySubmit}>
    <div className="shopperr-modal-body">
      <div className="new-row">
        <div className="resp-div">
          <textarea
            className="modal-textarea"
            name="replyContent"
            rows="4"
            cols="50"
            placeholder="Enter your reply here..."
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          ></textarea>
        </div>
        <div className="resp-div">
          <img src="example.jpg" alt="Example" className="modal-image" />
        </div>
      </div>
      <div className="new-row modal-footer">
        <button
          id="subscribe-button"
          className="btn btn-fill text-center modal-btn"
        >
          Reply
        </button>
      </div>
    </div>
  </form>
</div>

           
      </div>
    </div>
  );
};

export default OrderTicketed;