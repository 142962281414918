import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Link, Redirect } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'
import jwt_decode from 'jwt-decode';
import PaymentConfirm from "../views/PaymentConfirm";


function ShowOrder(props) {
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [showTab, setShowTab] = useState("ewallet");
    const [orderInfo, setOrderInfo] = useState([]);
    const [balanceInfo, setBalanceInfo] = useState([]);
    const [balance, setBalance] = useState(0);
    const [showCompTab, setShowCompTab] = useState("basic");
    const [orderId, setOrderId] = useState("");


    useEffect(()=> {
        let data = {
			'user' :  jwt_decode(localStorage.getItem('token')).id,
            'orderId' : props.match.params.id
		}
        getPayInfo(data)
        setOrderId(props.match.params.id)
    },[])

    function getPayInfo(data){
        axios.post(`/shopperrds/payInfo/`, data)
        .then(res=> {
            if(res.data.error==0){
                setOrderInfo(res.data.response)
                // console.log(res.data.response[0].products[0].price)
                if(res.data.balance.length>0){
                    setBalance(res.data.balance[0].afterAmt)
                }

                setBalanceInfo(res.data.balance)
                setTotalPrice(res.data.response[0].orderDetails[0].consumerPrice)
                var consumerPrice = 0;
                res.data.response[0].orderDetails.forEach((item) => {
                    consumerPrice +=item.consumerPrice;
                })
                setTotalAmount(consumerPrice)
            }
        })
    }

    function payNowAction(type){
        if(balance<totalPrice){
            NotificationManager.error("Recharge E-Wallet")
            
            return false;
        }
        setShowCompTab('confirm')

    }

    
    return (
        <div>
        {showCompTab==='basic' ? (
                <div className="container-fluid">
     
                {orderInfo.map((item) => (
                    <div className="card" style={{"padding": "1em"}}>
                        <h3>Payment information</h3>
                        <div className="modify-tips">
                            <table className="table table-hover">
                                <tbody>
                                    <tr>
                                        <td className="text-right">Shopperr Order Id :</td>
                                        <td>{item.orderSerial}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">Price :</td>
                                        <td>Rs.{item.consumerPrice}</td>
                                    </tr>
                                
                                    <tr>
                                        <td className="text-right">Total :</td>
                                        <td><span style={{"color": "#990000", "fontWeight": "600", "fontSize": "14px"}}>Rs.{totalAmount}</span></td>
                                    </tr>

                                    <tr>
                                        <td className="text-right">Status :</td>
                                        <td>{
                                                item.oStatus!='OC' && item.pStatus=='PU' ? 'Not Paid': ''
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
                
                <div className="card" style={{ padding: "1em" }}>
                    <ul>
                        <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => setShowTab('ewallet')}>E-Wallet</a></li>
                        {/* <li style={{display: "inline-block"}}><a style={{ padding: "10px", color:"#7b999e", cursor: "pointer" }} onClick={() => setShowTab('payment')}>Other Payment Modes</a></li> */}
                    </ul>
                </div>

                {showTab=='ewallet' ? (
                    <div className="card clearfix " style={{"padding": "1em"}}>
                        <div className="col-md-12 space-top ">
                            
                            <h5>Current Balance : Rs.&nbsp;&nbsp;{balanceInfo!='' ? balanceInfo[0].afterAmt : 0 }</h5>
                            
                            <div className="col-md-12" style={{"padding": "0","margin": "30px 0", "fontSize": "14px", "color": "#f69b06"}}>
                                By placing this order, you agree to the&nbsp;<a target="_blank" href="http://www.shopperr.in/index/terms">Terms Of Use</a>&nbsp;and&nbsp;
                                <a target="_blank" href="http://www.shopperr.in/index/privacypolicy">&nbsp;Privacy Policy&nbsp;</a>of shopperr.in
                            </div>
                            <div className="col-md-2 ">
                                <div className="btn btn-primary btn-fill" style={{"background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize":" 15px", "fontWeight": "500", "color": "#fff !important"}} onClick={() => payNowAction('prepay')}>Pay Now</div>
                            </div>
                        </div>
                    </div>
                )
                : showTab=='payment' ? (
                    <div className="card clearfix " style={{"padding": "1em"}}>
                        <div className="col-md-12 space-top ">
                    
                            <div className="col-md-12 " style={{"padding": "0", "margin": "30px 0", "fontSize": "14px", "color": "#f69b06"}}>Please note： Make a payment, you will be redirected to our integrated payment gateway, <strong>Paytm</strong>, which supports <span style={{"color":"red"}}>credit card</span> , <span style={{"color":"red"}}>debit card</span>, <span style={{"color":"red"}}>net banking</span>,  <span style={{"color":"red"}}>wallet</span> and other payment modes and helps you pay securely. By placing this order, you agree to the&nbsp;<a target="_blank" href="http://www.shopperr.in/index/terms">Terms Of Use</a>&nbsp;and&nbsp;<a target="_blank" href="http://www.shopperr.in/index/privacypolicy">&nbsp;Privacy Policy&nbsp;</a>of shopperr.in</div>
                            <div className="col-md-2 ">
                                <div className="btn btn-primary btn-fill" style={{"background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize":" 15px", "fontWeight": "500", "color": "#fff !important"}} onClick="payNowAction('paytm');">Pay Now</div>
                            </div>
                        
                        </div>
                    </div>
                ) 
                : (
                    <p></p>
                )
                }
            </div>
            ) 
            : (
                <PaymentConfirm balance={balance} totalPrice={totalAmount} orderId={orderId} />
            )
        }
        
        


        </div>
    )
}

export default ShowOrder