import React, { useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import '../assets/css/settings.css';
import { NotificationManager } from 'react-notifications';
import Card from '../components/Card/Card.jsx';
import CustomButton from '../components/CustomButton/CustomButton';
import 'react-phone-number-input/style.css';


const MerchantProfileSetting = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [id, setId] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [errors, setErrors] = useState({});
    const token = localStorage.getItem('token');
    const decode = jwt_decode(token);
    useEffect(() => {
        getSupplierProfile();
        setId(decode.id);
    }, []);

    const getSupplierProfile = () => {
        axios.get('/shopperrds/merchantProfile/' + decode.id).then((res) => {
            setEmail(res.data.email);
            setFirstName(res.data.fName);
            setLastName(res.data.lName);
            setPhoneNo(res.data.phoneNo); 
            setGstNumber(res.data.gstNumber ? res.data.gstNumber : '' ); 
            setCompany(res.data.company ? res.data.company : ''); 
            setAddress(res.data.address ? res.data.address : ''); 
            setState(res.data.state ? res.data.state : ''); 
            setCity(res.data.city ? res.data.city : ''); 
            setPincode(res.data.pincode ? res.data.pincode : ''); 
        });
    };

    const updateSettings = async (e) => {
        e.preventDefault();
        if(validate()){
            try {
                let data = {
                    firstName,
                    lastName,
                    email,
                    phoneNo,
                    gstNumber,
                    company,
                    address,
                    state,
                    city,
                    pincode,
                    _id : decode.id
                }; 
                await axios.post('/shopperrds/settingsUpdateMerchant', data).then((response) => {
                    
                    if(response.data.error === 0){
                        NotificationManager.success(response.data.response);
                    }else{
                        NotificationManager.success(response.data.response);
                    }
                    
                }); 
            } catch (error) {
                NotificationManager.error('something unusual happened');
            }
        }
    };

    function validate(){ 
        let errors = {};
        let isValid = true;
        if (!firstName) {
            isValid = false;
            errors["firstName"] = "Please enter your first name.";
        }
        if (!lastName) {
            isValid = false;
            errors["lastName"] = "Please enter your last name.";
        }
        if (!gstNumber) {
            isValid = false;
            errors["gstNumber"] = "Please enter  gst number.";
        }else if (typeof gstNumber !== "undefined") { 
            // let pattern = new RegExp(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/);
            let pattern = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
            if (!pattern.test(gstNumber)) {
                isValid = false;
                errors["gstNumber"] = "Please enter valid number.";
            } 
        }  
        if (!company) {
            isValid = false;
            errors["supplier"] = "Please enter company name.";
        }
        if (!address) {
            isValid = false;
            errors["address"] = "Please enter address.";
        }
        if (!city) {
            isValid = false;
            errors["city"] = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors["state"] = "Please enter state.";
        }
        if (!pincode) {
            isValid = false;
            errors["pincode"] = "Please enter pincode.";
        }else if (typeof pincode !== "undefined") { 
            let pattern = new RegExp(/^\d{6}$/);
            if (!pattern.test(pincode)) {
                isValid = false;
                errors["pincode"] = "Please enter valid pincode.";
            } 
        }
        if (!company) {
            isValid = false;
            errors["company"] = "Please enter company.";
        } 
        if (!email) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }
      
        if (typeof  email !== "undefined") {
              
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }
        
        if (!phoneNo) {
            isValid = false;
            errors["phoneNo"] = "Please enter your phone number.";
        } else if (typeof phoneNo !== "undefined") {  
            var pattern = new RegExp(/^\d{10}$/); 
            if (!pattern.test(phoneNo)) {
                isValid = false;
                errors["phoneNo"] = "Please enter valid phone number.";
            } 
        }  
        setErrors(errors) 
        return isValid;
    }

    return (
        <div className='content'>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title='Accounts Settings'
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <form onSubmit={updateSettings}>
                                    <div className='card ' style={{ marginTop: 0 , padding: '0px 10px' }}>
                                        <div className='col-md-6' style={{ paddingLeft: '6px' }}>
                                            <div className='form-group'>
                                                <label htmlFor='product_quantity'>First Name</label>
                                                <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                                min='0' className='form-control' style={{ color : errors.firstName ? 'red' : '' , borderColor : errors.firstName ? 'red' : '' }} id='product_id' placeholder='Your First Name' />
                                                <p className='text-danger'>{ errors.firstName }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label htmlFor='product_name'>Last Name</label>
                                                <input style={{ color : errors.lastName ? 'red' : '' , borderColor : errors.lastName ? 'red' : '' }} type='text' value={ lastName}  onChange={(e) => setLastName(e.target.value)}  min='0' className='form-control' id='product_name' placeholder='Your Last Name' required />
                                                <p className='text-danger'>{ errors.lastName }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label htmlFor='product_email'>Email</label>
                                                <input style={{ color : errors.email ? 'red' : '' , borderColor : errors.email ? 'red' : '' }} type='email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control' id='product_email' placeholder='example@any.com' />
                                                <p className='text-danger'>{ errors.email }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'> 
                                            <div className='form-group'>
                                                <label htmlFor='product_location'>Phone No</label> 
                                                <input style={{ color : errors.phoneNo ? 'red' : '' , borderColor : errors.phoneNo ? 'red' : '' }} type='number' value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} className='form-control' id='product_mobile' placeholder='Enter Mobile Number' />
                                                <p className='text-danger'>{ errors.phoneNo }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label htmlFor='gstNumber'>Gst Number</label>
                                                <input style={{ color : errors.gstNumber ? 'red' : '' , borderColor : errors.gstNumber ? 'red' : '' }} type='text' value={gstNumber} onChange={(e) => setGstNumber(e.target.value)} className='form-control' id='gstNumber' placeholder='Enter GST Number (11AAAAA1111Z1A1)' />
                                                <p className='text-danger'>{ errors.gstNumber }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'> 
                                            <div className='form-group'>
                                                <label htmlFor='company'>Company</label> 
                                                <input style={{ color : errors.company ? 'red' : '' , borderColor : errors.company ? 'red' : '' }} type='text' value={company} onChange={(e) => setCompany(e.target.value)} className='form-control' id='company' placeholder='Enter Company' />
                                                <p className='text-danger'>{ errors.company }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'> 
                                            <div className='form-group'>
                                                <label htmlFor='address'>Address</label> 
                                                <input style={{ color : errors.address ? 'red' : '' , borderColor : errors.address ? 'red' : '' }} type='text' value={address} onChange={(e) => setAddress(e.target.value)} className='form-control' id='address' placeholder='Enter Address' />
                                                <p className='text-danger'>{ errors.address }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'> 
                                            <div className='form-group'>
                                                <label htmlFor='state'>State</label> 
                                                <input style={{ color : errors.state ? 'red' : '' , borderColor : errors.state ? 'red' : '' }} type='text' value={state} onChange={(e) => setState(e.target.value)} className='form-control' id='state' placeholder='Enter State' />
                                                <p className='text-danger'>{ errors.state }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'> 
                                            <div className='form-group'>
                                                <label htmlFor='city'>City</label> 
                                                <input style={{ color : errors.city ? 'red' : '' , borderColor : errors.city ? 'red' : '' }} type='text' value={city} onChange={(e) => setCity(e.target.value)} className='form-control' id='city' placeholder='Enter City' />
                                                <p className='text-danger'>{ errors.city }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'> 
                                            <div className='form-group'>
                                                <label htmlFor='pincode'>Pincode</label> 
                                                <input style={{ color : errors.pincode ? 'red' : '' , borderColor : errors.pincode ? 'red' : '' }} type='text' value={pincode} onChange={(e) => setPincode(e.target.value)} className='form-control' id='pincode' placeholder='Enter Pincode' />
                                                <p className='text-danger'>{ errors.pincode }</p>
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <CustomButton fill type='submit' name='button'>Update Profile</CustomButton>
                                        </div>
                                    </div>
                                </form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default MerchantProfileSetting;
