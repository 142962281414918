import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import EditWareHouse from './WarehouseEdit'
import { NotificationManager } from 'react-notifications'
const moment = require('moment');
const DetailsView = (props) => {
    const history = useHistory()
    const { details, setEditToggle } = props
    const [pinHistory, setPinHistory] = useState([])
    const [modalShow, setModalShow] = useState(false)
    // const [deleteid, setDeleteId] = useState('')
    console.log('id :  ', details.wc)
    console.log(props)
    useEffect(() => {
        axios.get(`/admin/pinhistory/${details.wc}`)
            .then((response) => {
                console.log("history ", response.data)
                setPinHistory(response.data)
            }).catch((err) => {
                alert(err.message)
            }, [])
    }, [])
     
    return <div style={{ padding: ' 40px 40px 40px 40px' }}><div className="table-responsive">
        <span style={{  color: 'rgb(66, 208, 237)' , cursor: "poiner",}} >
            <i class="fa fa-arrow-left" style={{ cursor: "poiner", fontSize: "22px" }}
                aria-hidden="true" onClick={() => history.push('/admin/viewWarehouses')}></i>
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {/* <span style={{ float: 'right', color: 'rgb(66, 208, 237)', cursor: "poiner",}}>
            <i className="fa fa-pencil" style={{ cursor: "poiner", fontSize: "22px" }}
                aria-hidden="true" onClick={() => { setEditToggle({ toggle: true, data: details }) }}></i>
        </span> */}
        &nbsp;&nbsp;&nbsp;&nbsp;
         

        <table className="table table-hover">
            <thead>
                <tr className="d-flex">
                    {/* <th scope="col" >Name</th>
                    <th scope="col" >Code</th>
                    <th scope="col" >Priority</th>
                    <th scope="col" >Status</th>
                    <th scope="col" >Mobile</th>
                    <th scope="col" >Phone</th>
                    <th scope="col" >Pincode</th>
                    <th scope="col" >View</th> */}
                    <th scope="col" >Warehouse Details</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Name</td><td>{details?.warehouse_name}</td>
                </tr>
                <tr>
                    <td>Code</td> <td>{details?.warehouse_code}</td>
                </tr>
                <tr>
                    <td>Priority</td> <td>{details?.wpr}%</td>
                </tr>
                <tr>
                    <td>Status</td><td>{details?.wst === 1 ? 'Active' : 'Inactive'}</td>
                </tr>
                <tr>
                    <td>Mobile</td> <td>{details?.WAREHOUSE_MOBILE}</td>
                </tr><tr>
                    <td>Phone</td><td>{details?.phone}</td>
                </tr>
                <tr>
                    <td>Current Pincode Url</td><td><a href={details?.wp}>Download</a></td>
                </tr>
                <tr>
                    <td>City</td>
                    <td>{details?.warehouse_city}</td>
                </tr>
                <tr>
                    <td>State</td>
                    <td>{details?.warehouse_state}</td>
                </tr>
                <tr>
                    <td>Country</td>
                    <td>{details?.warehouse_country}</td>
                </tr>
                <tr>
                    <td>Last Updated</td>   
                    <td> {moment(new Date(details?.updatedAt)).format('MM.DD.YYYY HH:mm')}</td>
                </tr>
                <tr>
                    <td>Created at</td>
                    <td>{moment(new Date(details?.createdAt)).format('MM.DD.YYYY HH:mm')}</td>
                </tr>

                <tr>
                    <td>
                        Pin History
                    </td>
                    <td>
                        <table className="table table-hover">
                            <tbody>
                                {
                                    pinHistory.map((ele, i) => {
                                        return (
                                            <tr key={i}><td>{moment(new Date(ele.updatedAt)).format('MM.DD.YYYY HH:mm')}: <a href={ele.wp}>Download</a></td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>   
                        </table>
                    </td>
                </tr>
                {/* <td>
                            <span >
                                <i className="fa fa-pencil" style={{ cursor: "poiner", fontSize: "22px" }}
                                    aria-hidden="true" onClick={() => { setEditToggle({ toggle: true, data: details }) }}></i>
                            </span>
                        </td>
                        <td>
                            <span className="text-danger" style={{ cursor: "poiner" }}>
                                <i className="fa fa-trash" style={{ fontSize: "22px" }} aria-hidden="true"
                                    onClick={() => {
                                        setModalShow(true)
                                        setDdetailsteId(details._id)
                                    }}></i>
                            </span>
                        </td> */}
            </tbody>
        </table>

    </div>

        {/* <Modal show={modalShow} onHide={() => setModalShow({ modalShow: false })} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Delate Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Are You sure you want to delete this entry?</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalShow(false)}>
                    Cancel
						</Button>
                <Button variant="success" onClick={() => deleteProduct()}>
                    Delete
						</Button>
            </Modal.Footer>
        </Modal> */}
    </div>
}


const WarehouseDetails = (props) => {
    const details = props?.location?.state
    const [editToggle, setEditToggle] = useState({ toggle: false, data: {} })
    return (
        <>
            {(editToggle.toggle) ? (<EditWareHouse setEditToggle={setEditToggle} editToggle={editToggle} />) : (<DetailsView details={details} setEditToggle={setEditToggle} />)}
        </>)

}


export default WarehouseDetails