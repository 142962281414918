import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NotificationManager } from 'react-notifications';

const TransportInformation = () => {
  const { id } = useParams();  // Get the 'id' from the route parameters
  const [order, setOrder] = useState({});

  useEffect(() => {
    axios.get(`/admin/order/${id}`)
      .then(res => {
        const order = res.data.results.order;
        setOrder(order);
        NotificationManager.success("Order data fetched successfully!");
      })
      .catch(error => {
        // console.error("There was an error fetching the order data!", error);
        NotificationManager.error("Error fetching order data");
      });
  }, [id]);  // Re-fetch when 'id' changes

  return (
    <div className="content">
      <Row>
        <Col md={12}>
          <Card
            title="Transport Information"
            category="Details about the shipment"
            content={
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th colSpan="2" style={{ fontWeight: 'bold' }}>Transport Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Shipping Method</td>
                    <td>{order.shippingMethod || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td>Waybill Number</td>
                    <td> {order.order_shipping && order.order_shipping[0]?.os_tracking_no || 'N/A'} </td>
                  </tr>
                  <tr>
                    <td>Shipping Date</td>
                    <td>{new Date(order.shipDate).toUTCString() || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td>Shipping Status</td>
                    <td>{order.sStatus || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td>Comment</td>
                    <td>{order.commentStatus || 'N/A'}</td>
                  </tr>
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default TransportInformation;
