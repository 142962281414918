import React, { useState, useEffect, useRef } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import '../../assets/css/settings.css'
import { NotificationManager } from 'react-notifications'
import Card from '../../components/Card/Card.jsx'
import CustomButton from '../../components/CustomButton/CustomButton'
import { CSVLink, CSVDownload } from "react-csv";

const MisReport = () => {
	const [name, setName] = useState('')
	const [priorityChina, setPriorityChina] = useState('0')
	const [priorityIndia, setPriorityIndia] = useState('0')
	const [priorityBulk, setPriorityBulk] = useState('0')
	const [id, setId] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState()
	const [password, setPassword] = useState('')
	const [csvData, setCsvData] = useState('')
	const [filename, setFilename] = useState('default-filename.csv');
	const [updateSku, setUpdateSku] = useState('');
	const [discontinueSku, setDiscontinueSku] = useState('');
	const [csvDataExport, setCsvDataExport] = useState([]);
	const [stockCsvData, setStockCsvData] = useState([]);
	const [spinner1, setSpinner1] = useState(false);
	const [spinner2, setSpinner2] = useState(false);
	const [spinner3, setSpinner3] = useState(false);
	const [spinner4, setSpinner4] = useState(false);
	

	const csvLinkRef = useRef();
	const fileRef = useRef();

	// this.state = { data: [], name:this.props.filename?this.props.filename:'data' };
    // this.csvLink = React.createRef();

	// const csvData = [
	// 	["firstname", "lastname", "email"],
	// 	["Ahmed", "Tomi", "ah@smthing.co.com"],
	// 	["Raed", "Labes", "rl@smthing.co.com"],
	// 	["Yezzi", "Min l3b", "ymin@cocococo.com"]
	//   ];

	const token = localStorage.getItem('token')
	const decode = jwt_decode(token)
	useEffect(() => {
		
	}, [])


	const updateSettings = async (e) => {
		e.preventDefault()
		try {
			let res = await axios.post('/admin/orderPriority', {
				priorityBulk,
				priorityIndia,
				priorityChina,
				merchantId:decode.id
			}).then((result) => {
				if(result.data.status == 1){
					NotificationManager.success('Settings Updated Successfully.')
				}else{
					NotificationManager.error('Something Went Wrong.')
				}
			})
			
		} catch (error) {
			NotificationManager.error('something unusual happened')
		}
	}

	// function downData(){
	// const downData = async (e) => {	
	const downData = (e) => {
		e.preventDefault();

		setSpinner1(true)
		axios.post('/admin/currentStock', {
			export_excel:1,
			merchantId:decode.id
		}).then((result) => {
			setSpinner1(false)
			if(result.data.status==1){
				
				window.location = result.data.data;
				// setFilename(`current-stock-report.csv`);
				// setCsvData(result.data.data)
				// csvLinkRef.current.link.click();
			}
		})
	}

	const downDataSku = (e) => {
		e.preventDefault();

		setSpinner2(true)
		axios.post('/admin/currentStock', {
			export_excel:9,
			merchantId:decode.id
		}).then((result) => {
			setSpinner2(false)
			if(result.data.status==1){
				window.location = result.data.data;
				// setFilename(`current-stock-report.csv`);
				// setCsvData(result.data.data)
				// csvLinkRef.current.link.click();
			}
		})
	}

	// const searchData = (e) => {
	// 	e.preventDefault();

	// 	axios.post('/admin/currentStock', {
	// 		export_excel:0,
	// 		merchantId:decode.id,
	// 		sku:updateSku
	// 	}).then((result) => {
	// 		if(result.data.status==1){
	// 			// setFilename(`current-stock-report.csv`);
	// 			// setCsvData(result.data.data)
	// 			// csvLinkRef.current.link.click();
	// 		}
	// 	})
	// }
	// const searchData2 = (e) => {
	// 	e.preventDefault();

	// 	axios.post('/admin/currentStock', {
	// 		mark_discontinued:3,
	// 		merchantId:decode.id,
	// 		sku:setDiscontinueSku
	// 	}).then((result) => {
	// 		if(result.data.status==1){
	// 			// setFilename(`current-stock-report.csv`);
	// 			// setCsvData(result.data.data)
	// 			// csvLinkRef.current.link.click();
	// 		}
	// 	})
	// }
	const searchData3 = (e) => {
		e.preventDefault();

		const scvdata = new FormData()
  		scvdata.append('file', stockCsvData[0])
  		scvdata.append('instock', 4)

		axios.post('/admin/currentStock', scvdata).then((result) => {
			if(result.data.status==1){
				// setFilename(`current-stock-report.csv`);
				// setCsvData(result.data.data)
				// csvLinkRef.current.link.click();
			}
		})

		
	}

	const exportList = (e) => {
		e.preventDefault();

		const scvdata = new FormData()
  		scvdata.append('file', csvDataExport[0])
  		scvdata.append('export_excel', 8)
  		scvdata.append('merchantId',decode.id)
		
		setSpinner4(true)
		axios.post('/admin/currentStock', scvdata).then((result) => {
			setSpinner4(false)
			if(result.data.status==1){
				// setFilename(`current-stock-report.csv`);
				// setCsvData(result.data.data)
				// csvLinkRef.current.link.click();
				window.location = result.data.data;
			}
		})
	}

	const downDataAssigned = (e) => {
		e.preventDefault();

		setSpinner3(true)
		axios.post('/admin/currentStock', {
			export_excel:5,
			merchantId:decode.id,
		}).then((result) => {
			setSpinner3(false)
			if(result.data.status==1){
				// setFilename(`current-stock-report.csv`);
				// setCsvData(result.data.data)
				// csvLinkRef.current.link.click();
				window.location = result.data.data;
			}
		})
	}

	const mystyle = {
		"WebkitAnimation": "spin 2s infinite linear",
		"MozAnimation": "spin 2s infinite linear",
		"OAnimation": "spin 2s infinite linear",
		"animation": "spin 2s infinite linear",
	};

	return (
		<div className="container" style={{width:"auto"}}>
			 {/* <button onClick={downData}>Download CSV</button> */}
			<CSVLink
				data={csvData}
				filename={filename}
				ref={csvLinkRef}
				style={{ display: 'none' }}
			>
				Download me
			</CSVLink>
			{/* <CSVLink data={csvData}>Download me</CSVLink> */}
			<div className="card" style={{padding: "3em"}}>
				<div className="row">
					<div className="col-sm-12 col-md-12">
						<h5>Current Stock Export (Istore And Shopper)</h5>
						<form id="down_form" method="POST" onSubmit={downData}>
							<font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>
								<input type="submit" value="Export Stock" className="btn btn-primary btn-xs" style={{"textTransform": "uppercase", "background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize": "14px", "fontWeight": "500", "color": "white"}} /></font></font> 
							<input name="export_excel" id="export_excel" type="hidden" value="1" />
							<span style={{"padding": "4px 0px 0 10px", display: spinner1 ? 'block' : 'none'}} id="spinner1"><i className={`pe-7s-refresh pe-2x pe-spin`} style={mystyle}></i></span>
						</form>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-12">
						<h5>Current Not Listed SKU Export</h5>
						<form id="down_form_sku" method="POST" onSubmit={downDataSku}>
							<font style={{"verticalAlign": "inherit"}}>
								<font style={{"verticalAlign": "inherit"}}>
									<input type="submit" value="Export Not List SKU" className="btn btn-primary btn-xs" style={{"textTransform": "uppercase", "background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize": "14px", "fontWeight": "500", "color": "white"}} />
								</font>
							</font>
								<input name="export_excel" id="export_excel" type="hidden" value="9" />
								<span style={{"padding": "4px 0px 0 10px", display: spinner2 ? 'block' : 'none'}}><i className={`pe-7s-refresh pe-2x pe-spin`} style={mystyle}></i></span>
						</form>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-12">
						<h5>Current Stock Export (Istore And Shopperr,Assigned,Bulk)</h5>	
						<form id="down_form_assigned" method="POST" onSubmit={downDataAssigned}> 
							<font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>
								<input type="submit" value="Export Stock Assigned, Bulk" className="btn btn-primary btn-xs" style={{"textTransform": "uppercase", "background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize": "14px", "fontWeight": "500", "color": "white"}} />
							</font></font> 
							<input name="export_excel" id="export_excel" type="hidden" value="5" />
							<span style={{"padding": "4px 0px 0 10px", display: spinner3 ? 'block' : 'none'}}><i className={`pe-7s-refresh pe-2x pe-spin`} style={mystyle}></i></span>
						</form>
					</div>

				</div>

				{/* <div className="row">
					<div className="col-sm-12 col-md-12">
						<h5>SKU Not sale, Convert to sale</h5>
						<form id="search_form" method="POST" onSubmit={searchData}> 
							<input type="text" name="sku" value={updateSku} onChange={(e) => {setUpdateSku(e.target.files) }} /> 
							<input name="export_excel" id="export_excel" type="hidden" value="0" />
							<font style={{"verticalAlign": "inherit", "padding": "6px 0 0px 10px"}}><font style={{"verticalAlign": "inherit"}}>
								<input type="submit" value="Sale" className="btn btn-primary btn-xs" style={{"textTransform": "uppercase", "background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize": "14px", "fontWeight": "500", "color": "white"}} /></font></font> 
						</form>
					</div>
				</div> */}
				{/* <div className="row">
					<div className="col-sm-12 col-md-12">
						<h5>Enter discontinued SKU</h5>
						<form id="search_form_2" method="POST" onSubmit={searchData2}> 
						<input type="text" name="sku"  value={discontinueSku} onChange={(e) => {setDiscontinueSku(e.target.files) }} /> 
						<input name="mark_discontinued" id="mark_discontinued" type="hidden" value="3" />
						<font style={{"verticalAlign": "inherit", "padding": "6px 0 0px 10px"}}><font style={{"verticalAlign": "inherit"}}>
							<input type="button" value="Mark Discontinued" onclick="searchData2()" className="btn btn-primary btn-xs" style={{"textTransform": "uppercase", "background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize": "14px", "fontWeight": "500", "color": "white"}} />
						</font></font> 
						
						</form>
					</div>
				</div> */}
				{/* <div className="row">
					<div className="col-sm-12 col-md-12">
						<form id="search_form_3" method="POST" encType="multipart/form-data"  onSubmit={searchData3}> 
							<h5>Order Create Stock Upload</h5>
							<input type="file" name="file" id="file" onChange={(e) => {setStockCsvData(e.target.files) }} ref={fileRef} />
							<input name="instock" id="instock" type="hidden" value="4" /> 
							<font style={{"verticalAlign": "inherit", "padding": "6px 0px 0px 10px"}}><font style={{"verticalAlign": "inherit"}}>
								<input type="button" value="Upload" className="btn btn-primary btn-xs" style={{"textTransform": "uppercase", "background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize": "14px", "fontWeight": "500", "color": "white"}} />
							</font></font> 
							
						</form>
					</div>
				</div> */}
				<div className="row">
					<div className="col-sm-12 col-md-12">
						<h5>Export List</h5>
						<form id="down_form" method="POST" encType="multipart/form-data" onSubmit={exportList}> 
							<input type="file" id="export_list" name="export_list" accept=".csv" onChange={(e) => {setCsvDataExport(e.target.files) }} encType='multipart/form-data' ref={fileRef} />
							<br/>
							<span id="list_msg"></span>
							<input type="hidden" id="list_error" value="0" />
							<br />
							<font style={{"verticalAlign": "inherit", "padding": "6px 0px 0px 10px"}}><font style={{"verticalAlign": "inherit"}}>
								<input type="submit" id="export_list_btn" value="Export List" className="btn btn-primary btn-xs" style={{"textTransform": "uppercase", "background": "rgb(255, 160, 0)", "borderColor": "rgb(255, 160, 0)", "fontSize": "14px", "fontWeight": "500", "color": "white"}} /></font></font> 
								<input name="export_excel" id="export_excel" type="hidden" value="8" />
								<span style={{"padding": "4px 0px 0 10px", display: spinner4 ? 'block' : 'none'}}><i className={`pe-7s-refresh pe-2x pe-spin`} style={mystyle}></i></span>
						</form>

					</div>
					
				</div>
			</div>
		</div>
	)
}

export default MisReport
