import React, {Component} from 'react'
import {NotificationManager} from 'react-notifications'
import SyncLoader from "react-spinners/SyncLoader"
import _ from "lodash";
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
` 

export default class CategoryList extends Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            categoryList: [],
            totalCategory: 0,
            url: "",
            redirect: false,
            modalShow: false,
            categoryValue:""
        }
    }

    componentDidMount(){
        this.getCategoryList()
    }

    getCategoryList(){
        this.setState({loading: true})
        axios.get("/admin/totalCategory")
        .then(result => { 
            this.setState({loading: false, categoryList: result.data, totalCategory: result.data.length})
        })
        .catch(err => {
            console.log(err)
            NotificationManager.error("Failed to fetch category list")
        })
    }

    handleDelete=(id)=>
    {
        this.setState({categoryValue: id})
        this.setState({modalShow: true})
    }
    deleteCategory = id => {
        this.setState({loading: true})
        axios.delete(`/api/categoryDel/${id}`)
        .then(result => {
            this.setState({modalShow: false})
            NotificationManager.success("Category Deleted Successfully")
            this.getCategoryList()
        })
        .catch(err => {
            console.log(err)
            this.setState({loading: false})
            NotificationManager.error("Failed to delete category")
        })
    }

    handleUpdate = id => {
        localStorage.setItem("code", id)
        this.setState({redirect: true})
    }

    render(){
        if(this.state.redirect){
            return(
                <Redirect to="/admin/editCategory" />
            )
        }
        return(
            <div>
            <Modal show={this.state.modalShow} onHide={() => this.setState({modalShow: false})} animation={false}>
						<Modal.Header closeButton>
						<Modal.Title>Delete Product Category</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<h5>Are you sure you want to delete?</h5>
							
							<br></br>
						</Modal.Body>
						<Modal.Footer>
						<button style={{color:"white",background:"green"}} onClick={() => this.setState({modalShow: false})}>
							Cancel
						</button>
						<button style={{color:"white",background:"red",marginTop:"8px"}} onClick={() => this.deleteCategory(this.state.categoryValue)}>
							 Delete
						</button>
						
						</Modal.Footer>
					</Modal> 
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
				<br></br>
                <div className="container" style={{width:"auto"}}>
                    <div className="card" style={{padding: "3em"}}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <h4>Total Category's: {this.state.totalCategory}</h4>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ paddingRight : '0px' }}>
                                <h4 style={{float: "right"}}>
                                    <Link to="/admin/AddCategory">
                                        <button className='btn btn-fill' style={{ backgroundColor: 'rgb(255, 160, 0)', 
                                            color: 'rgb(255, 255, 255)', 
                                            fontWeight: '500',   
                                            height: "40px", 
                                            display: "flex", 
                                            alignItems: "center", 
                                            justifyContent: "center", 
                                            borderRadius: 4, 
                                            border:'0px', 
                                            'fontSize': '15px' }}>Add Category
                                        </button>
                                    </Link>
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <table className="table table-hover table-responsive">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Category Name</th>
                                        <th>Sub Category</th>
                                        <th>Description</th>
                                        <th>Created On</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.categoryList.map(categoryObj => {
                                            const { category, subCategory, description, created_on, categoryImage, _id } = categoryObj
                                            return(
                                                <tr key={_id}>
                                                    <td>
                                                        {
                                                            _.isEmpty(categoryImage)
                                                            ?
                                                                "Image Not Available"
                                                            :
                                                                <img src={categoryImage} width="120" />
                                                        }
                                                    </td>
                                                    <td>{category}</td>
                                                    <td>{subCategory}</td>
                                                    <td>{description}</td>
                                                    <td>{created_on}</td>
                                                    <td colSpan="2">
                                                        <span onClick={() => this.handleUpdate(_id)} style={{ color : '#42d0ed' , cursor : 'pointer' }}><i className="fa fa-pencil" aria-hidden="true"></i></span>
                                                        &nbsp;&nbsp;<span onClick={() =>this.handleDelete(_id)} style={{  cursor : 'pointer' }} className='text-danger'><i className="fa fa-trash" aria-hidden="true"></i></span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}